<script setup></script>

<template>
  <crud-details-page
    api="tools/group-notification"
    disable-top-margin
    :versioned="false"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Message -->
        <crud-field-selector-client-strings
          api-field-name="Message"
          required
        />
        <!-- IsEnabled -->
        <crud-field-switcher api-field-name="IsEnabled" />
        <!-- PostOnSlackSwChangelog -->
        <crud-field-switcher api-field-name="PostOnSlackSwChangelog" />
      </fields-col>
      <fields-col :sm="8">
        <!-- ValidToSeasonNo -->
        <crud-field-number api-field-name="ValidToSeasonNo" />
        <!-- SeasonDay -->
        <crud-field-number
          api-field-name="SeasonDay"
          label="Send at season day"
        />
        <!-- Hour -->
        <crud-field-number
          api-field-name="Hour"
          label="At hour of season day"
        />
        <!-- Minute -->
        <crud-field-number
          api-field-name="Minute"
          label="At minute of season day"
        />
      </fields-col>
      <section-divider>Notification sent states</section-divider>
      <!-- AsiaDone -->
      <crud-field-switcher
        :sm="3"
        api-field-name="AsiaDone"
        disabled
      />
      <!-- AsiaLastId -->
      <crud-field-number
        :sm="4"
        api-field-name="AsiaLastId"
        disabled
      />
      <!-- AsiaSentCounter -->
      <crud-field-number
        :sm="4"
        api-field-name="AsiaSentCounter"
        disabled
        col-break
      />
      <!-- EuropeDone -->
      <crud-field-switcher
        :sm="3"
        api-field-name="EuropeDone"
        disabled
      />
      <!-- EuropeLastId -->
      <crud-field-number
        :sm="4"
        api-field-name="EuropeLastId"
        disabled
      />
      <!-- EuropeSentCounter -->
      <crud-field-number
        :sm="4"
        api-field-name="EuropeSentCounter"
        disabled
        col-break
      />
      <!-- AmericaDone -->
      <crud-field-switcher
        :sm="3"
        api-field-name="AmericaDone"
        disabled
      />
      <!-- AmericaLastId -->
      <crud-field-number
        :sm="4"
        api-field-name="AmericaLastId"
        disabled
      />
      <!-- AmericaSentCounter -->
      <crud-field-number
        :sm="4"
        api-field-name="AmericaSentCounter"
        disabled
        col-break
      />
    </template>
  </crud-details-page>
</template>
