<script setup></script>

<template>
  <crud-details-page
    api="energy-pack"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Text -->
        <crud-field-text
          api-field-name="Text"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetIcon -->
        <crud-field-selector-files
          api-field-name="AssetIcon"
          required
        />
        <!-- TextKey -->
        <crud-field-selector-client-strings api-field-name="TextKey" />
      </fields-col>
      <!-- PriceGems -->
      <crud-field-select
        :sm="8"
        api-field-name="PriceGems"
        options-enum="enums.OfferPrice"
        required
      />
      <section-divider>When promo is NOT active</section-divider>
      <template
        v-for="i in 3"
        :key="i"
      >
        <crud-field-number
          :sm="4"
          :api-field-name="`OffFromSeasonDay${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`OffDurationHours${i}`"
          col-break
        />
      </template>
      <section-divider>Promo product fields</section-divider>
      <!-- PromoEnergyTier -->
      <crud-field-number
        :sm="4"
        api-field-name="PromoEnergyTier"
      />
      <!-- PromoDurationMinutes -->
      <crud-field-number
        :sm="4"
        api-field-name="PromoDurationMinutes"
      />
      <section-divider>Rewards</section-divider>
      <!-- RewardEnergy -->
      <crud-field-number
        :sm="4"
        api-field-name="RewardEnergy"
      />
      <crud-field-selector-item
        :sm="8"
        api-field-name="RewardItem"
      />
    </template>
  </crud-details-page>
</template>
