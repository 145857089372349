<script>
import { defineAsyncComponent } from 'vue'
import DynamicSelector from '!/components/selectors/DynamicSelector.vue'

export default {
  name: 'TrophySelector',
  components: {
    DynamicSelector,
    TrophyDetailsPage: defineAsyncComponent(() => import('@/pages/rewards/trophies/DetailsPage.vue'))
  },
  props: {
    filterMode: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <DynamicSelector
    :label="filterMode ? '' : 'Trophy'"
    show-img-preview-column
    :columns-settings="{
      Type: {},
      DivisionNo: {},
      TournamentPlaceNo: {}
    }"
    :width-field-px="filterMode && !fullWidth ? 200 : undefined"
    :full-width="fullWidth"
    api="rewards/trophies"
    :edit-model-route-name="filterMode ? '' : 'trophies-details'"
  >
    <template #editModelContent="{ entityRoute, setEditModelMetaData, closeEditModal, refreshSelector }">
      <TrophyDetailsPage
        :entity-route="entityRoute"
        @change-meta-data="setEditModelMetaData($event)"
        @cancel="closeEditModal"
        @change="refreshSelector"
      />
    </template>
  </DynamicSelector>
</template>
