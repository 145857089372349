<script>
export default {
  props: {
    menuItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: {},
      ccBlockade: null,
      loadingSkirmishes: false,
      loadingDev: false,
      loadingProd: false,
      loadingCache: {},
      test: null,
      dialogVisible: false,
      activeBattle: false,
      activeBattleTimer: null
    }
  },
  mounted() {
    this.checkIfActiveBattle()
    this.activeBattleTimer = setInterval(() => { // check if skirmish is taking place every 15 second on this page
      this.checkIfActiveBattle()
    }, 15000)
  },
  beforeUnmount() {
    clearInterval(this.activeBattleTimer)
  },
  created() {
    this.env = 'dev'
    this.envApi = this.$store.getters['auth/envs']?.[this.env]
    this.getClearCacheBlockade()
  },
  methods: {
    checkIfActiveBattle() {
      const email = this.$store.getters['auth/userData'].Email
      const envApiProd = this.$store.getters['auth/envs']?.prod

      this.$axios
        .get(`${envApiProd}/proxy/getbattlehours`, { params: { u: email, p: '6cq8!@X-bN7U5b+]Lx9OAK?^jLxKSIE!#6Z/>**K' } })
        .then((response) => {
          this.activeBattle = response.data.active_battle
        })
        .catch(() => {
          this.loadingSkirmishes = false
          this.$message({
            message: 'Show skirmishes failed',
            type: 'warning',
            offset: 40
          })
        })
    },
    getClearCacheBlockade() {
      this.$axios
        .get('/dev/getclearcacheblockade/', {})
        .then(({ data }) => {
          try {
            if (data?.blockade) {
              this.ccBlockade = data.blockade
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
        })
        .catch(() => {
        })
    },
    blockClearCache() {
      this.loading.blockClearCache = true
      this.$axios
        .get('/dev/blockclearcache/', {})
        .then(() => {
          this.loading.blockClearCache = false
          this.getClearCacheBlockade()
          this.$message({
            message: 'Clear cache blocked',
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.loading.blockClearCache = false
          this.$message({
            message: 'Error while setting clear cache blockade',
            type: 'warning',
            offset: 40
          })
        })
    },
    unblockClearCache() {
      this.loading.blockClearCache = true
      this.$axios
        .get('/dev/unblockclearcache/', {})
        .then(() => {
          this.loading.blockClearCache = false
          this.ccBlockade = null
          this.$message({
            message: 'Clear cache unblocked',
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.loading.blockClearCache = false
          this.$message({
            message: 'Error while unsetting clear cache blockade',
            type: 'warning',
            offset: 40
          })
        })
    },
    clearDev() {
      const email = this.$store.getters['auth/userData'].Email
      this.loadingDev = true
      this.$axios
        .get(`/proxy/clearcache/`, { params: { u: email, p: '6cq8!@X-bN7U5b+]Lx9OAK?^jLxKSIE!#6Z/>**K' } })
        .then(() => {
          this.loadingDev = false
          this.$message({
            message: 'DEV cache cleared',
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.loadingDev = false
          this.$message({
            message: 'DEV cache failed',
            type: 'warning',
            offset: 40
          })
        })
    },
    clearProdJWT() {
      this.loadingProd = true
      this.$axios
        .get(`${this.$store.getters['auth/envs'].prod}/jwt/clearcache/`, { params: { method: 'GET', data: [], env: 'prod' } })
        .then(() => {
          this.loadingProd = false
          this.$message({
            message: 'PROD JWT cache cleared!',
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.loadingProd = false
          this.$message({
            message: 'PROD JWT cache failed!',
            type: 'warning',
            offset: 40
          })
        })
    },
    showSkirmishes() {
      this.loadingSkirmishes = true
      const envApi = this.$store.getters['auth/envs'].prod
      this.$axios
        .get(`${envApi}/proxy/listbattlehours/`)
        .then((response) => {
          this.loadingSkirmishes = false
          this.dialogVisible = true
          this.$utils.nextLoopEvent(100).then(() => {
            const doc = this.$refs.iframe.contentWindow.document
            doc.open()
            doc.write(response.data)
            doc.close()
          })
        })
        .catch(() => {
          this.loadingSkirmishes = false
          this.$message({
            message: 'Show skirmishes failed',
            type: 'warning',
            offset: 40
          })
        })
    }
  }
}
</script>

<template>
  <template v-if="ccBlockade === null">
    <el-popconfirm
      title="Are you sure?"
      :teleported="false"
      @confirm="blockClearCache"
    >
      <template #reference>
        <el-button
          type="primary"
          class="m-5"
          :loading="loading.blockClearCache"
        >
          BLOCK CC
        </el-button>
      </template>
    </el-popconfirm>
  </template>
  <template v-if="ccBlockade !== null">
    <el-tooltip
      class="box-item"
      placement="top"
      :content="`Clear cache blocked by: ${ccBlockade.AdminUser}`"
    >
      <span>
        <el-popconfirm
          title="Are you sure?"
          :teleported="false"
          @confirm="unblockClearCache"
        >
          <template #reference>
            <el-button
              type="primary"
              class="m-5"
              :loading="loading.blockClearCache"
            >
              UNBLOCK CC
            </el-button>
          </template>
        </el-popconfirm>
      </span>
    </el-tooltip>
  </template>
  <el-button
    type="warning"
    class="m-5"
    :loading="loadingDev"
    @click="clearDev"
  >
    CLEAR DEV
  </el-button>
  <template v-if="ccBlockade !== null || activeBattle">
    <el-tooltip
      class="box-item"
      placement="top"
      :content="activeBattle ? 'Active battle' : `Clear cache blocked by: ${ccBlockade.AdminUser}`"
    >
      <span>
        <el-button
          disabled
          type="danger"
          class="m-5"
          :loading="loadingCache.prod"
        >CLEAR PROD</el-button>
      </span>
    </el-tooltip>
  </template>
  <template v-if="ccBlockade === null && !activeBattle">
    <el-popconfirm
      title="Are you sure ?"
      :teleported="false"
      @confirm="clearProdJWT"
    >
      <template #reference>
        <el-button
          :disabled="activeBattle"
          type="danger"
          class="m-5"
          :loading="loadingCache.prod"
        >
          CLEAR PROD
        </el-button>
      </template>
    </el-popconfirm>
  </template>
  <el-button
    type="info"
    class="m-5"
    :loading="loadingSkirmishes"
    @click="showSkirmishes"
  >
    SHOW PROD SKIRMISHES
  </el-button>
  <div />
  <el-dialog
    v-model="dialogVisible"
    title="PROD SKIRMISHES"
    width="70%"
    destroy-on-close
  >
    <iframe
      ref="iframe"
      class="h-[60vh] min-h-[500px] w-full"
    />
  </el-dialog>
</template>
