<script>
import CrudFieldSelectorQuest from '@/components/forms/crud-fields/CrudFieldSelectorQuest.vue'
import CrudFieldSelectorQuestReward from '@/components/forms/crud-fields/CrudFieldSelectorQuestReward.vue'

export default {
  components: { CrudFieldSelectorQuestReward, CrudFieldSelectorQuest }
}
</script>

<template>
  <crud-details-page
    api="quests/quest-group"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- DurationHours -->
        <crud-field-number
          api-field-name="DurationHours"
          required
        />
        <!-- RequiredLevel -->
        <crud-field-number api-field-name="RequiredLevel" />
        <!-- Color -->
        <crud-field-text api-field-name="Color" />
        <!-- ForNewbies -->
        <crud-field-switcher api-field-name="ForNewbies" />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetIcon -->
        <CrudFieldSelectorQuestReward
          api-field-name="QuestReward"
          label="Quest milestones reward"
        />
        <!-- AssetIcon -->
        <crud-field-selector-files api-field-name="AssetIcon" />
        <!-- AssetBanner -->
        <crud-field-selector-files api-field-name="AssetBanner" />
        <!-- AssetPopup -->
        <crud-field-selector-files api-field-name="AssetPopup" />
        <!-- AssetRewardPoints -->
        <crud-field-selector-files api-field-name="AssetRewardPoints" />
        <!-- NameTag -->
        <crud-field-selector-client-strings api-field-name="NameTag" />
      </fields-col>
      <fields-col :sm="8">
        <!-- XpPct -->
        <crud-field-number api-field-name="XpPct" />
        <!-- Energy -->
        <crud-field-number api-field-name="Energy" />
        <!-- Gems -->
        <crud-field-number api-field-name="Gems" />
        <template
          v-for="i in 2"
          :key="i"
        >
          <crud-field-selector-item
            :sm="16"
            :api-field-name="`Item${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Item${i}Amount`"
          />
        </template>
      </fields-col>
      <section-divider>Quests</section-divider>
      <template
        v-for="i in 30"
        :key="i"
      >
        <CrudFieldSelectorQuest
          :sm="8"
          :api-field-name="`Quest${i}`"
          :label="`Quest ${i}`"
        />
      </template>
    </template>
  </crud-details-page>
</template>
