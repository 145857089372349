<script setup>
import { ref } from 'vue'
import { globalProperties as app } from '!/plugins/utilities.js'

const boards = ref({
  dev: { data: {}, show: false },
  prod: { data: {}, show: false }
})

const envsApi = app.$store.getters['auth/envs'];
['dev', 'prod'].forEach((env) => {
  if (envsApi?.[env]) {
    app.$axios
      .get(`${envsApi[env]}/proxy/getcurrentseasonno/`)
      .then(({ data }) => {
        delete data.status
        boards.value[env] = { data, show: Object.keys(data).length }
      })
      .catch(app.$utils.catchError)
  }
})
</script>

<template>
  <div class="gs-user-font mt-2 flex flex-col sm:flex-row items-center justify-center">
    <div
      v-for="(board, env) in boards"
      :key="env"
      class="px-3"
    >
      <div
        v-if="board.show"
        class="font-related-lg rounded-lg bg-blue-100 px-4 py-1 mb-2 whitespace-nowrap"
      >
        <div
          v-for="(zone, zoneName) in board.data"
          :key="zoneName"
          class="px-4"
        >
          <div class="font-related-xl font-medium">
            {{ zoneName }}
          </div>
          <div class="capitalize">
            {{ env }} season no: <b>{{ zone.no }}</b>
          </div>
          <div class="capitalize">
            {{ env }} season day: <b>{{ zone.day }}</b>
          </div>
          <div class="capitalize">
            {{ env }} season start: <b>{{ zone.start_local }}</b>
          </div>
          <div class="capitalize">
            {{ env }} season start: <b>{{ zone.start }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <crud-table
    :default-visible-columns="['No', 'Name']"
    api="seasons"
    api-item="season"
  />
</template>
