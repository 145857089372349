<script>
import { defineAsyncComponent } from 'vue'
import DynamicSelector from '!/components/selectors/DynamicSelector.vue'

export default {
  name: 'LotteryEventSelector',
  components: {
    DynamicSelector,
    LotteryEventDetailsPage: defineAsyncComponent(() => import('@/pages/events/lottery-events/DetailsPage.vue'))
  },
  props: {
    filterMode: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <DynamicSelector
    :label="filterMode ? '' : 'Lottery Event'"
    show-img-preview-column
    :columns-settings="{
      Name: {}
    }"
    :width-field-px="filterMode && !fullWidth ? 200 : undefined"
    :full-width="fullWidth"
    api="events/lottery-events"
    :edit-model-route-name="filterMode ? '' : 'lottery-events-details'"
  >
    <template #editModelContent="{ entityRoute, setEditModelMetaData, closeEditModal, refreshSelector }">
      <LotteryEventDetailsPage
        :entity-route="entityRoute"
        @change-meta-data="setEditModelMetaData($event)"
        @cancel="closeEditModal"
        @change="refreshSelector"
      />
    </template>
  </DynamicSelector>
</template>
