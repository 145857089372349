<script>
import CrudFieldSelectorExternalSkin from '@/components/forms/crud-fields/CrudFieldSelectorExternalSkins.vue'

export default {
  components: { CrudFieldSelectorExternalSkin }
}
</script>

<template>
  <crud-details-page
    api="items/item"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <crud-field-selector-files api-field-name="AssetIcon" />
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.ItemType"
          required
        />
        <crud-field-text
          api-field-name="Label"
          required
        />
        <crud-field-number api-field-name="Quality" />
        <CrudFieldSelectorExternalSkin api-field-name="Skin" />
      </fields-col>
      <fields-col :sm="8">
        <crud-field-selector-client-strings
          label="Card Name"
          api-field-name="CardNameTag"
        />
        <crud-field-switcher
          api-field-name="IsGoldenCard"
          label="GoldenCard"
          :grow-field="false"
        />

        <crud-field-checkbox
          api-field-name="IsCountable"
          class="ml-3"
          slim
        />
        <crud-field-checkbox
          api-field-name="IsConsumable"
          class="ml-3"
          slim
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
