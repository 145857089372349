<script>
import CrudFieldSelectorOffer from '@/components/forms/crud-fields/CrudFieldSelectorOffer.vue'

export default {
  components: { CrudFieldSelectorOffer }
}
</script>

<template>
  <crud-details-page
    api="offers/offer"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="6">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.OfferType"
          required
        />
        <!-- ProductId -->
        <crud-field-text
          api-field-name="ProductId"
          required
        />
        <!-- Price -->
        <crud-field-select
          options-enum="enums.OfferPrice"
          api-field-name="Price"
          required
        />
        <!-- HelpKey -->
        <crud-field-selector-client-strings api-field-name="HelpKey" />
      </fields-col>
      <fields-col :sm="6">
        <!-- AssetHomeIcon -->
        <crud-field-selector-files api-field-name="AssetHomeIcon" />
        <!-- AssetIcon -->
        <crud-field-selector-files api-field-name="AssetIcon" />
        <!-- AssetIconForeground -->
        <crud-field-selector-files api-field-name="AssetIconForeground" />
        <!-- AssetPopupBackground -->
        <crud-field-selector-files api-field-name="AssetPopupBackground" />
      </fields-col>
      <fields-col :sm="6">
        <!-- AssetPopupOfferInfo -->
        <crud-field-selector-files api-field-name="AssetPopupOfferInfo" />
        <!-- AssetBig -->
        <crud-field-selector-files api-field-name="AssetBig" />
        <!-- AssetMedium -->
        <crud-field-selector-files api-field-name="AssetMedium" />
        <!-- AssetSmall -->
        <crud-field-selector-files api-field-name="AssetSmall" />
      </fields-col>
      <fields-col :sm="6">
        <!-- AssetBig2 -->
        <crud-field-selector-files api-field-name="AssetBig2" />
        <!-- AssetMedium2 -->
        <crud-field-selector-files api-field-name="AssetMedium2" />
        <!-- AssetSmall2 -->
        <crud-field-selector-files api-field-name="AssetSmall2" />
      </fields-col>

      <!-- Gamesture storeapi -->
      <section-divider>Gamesture store</section-divider>
      <fields-col :sm="8">
        <!-- StoreName -->
        <crud-field-textarea
          api-field-name="StoreName"
          :textarea-rows="2"
        />
        <!-- StoreDescription -->
        <crud-field-textarea
          api-field-name="StoreDescription"
          :textarea-rows="4"
        />
        <!-- StoreAssetBackground -->
        <crud-field-selector-files
          api-field-name="StoreAssetBackground"
          label="Store asset"
          vertical
        >
          <template #fieldAppend>
            <div class="text-neutral-400 font-related-sm pt-0.5 pl-1">
              ratio 1 / 1.6 (min size: 500px x 310px)
            </div>
          </template>
        </crud-field-selector-files>
        <crud-field-number
          api-field-name="StorePriceInGCoins"
          label="Price In GCoins"
        />
        <!-- StoreAllowQuantityPurchase -->
        <crud-field-switcher api-field-name="StoreAllowQuantityPurchase" />
      </fields-col>
      <fields-col :sm="8">
        <!-- StoreIsBestDeal -->
        <crud-field-switcher
          api-field-name="StoreIsBestDeal"
          vertical
        >
          <template #fieldAppend>
            <div class="text-neutral-400 font-related-sm pt-0.5 pl-1">
              !!! Must be selected in 2 offers !!!
            </div>
          </template>
        </crud-field-switcher>
        <!-- StoreIsTopOffer -->
        <crud-field-switcher
          api-field-name="StoreIsTopOffer"
          vertical
        >
          <template #fieldAppend>
            <div class="text-neutral-400 font-related-sm pt-0.5 pl-1">
              !!! Must be selected in 4 offers !!!
            </div>
          </template>
        </crud-field-switcher>
        <!-- StoreBonusGems -->
        <crud-field-number api-field-name="StoreBonusGems" />
        <!-- StoreBonusEnergy -->
        <crud-field-number api-field-name="StoreBonusEnergy" />
        <!-- StoreBonusXpPct -->
        <crud-field-number api-field-name="StoreBonusXpPct" />
        <!-- StoreDiscountPercent -->
        <crud-field-number api-field-name="StoreDiscountPercent" />
        <!-- StoreAssetDiscount -->
        <crud-field-selector-files
          api-field-name="StoreAssetDiscount"
          vertical
        >
          <template #fieldAppend>
            <div class="text-neutral-400 font-related-sm pt-0.5 pl-1">
              ratio 47 / 6 (min size: 470 x 60px)
            </div>
          </template>
        </crud-field-selector-files>
      </fields-col>
      <fields-col :sm="8">
        <crud-field-switcher api-field-name="StoreIsOnlyForStore" />
        <crud-field-switcher api-field-name="StoreIsNotAvailable" />
        <!-- StoreIsFeatureBanner -->
        <crud-field-switcher api-field-name="StoreIsFeatureBanner" />
        <!-- StoreFeatureBannerAsset -->
        <crud-field-selector-files
          api-field-name="StoreFeatureBannerAsset"
          vertical
        >
          <template #fieldAppend>
            <div class="text-neutral-400 font-related-sm pt-0.5 pl-1">
              ratio 175 / 67  (min size: 1400px x 536px)
            </div>
          </template>
        </crud-field-selector-files>
        <!-- StoreFeatureMobileBannerAsset -->
        <crud-field-selector-files
          api-field-name="StoreFeatureMobileBannerAsset"
          vertical
        >
          <template #fieldAppend>
            <div class="text-neutral-400 font-related-sm pt-0.5 pl-1">
              ratio 250 / 281 (min size: 500px x 562px)
            </div>
          </template>
        </crud-field-selector-files>
        <!-- StoreFeatureBannerDescription -->
        <crud-field-textarea
          api-field-name="StoreFeatureBannerDescription"
          :textarea-rows="2"
        />
        <crud-field-number api-field-name="StoreDisplayPriority" />
      </fields-col>
      <!-- End of Gamesture storeapi -->

      <section-divider>Extra fields</section-divider>
      <!-- PurchaseLimit -->
      <crud-field-number
        :sm="6"
        api-field-name="PurchaseLimit"
      />
      <!-- DurationHours -->
      <crud-field-number
        :sm="6"
        api-field-name="DurationHours"
      />
      <!-- SeasonDayStart -->
      <crud-field-number
        :sm="6"
        api-field-name="SeasonDayStart"
      />
      <!-- ReplacedOffer -->
      <CrudFieldSelectorOffer
        :sm="6"
        api-field-name="ReplacedOffer"
      />
      <!-- ChainedOffer -->
      <CrudFieldSelectorOffer
        :sm="6"
        api-field-name="ChainedOffer"
      />
      <!-- FlashTriggerLevel -->
      <crud-field-number
        :sm="6"
        api-field-name="FlashTriggerLevel"
      />
      <!-- IsFeatured -->
      <crud-field-switcher
        :sm="6"
        api-field-name="IsFeatured"
        class="flex-none"
      />
      <!-- IsPromoPopup -->
      <crud-field-switcher
        :sm="6"
        api-field-name="IsPromoPopup"
        class="flex-none"
      />
      <section-divider>Rewards</section-divider>
      <fields-col :sm="10">
        <!-- RewardGems -->
        <crud-field-number
          :sm="12"
          api-field-name="RewardGems"
        />
        <!-- RewardSeasonPass -->
        <crud-field-switcher
          :sm="12"
          api-field-name="RewardSeasonPass"
        />
        <!-- RewardEnergy -->
        <crud-field-number
          :sm="12"
          api-field-name="RewardEnergy"
        />
        <!-- HideRewardsOnFlashPopup -->
        <crud-field-switcher
          :sm="12"
          api-field-name="HideRewardsOnFlashPopup"
          class="flex-none"
        />
        <el-col />
        <crud-field-number
          :sm="9"
          :min="0"
          api-field-name="RewardXpPct"
        />
      </fields-col>
      <fields-col :sm="12">
        <template
          v-for="i in 4"
          :key="i"
        >
          <crud-field-selector-item
            :sm="16"
            :api-field-name="`RewardItem${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`RewardAmount${i}`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
