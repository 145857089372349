<script>
import emojiRegex from 'emoji-regex'
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'
import BattleScorePreview from '@/components/shared/BattleScorePreview.vue'

export default {
  components: {
    EmojiPicker,
    BattleScorePreview
  },
  setup() {
    return {
      previewActions: {}
    }
  },
  data() {
    return {
      guildTabs: 'general',
      chatTabs: 'chat_0',
      externalData: {},
      guildTranslatedDescription: '',
      envApi: '',
      endPoint: '',
      loading: {},
      initRequestId: 0,
      player: {},
      pinfo: {},
      guilds: {},
      battleLog: {},
      playerMessage: {},
      moderators: [],
      env: '',
      ws: null,
      mentionTags: [],
      idTaskRestApi: 10000000,
      chat: {
        token: '',
        isDeveloper: false,
        messages: {},
        messagesOrder: [],
        translatedMessages: {},
        tabs: [],
        height: null,
        shouldBeScrolledTab: {},
        lazyTasks: {},
        loading: {},
        pinnedMessage: {}
      },
      showEmojiPicker: [],
      visibleMembersAutocomplete: [],
      visibleMembersAutocompleteOffset: []
    }
  },
  computed: {
    playerIsGuildMaster() {
      if (this.player.guild === 0 || this.player.id === 0) {
        return false
      }
      if (!this.guilds[this.player.guild]) {
        return false
      }
      return this.guilds[this.player.guild]?.members[this.player.id].gm === 1
    },
    playerIsDeputy() {
      if (this.player.guild === 0 || this.player.id === 0) {
        return false
      }
      if (!this.guilds[this.player.guild]) {
        return false
      }
      return this.guilds[this.player.guild]?.members[this.player.id].deputy === 1
    },
    autocompleteGuildMembers() {
      if (this.player.guild === 0) {
        return []
      }
      if (!this.guilds[this.player.guild]) {
        return []
      }
      if (!this.guilds[this.player.guild].members) {
        return []
      }
      const data = [] // [{"letter": "x", "names":[{"name": "name1", "tag": "tag1"},...]}]

      for (const pId in this.guilds[this.player.guild].members) {
        if (pId < 0) {
          continue
        }

        const member = this.guilds[this.player.guild].members[pId]
        const firstLetter = member.name[0].toUpperCase()

        let firstLetterIdx = -1
        for (const i in data) {
          if (data[i].letter === firstLetter) {
            firstLetterIdx = i
            break
          }
        }

        if (firstLetterIdx === -1) {
          data.push({
            letter: firstLetter,
            names: [
              {
                name: member.name,
                tag: pId
              }
            ]
          })
          continue
        }
        data[firstLetterIdx].names.push({
          name: member.name,
          tag: pId
        })
      }

      for (const i in data) {
        data[i].names.sort((k, m) => {
          if (k.name > m.name)
            return 1
          if (k.name < m.name)
            return -1

          return 0
        })
      }

      data.sort((i, j) => {
        if (i.letter > j.letter)
          return 1
        if (i.letter < j.letter)
          return -1

        return 0
      })

      return data
    }
  },
  watch: {
    'chat.token': {
      handler() {
        if (this.ws?.isOpen) {
          this.resetChatData()
          this.disconnect()
        } else {
          this.resetChatData()
        }
      }
    },
    'ws.lastResponse': {
      handler(ev) {
        if (typeof ev.data === 'undefined') {
          return
        }
        const resp = JSON.parse(ev?.data)

        if (this.initRequestId > 0 && this.initRequestId === resp.req_id) {
          this.tryUpdatePlayerObject(resp)
          this.initRequestId = 0
        }
        this.tryUpdatePinfo(resp)
        this.tryUpdateGuilds(resp)
        this.tryUpdateChatMessages(resp)
        this.tryShowError(resp)
        this.tryUpdateBattleLog(resp)
        this.tryUpdateTranslatedMessages(resp)
        this.tryUpdateNotifyAllResult(resp)
        this.tryUpdatePinnedMessage(resp)
        if (resp?.req_id !== undefined && !!this.chat.lazyTasks[`id_${resp.req_id}`]) {
          this.updateScrollPosition(resp.req_id)
        }
      }
    }
  },
  created() {
    this.chat.isDeveloper = !!this.$route.query?.dev
    this.env = this.$route.query?.env

    this.initWebsocket()
  },
  mounted() {
    if (this.chat.height === null) {
      const clientRect = this.$refs?.chatWrapper?.getBoundingClientRect?.()
      this.chat.height = clientRect?.top ? Math.max(this.$windowHeight, 1000) - clientRect.top - 200 : 700
    }
    this.getChatPreview()
  },
  unmounted() {
    this.resetChatData()
    this.ws?.destroy?.()
  },
  methods: {
    guildMembersListPersonalMessage(form) {
      let csv = ''
      form.members.forEach((player, index) => {
        if (index !== 0) {
          csv += ','
        }
        csv += player.id
      })

      return csv
    },
    convertDivisionNo(division) {
      switch (division) {
        case 1:
          return 4
        case 2:
          return 3
        case 3:
          return 2
        case 4:
          return 1
      }
      return 0
    },
    showEmoji(emoji, tabIndex) {
      const input = this.$refs[`${this.chatTabs}_playerMessage`][0]
      if (input) {
        input.setRangeText(emoji.i, input.selectionStart, input.selectionEnd)
        this.playerMessage[this.chatTabs] = input.value
      } else {
        this.playerMessage[this.chatTabs] =
          this.playerMessage[this.chatTabs] === undefined ? emoji.i : this.playerMessage[this.chatTabs] + emoji.i
      }
      this.showEmojiPicker[tabIndex] = false
    },
    initWebsocket() {
      this.$axios.get('/admin/api/environments/').then((data) => {
        const envUrl = data.data.environments[this.env]
        if (!envUrl) {
          this.$message.error('Env url not defined!')
          return
        }

        this.ws = this.$socket(envUrl)
        this.ws.onOpen(() => {
          this.resetChatData()
          this.initRequestId = this.sendTask('logged/player/init')
        })
      })
    },
    getChatPreview() {
      const endpoint = `${this.envApi}/proxy/chatpreview/guild/getlines/`
      const email = this.$store.getters['auth/userData'].Email

      this.$axios.get(endpoint, {
        params: { u: email, p: '6cq8!@X-bN7U5b+]Lx9OAK?^jLxKSIE!#6Z/>**K', guild_id: this.$route.params?.id }
      }).then((response) => {
        this.tryUpdatePlayerObject(response.data)
        this.tryUpdateGuilds(response.data)
        this.tryUpdateChatMessages(response.data)
        this.tryUpdateBattleLog(response.data)
        this.tryUpdatePinfo(response.data)
        this.$utils.nextLoopEvent().then(() => {
          const scrollerRef = this.$refs?.[`${this.chatTabs}_scroll`]?.[0]
          if (scrollerRef) {
            scrollerRef.setScrollTop(scrollerRef.wrapRef.scrollHeight)
          }
        })
      })
    },
    loadData() {
      const envApi = this.$store.getters['auth/envs']?.[this.$route.query?.env]
      if (!(this.$utils.isDevMode() && this.$route.query?.env !== 'local')) {
        this.envApi = envApi
      }
      this.endPoint = '/proxy/gameplay/guilddetails/'
      const params = {
        id: this.$route.params?.id
      }
      this.$axios
        .get(envApi + this.endPoint, { params })
        .then(({ data }) => {
          data.guild.mount_group_type_expire_days = 30
          data.guild.mount_group_type = data.guild.mount_group_type || 'basic_horse'
          data?.battles?.forEach?.((battle, index) => {
            battle.day = index + 1
          })
          this.externalData = data
          this.getModeratorsFromExternalData()
        })
        .catch(this.$utils.catchError)
    },
    setTitle(apiLabel, data) {
      return `${data?.guild?.Name} #${data?.guild?.ID} - ${this.$route.query?.env}`
    },
    setNewGuildName(formRefer) {
      if (!this.envApi)
        return false
      const params = {
        newGuildName: this.externalData.guild.Name,
        newGuildOpen: this.externalData.guild.IsOpen,
        newGuildRequiredLevel: this.externalData.guild.RequiredLevel,
        newFillWithBots: this.externalData.guild.FillWithBots,
        newFillByZone: this.externalData.guild.FillByZone,
        newDeleteFilledBots: this.externalData.guild.DeleteFilledBots,
        id: this.externalData.guild.ID
      }
      formRefer
        .validateField('guild.Name')
        .then(() => {
          this.saveData(params, 'setNewGuildName')
        })
        .catch(() => {
          this.notifyNotValid()
        })
    },
    setNewGuildDetails() {
      if (!this.envApi)
        return false
      const params = {
        newGuildOpen: this.externalData.guild.IsOpen,
        newGuildRequiredLevel: this.externalData.guild.RequiredLevel,
        newFillWithBots: this.externalData.guild.FillWithBots,
        newFillByZone: this.externalData.guild.FillByZone,
        newDeleteFilledBots: this.externalData.guild.DeleteFilledBots,
        id: this.externalData.guild.ID
      }
      this.saveData(params, 'setNewGuildDetails')
    },
    setNewGuildDescription() {
      if (!this.envApi)
        return false
      const params = {
        newGuildDescription: this.externalData.guild.Description,
        newGuildOpen: this.externalData.guild.IsOpen,
        newGuildRequiredLevel: this.externalData.guild.RequiredLevel,
        newFillWithBots: this.externalData.guild.FillWithBots,
        newFillByZone: this.externalData.guild.FillByZone,
        newDeleteFilledBots: this.externalData.guild.DeleteFilledBots,
        id: this.externalData.guild.ID
      }
      this.saveData(params, 'setNewGuildDescription')
    },
    translateGuildDescription() {
      if (!this.envApi)
        return false
      this.$axios
        .get(`${this.envApi}/proxy/translate/`, {
          params: {
            text: this.externalData.guild.Description,
            lang: 'EN'
          }
        })
        .then(({ data }) => {
          this.guildTranslatedDescription = data.translated_text
        })
        .catch(this.$utils.catchFormErrors(this.externalErrors))
    },
    setGuildFillWithBots() {
      if (!this.envApi)
        return false
      const params = {
        newGuildOpen: this.externalData.guild.IsOpen,
        newGuildRequiredLevel: this.externalData.guild.RequiredLevel,
        newBotFillCountry: this.externalData.guild.BotFillCountry,
        newFillWithBots: this.externalData.guild.FillWithBots,
        newFillByZone: this.externalData.guild.FillByZone,
        newDeleteFilledBots: this.externalData.guild.DeleteFilledBots,
        id: this.externalData.guild.ID
      }
      this.saveData(params, 'setGuildFillWithBots')
    },
    setIsFeatured() {
      if (!this.envApi)
        return false
      this.saveData(
        {
          newGuildOpen: this.externalData.guild.IsOpen,
          newGuildRequiredLevel: this.externalData.guild.RequiredLevel,
          newIsFeatured: this.externalData.guild.IsFeatured,
          newBotFillCountry: this.externalData.guild.BotFillCountry,
          newFillWithBots: this.externalData.guild.FillWithBots,
          newFillByZone: this.externalData.guild.FillByZone,
          newDeleteFilledBots: this.externalData.guild.DeleteFilledBots,
          id: this.externalData.guild.ID
        },
        'setIsFeatured'
      )
    },
    notifyNotValid() {
      this.$notify({
        type: 'warning',
        customClass: 'bg-teal-50 text-red-600 child-inherit-colors',
        message: 'Not valid fields',
        position: 'top-right',
        offset: 80,
        duration: 10000
      })
    },
    notifySaved() {
      this.$message({
        message: 'saved',
        type: 'success',
        offset: 40
      })
    },
    saveData(params, loadingKye = '') {
      this.loading[loadingKye] = true
      this.$axios
        .get(`${this.envApi}/proxy/gameplay/saveguild/${this.externalData.guild.ID}/`, { params })
        .then(({ data }) => {
          this.notifySaved()
          data.guild.mount_group_type_expire_days = 30
          data.guild.mount_group_type = data.guild.mount_group_type || 'basic_horse'
          this.externalData = data
        })
        .catch(this.$utils.catchFormErrors(this.externalErrors))
        .then(() => {
          this.loading[loadingKye] = false
        })
    },
    sendTask(task = 'logged/player/void', additionalData = {}) {
      const data = { ...additionalData }
      data.version = ''
      data.platform = 'Ios'
      data.country_code = ''
      data.access_token = 'b581165696952d71b47d205883de25db'
      data.token = this.chat.token
      data.task = task
      return this.ws.send(data)
    },
    tryUpdatePlayerObject(data) {
      if (data.player) {
        this.player = Object.assign({}, data.player)
      }
    },
    tryUpdateChatMessages(data) {
      let newChatMessage = false
      const newMessageIds = []
      if (this.chat.shouldBeScrolledTab?.[this.chatTabs] === undefined) {
        this.chat.shouldBeScrolledTab[this.chatTabs] = true
      }

      const scrollerRef = this.$refs?.[`${this.chatTabs}_scroll`]?.[0]
      if (!this.chat.shouldBeScrolledTab[this.chatTabs]) {
        this.chat.shouldBeScrolledTab[this.chatTabs] =
          scrollerRef.wrapRef.scrollHeight - scrollerRef.wrapRef.scrollTop - scrollerRef.wrapRef.clientHeight < 1
      }

      if (data.chat) {
        for (const k in data.chat) {
          if (typeof this.chat.messages[k] == 'undefined') {
            newMessageIds.push(k)
            newChatMessage = true
          }

          this.chat.messages[k] = data.chat[k]

          const msgUnix = Math.round(k.substring(1, k.length) * 0.000001)
          this.chat.messages[k].date = new Date(msgUnix).toLocaleString()
          this.chat.messages[k].msgRendered = this.renderEmoji(this.chat.messages[k].msg)
        }
      }

      if (newChatMessage) {
        const chatMessageOrderTmp = [...this.chat.messagesOrder, ...newMessageIds]

        chatMessageOrderTmp.sort((i, j) => {
          const iKey = Number.parseInt(i.substring(1, i.length))
          const jKey = Number.parseInt(j.substring(1, j.length))
          return iKey - jKey
        })
        this.chat.messagesOrder = chatMessageOrderTmp
      }
      this.$utils.nextLoopEvent().then(() => {
        const scrollerRef = this.$refs?.[`${this.chatTabs}_scroll`]?.[0]
        if (scrollerRef && this.chat.shouldBeScrolledTab[this.chatTabs]) {
          scrollerRef.setScrollTop(scrollerRef.wrapRef.scrollHeight)
          this.chat.shouldBeScrolledTab[this.chatTabs] = false
        }
      })
    },
    tryUpdatePinfo(data) {
      if (data.pinfo) {
        for (const i in data.pinfo) {
          let currentPinfo = this.pinfo[i]
          if (!currentPinfo) {
            currentPinfo = {}
          }

          this.pinfo[i] = Object.assign(currentPinfo, data.pinfo[i])
        }
      }
    },
    tryUpdateGuilds(data) {
      if (data.guilds) {
        for (const i in data.guilds) {
          let currentGuild = this.guilds[i]
          if (!currentGuild) {
            currentGuild = {}
          }
          this.guilds[i] = Object.assign(currentGuild, data.guilds[i])

          if (data.guilds[i].members) {
            for (const j in data.guilds[i].members) {
              let currentPinfo = this.pinfo[j]
              if (!currentPinfo) {
                currentPinfo = {}
              }

              this.pinfo[j] = Object.assign(currentPinfo, data.guilds[i].members[j])
            }
          }
        }
      }
    },
    tryShowError(data) {
      if (data.error) {
        this.$message.error(data.error)
      }
      if (data.ymsg) {
        this.$message.info(data.ymsg)
      }
    },
    tryUpdateBattleLog(data) {
      if (data.battle_log) {
        for (const i in data.battle_log) {
          let currentBattleLog = this.battleLog[i]
          if (!currentBattleLog) {
            currentBattleLog = {}
          }
          this.battleLog[i] = Object.assign(currentBattleLog, data.battle_log[i])
        }
        this.updateChatTabs()
      }
    },
    sendMessageToAll() {
      const msg = this.playerMessage.chat_0
      if (msg.includes('@')) {
        this.$message.error('You cannot mention players in broadcast message')
        return
      }

      this.$messageBox
        .confirm('Are you sure?', 'Send message to all')
        .then(() => {
          const allMsg = `/all ${msg}`
          this.sendTask('logged/guild/addchat', {
            msg: allMsg
          })
          this.playerMessage.chat_0 = ''
        })
        .catch(() => {})
    },
    sendMessage(guild2Id, isTournament, bossEventId, isCards) {
      if (!this.playerMessage[this.chatTabs]?.length) {
        return
      }
      let playerMessage = this.playerMessage[this.chatTabs]
      const scrollerRef = this.$refs[`${this.chatTabs}_scroll`][0]
      scrollerRef.setScrollTop(scrollerRef.wrapRef.scrollHeight)

      const mentionTags = []
      const mentionsRegex = /@(\w+)#(\d+)/g
      const mentionMatches = [...playerMessage.matchAll(mentionsRegex)]
      for (const i in mentionMatches) {
        const d = mentionMatches[i]
        mentionTags.push(d[2])
        playerMessage = playerMessage.replace(d[0], `@${d[1]}`)
      }

      this.sendTask('logged/guild/addchat', {
        msg: playerMessage || '',
        guild2_id: guild2Id,
        boss_event_id: bossEventId,
        is_tournament: isTournament,
        is_cards: isCards,
        mention_tags: mentionTags.join(',')
      })
      this.playerMessage[this.chatTabs] = ''
    },
    updateChatTabs() {
      const tabs = [
        {
          name: 'Guild chat',
          guild2_id: 0,
          boss_event_id: 0,
          is_tournament: 0,
          is_cards: 0,
          ts: 0
        }
      ]

      const nowUnix = Math.floor(Date.now() / 1000)
      for (const i in this.battleLog) {
        const battleLog = this.battleLog[i]

        if (battleLog.typ === 'battle' || battleLog.typ === 'tournament') {
          let guild2 = battleLog.guild1
          if (guild2 === this.player.guild) {
            guild2 = battleLog.guild2
          }

          let chatName = this.guilds[guild2] ? this.guilds[guild2].n : 'undefined'
          if (battleLog.typ === 'tournament') {
            chatName = `Tournament: ${chatName}`
          }
          const endTs = battleLog.livets + battleLog.linescnt * battleLog.linesec

          if (endTs + 3600 < nowUnix) {
            continue
          }

          tabs.push({
            name: chatName,
            guild2_id: guild2,
            boss_event_id: 0,
            is_tournament: battleLog.typ === 'tournament' ? 1 : 0,
            is_cards: 0,
            ts: endTs
          })
        }
      }

      tabs.sort((a, b) => {
        if (a.is_tournament === 0 && b.is_tournament === 1) {
          return -1
        }
        return a.ts - b.ts
      })

      let tabsChanged = tabs.length !== this.chat.tabs.length

      let forceBreak = false
      for (const i in this.chat.tabs) {
        if (forceBreak) {
          break
        }
        const curTab = this.chat.tabs[i]
        const newTab = tabs[i]

        for (const j in curTab) {
          if (curTab[j] !== newTab[j]) {
            tabsChanged = true
            forceBreak = true
            break
          }
        }
      }

      if (!tabsChanged) {
        return
      }

      this.chat.tabs = tabs

      this.chat.tabs.forEach((tab, index) => {
        if (this.showEmojiPicker[index] === undefined) {
          this.showEmojiPicker[index] = false
        }
        if (this.visibleMembersAutocomplete[index] === undefined) {
          this.visibleMembersAutocomplete[index] = false
        }
      })
    },
    resetChatData() {
      this.player = {}
      this.pinfo = {}
      this.guilds = {}
      this.chat.messages = {}
      this.chat.messagesOrder = []
      this.chat.shouldBeScrolledTab = {}
      this.battleLog = {}
      this.chat.lazyTasks = {}
      this.chat.pinnedMessage = {}
      this.chat.loading = {}
    },
    getModeratorsFromExternalData() {
      if (this.externalData?.members?.length) {
        for (const i in this.externalData.members) {
          const member = this.externalData.members[i]

          if (member.is_moderator) {
            this.moderators.push({
              value: member.token,
              label: member.member?.name
            })
          }
        }
      }
    },
    connect() {
      this.ws.openSocket()
    },
    disconnect() {
      this.$message({
        message: 'You have been disconnected.',
        type: 'warning',
        offset: 40
      })
      this.ws?.destroy?.()
    },
    renderEmoji(text) {
      const regex = emojiRegex()
      if (regex.test(text)) {
        const rendered = text
          .replaceAll(regex, (matched) => {
            return `[e-m_o-j_I]${matched}[e-m_o-j_I]`
          })
          .split('[e-m_o-j_I]')
          .map((el) => {
            return { val: el, html: regex.test(el) }
          })

        return rendered
      }
      return null
    },

    lazyLoad(ev, tabIndex, tabData) {
      if (ev.scrollTop === 0) {
        const scrollerRef = this.$refs?.[`${this.chatTabs}_scroll`]?.[0]
        const scrollHeight = scrollerRef.wrapRef.scrollHeight
        let from_line_id = 0
        for (const i in this.chat.messagesOrder) {
          const msg = this.chat.messages[this.chat.messagesOrder[i]]

          if (this.isMessageInTab(msg, tabData)) {
            if (from_line_id === 0) {
              from_line_id = this.chat.messagesOrder[i].substring(1)
            }
          }
        }

        this.chat.loading[`${this.chatTabs}_loading`] = true
        let idTask = 0
        if (!this.ws?.isOpen) {
          idTask = ++this.idTaskRestApi
          const endpoint = `${this.envApi}/proxy/chatpreview/guild/lazyload/`
          const email = this.$store.getters['auth/userData'].Email

          this.$axios.get(endpoint, {
            params: {
              u: email,
              p: '6cq8!@X-bN7U5b+]Lx9OAK?^jLxKSIE!#6Z/>**K',
              guild_id: this.$route.params?.id,
              guild2_id: tabData.guild2_id,
              boss_event_id: tabData.boss_event_id,
              is_tournament: tabData.is_tournament ? 1 : 0,
              is_cards: tabData.is_cards ? 1 : 0,
              from_line_id
            }
          }).then((response) => {
            this.tryUpdateChatMessages(response.data)
            this.tryUpdatePinfo(response.data)
            this.updateScrollPosition(idTask)
          })
        } else {
          idTask = this.sendTask('logged/guild/getchat', {
            guild2Id: tabData.guild2_id,
            boss_event_id: tabData.boss_event_id,
            is_tournament: tabData.is_tournament,
            is_cards: tabData.is_cards,
            from_line_id
          })
          this.idTaskRestApi++
        }

        this.chat.lazyTasks[`id_${idTask}`] = { scrollHeight }
      }
    },

    translateMessage(msgId, tabData) {
      if (!this.ws?.isOpen) {
        const endpoint = `${this.envApi}/proxy/chatpreview/translatechat/`
        const email = this.$store.getters['auth/userData'].Email

        this.$axios.get(endpoint, {
          params: {
            u: email,
            p: '6cq8!@X-bN7U5b+]Lx9OAK?^jLxKSIE!#6Z/>**K',
            guild_id: this.$route.params?.id,
            msg_ids: msgId.substr(1, msgId.length),
            guild2_id: tabData.guild2_id,
            boss_event_id: tabData.boss_event_id,
            is_tournament: tabData.is_tournament ? 1 : 0,
            is_cards: tabData.is_cards ? 1 : 0
          }
        }).then((response) => {
          this.tryUpdateTranslatedMessages(response.data)
        })

        return
      }

      this.sendTask('logged/guild/translatechat', {
        msg_id: msgId.substr(1, msgId.length),
        guild2_id: tabData.guild2_id,
        boss_event_id: tabData.boss_event_id,
        is_tournament: tabData.is_tournament,
        is_cards: tabData.is_cards
      })
    },

    translateAllMessages(tabData) {
      this.loading.translate = true
      const envApi = this.$store.getters['auth/envs']?.[this.$route.query?.env]
      const endpoint = `${envApi}/proxy/chatpreview/translatechat/`
      const email = this.$store.getters['auth/userData'].Email

      let msgIds = ''

      for (const i in this.chat.messagesOrder) {
        const msgId = this.chat.messagesOrder[i]
        const isServerMessage = this.chat.messages[msgId].msg.substr(0, 2) === '{{'
        if (this.chat.translatedMessages[msgId] === undefined && !isServerMessage) {
          if (i > 0) {
            msgIds += ','
          }
          msgIds += msgId.substr(1, msgId.length)
        }
      }

      this.$axios
        .get(endpoint, {
          params: {
            u: email,
            p: '6cq8!@X-bN7U5b+]Lx9OAK?^jLxKSIE!#6Z/>**K',
            guild_id: this.$route.params?.id,
            msg_ids: msgIds,
            guild2_id: tabData.guild2_id,
            boss_event_id: tabData.boss_event_id,
            is_tournament: tabData.is_tournament ? 1 : 0,
            is_cards: tabData.is_cards ? 1 : 0
          }
        })
        .then((response) => {
          this.tryUpdateTranslatedMessages(response.data)
          this.loading.translate = false
        })
    },

    tryUpdateTranslatedMessages(resp) {
      if (resp.translated_chat) {
        for (const i in resp.translated_chat) {
          this.chat.translatedMessages[i] = resp.translated_chat[i]
        }
      }
    },

    tryUpdateNotifyAllResult(resp) {
      if (resp.notify_all_result) {
        let errMsg = ''
        if (resp.notify_all_result === 'only_gm') {
          errMsg = 'Only GM can send message to all'
        } else if (resp.notify_all_result === 'too_many') {
          errMsg = 'Broadcast limit exceeded'
        }

        if (errMsg !== '')
          this.$message.error(errMsg)
      }
    },

    tryUpdatePinnedMessage(resp) {
      if (resp.chat_pinned) {
        this.chat.pinnedMessage = {}
        for (const msgId in resp.chat_pinned) {
          const msgData = resp.chat_pinned[msgId]
          if (msgData.pin > 0) {
            const msgTs = Math.round(msgId.substr(1, msgId.length) * 0.000001)
            this.chat.pinnedMessage = { msg_id: msgId, ...msgData }
            this.chat.pinnedMessage.date = new Date(msgTs).toLocaleString()
            this.chat.pinnedMessage.msgRendered = this.renderEmoji(this.chat.pinnedMessage)
          }
        }
      }
    },

    isMessageInTab(message, tabData) {
      return (
        message.g2 === tabData.guild2_id &&
        message.rsh === tabData.boss_event_id
        // message.it === tabData.is_tournament &&
        // message.ic === tabData.is_cards
      )
    },

    canBePinned(msgId) {
      const msg = this.chat?.messages[msgId]
      if (!msg) {
        return false
      }

      return msg.t === 2 && this.chat.pinnedMessage.msg_id !== msgId
    },

    pinMessage(msgId, setAsPinned) {
      let messageBoxTitle = 'Pin message'
      if (!setAsPinned) {
        messageBoxTitle = 'Unpin message'
      }

      this.$messageBox
        .confirm('Are you sure?', messageBoxTitle)
        .then(() => {
          this.sendTask('logged/guild/pinchat', {
            msg_id: msgId.substring(1, msgId.length),
            pin: setAsPinned ? 1 : 0
          })
        })
        .catch(() => {})
    },

    fillNicknameInMessage(name, tag, tab) {
      if (!this.playerMessage[this.chatTabs]) {
        this.playerMessage[this.chatTabs] = ''
      }
      const msgLen = this.playerMessage[this.chatTabs].length

      let prefix = ' @'
      if (msgLen > 0 && this.playerMessage[this.chatTabs][msgLen - 1] === '@') {
        prefix = ''
      }
      this.playerMessage[this.chatTabs] += `${prefix + name}#${tag}`
      this.visibleMembersAutocomplete[tab] = false
    },

    canShowMembersAutocomplete(event, tab) {
      this.visibleMembersAutocompleteOffset[tab] = (event.target.selectionStart || 0) + 8
      if (event.key !== '@')
        return
      this.$nextTick(() => {
        this.visibleMembersAutocomplete[tab] = true
      })
    },
    updateScrollPosition(requestId) {
      this.$utils.nextLoopEvent().then(() => {
        const scrollerRef = this.$refs?.[`${this.chatTabs}_scroll`]?.[0]
        const scrollHeight = scrollerRef.wrapRef.scrollHeight
        if (scrollerRef && scrollHeight && this.chat.lazyTasks?.[`id_${requestId}`]?.scrollHeight) {
          scrollerRef.setScrollTop(scrollHeight - this.chat.lazyTasks[`id_${requestId}`].scrollHeight - 25)
        }
        this.chat.loading[`${this.chatTabs}_loading`] = false
      })
    },
    onActiveChatTab() {
      this.$utils.nextLoopEvent().then(() => {
        const scrollerRef = this.$refs?.chat_0_scroll?.[0]
        if (scrollerRef) {
          scrollerRef.setScrollTop(scrollerRef.wrapRef.scrollHeight)
        }
      })
    }
  }
}
</script>

<template>
  <crud-details-page
    :actions="{
      crudSave: false,
      crudDuplicate: false,
      crudNewVersion: false,
      crudUsage: false,
      crudDelete: false
    }"
    :versioned="false"
    disable-top-margin
    :external-data="externalData"
    proxy
    :render-title="setTitle"
    @is-access="loadData"
  >
    <template #form="{ form, formRefer }">
      <fields-col>
        <el-tabs
          v-model="guildTabs"
          class="w-full"
          @tab-change.once="onActiveChatTab"
        >
          <el-tab-pane
            label="General"
            name="general"
          >
            <div class="sm:px-10">
              <div class="text-sm">
                Env: {{ form.env }}
              </div>
              <div class="text-lg font-bold">
                Membersi gildii: {{ form.guild.Name }}
              </div>
              <div class="mb-2 mt-4">
                {{ form.info }}
              </div>
              <filtering-table
                :rows="form.members"
                :max-height="`${Math.max(300, $windowHeight * ($windowWidth > 640 ? 0.72 : 0.6))}px`"
                header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                cell-class-name="gs-col-padding-mini"
                fit
                :columns="{
                  id: { header: 'ID', sortable: true, filterField: true, type: 'uint', elAttr: { width: 80 } },
                  name: {
                    sortable: true,
                    filterField: true,
                    type: 'string',
                    elAttr: { minWidth: 130, formatter: (row) => row?.member?.name }
                  },
                  is_bot: { header: 'IsBot', sortable: true, filterField: true, type: 'bool', elAttr: { width: 70 } },
                  country: { sortable: true, filterField: true, type: 'string', elAttr: { width: 70 } },
                  created: { header: 'Created at', sortable: true, filterField: true, type: 'string', elAttr: { width: 160 } },
                  last_active: {
                    header: 'Last active',
                    sortable: true,
                    filterField: true,
                    type: 'string',
                    elAttr: { width: 90 }
                  },
                  guild_join: { header: 'Joined at', sortable: true, filterField: true, type: 'string', elAttr: { width: 160 } },
                  level: { sortable: true, filterField: true, type: 'uint', elAttr: { width: 90 } },
                  season_points: {
                    header: 'Season points',
                    sortable: true,
                    filterField: true,
                    type: 'uint',
                    elAttr: { width: 110 }
                  },
                  energy: { sortable: true, filterField: true, type: 'uint', elAttr: { width: 70 } },
                  gems: { sortable: true, filterField: true, type: 'uint', elAttr: { width: 70 } },
                  gm: { header: 'GM', sortable: true, filterField: true, type: 'string', elAttr: { width: 90 } },
                  revenue: { sortable: true, filterField: true, type: 'string', elAttr: { width: 90 } },
                  tier: { sortable: true, filterField: true, type: 'uint', elAttr: { width: 50 } },
                  same_device_id: {
                    header: 'Same Device',
                    sortable: true,
                    filterField: true,
                    type: 'string',
                    elAttr: { width: 100 }
                  }
                }"
                index-rows-column
                :page-sizes="[50, 100, 200]"
                hide-refresh-btn
              >
                <template #cell_name="{ row }">
                  <router-link
                    :to="
                      $utils.bindStaticParams({
                        name: 'gameplay-players-details',
                        params: { id: row.id },
                        query: { env: form?.env }
                      })
                    "
                    target="_blank"
                    class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
                  >
                    {{ row.member.name }}
                  </router-link>
                </template>
              </filtering-table>
              <div class="pl-1 pt-1 text-sm">
                <span v-if="form.dummies">+{{ form.dummies + (form.dummies > 1 ? ' dummies' : ' dummy') }}, </span>Total revenue:
                ${{ form.total_revenue }}
              </div>
              <div class="mt-10 text-lg font-bold">
                Walki gildii: {{ form.guild.Name }}
              </div>
              <filtering-table
                :rows="form.battles"
                :max-height="`${Math.max(300, $windowHeight * ($windowWidth > 640 ? 0.72 : 0.6))}px`"
                header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                cell-class-name="gs-col-padding-mini"
                fit
                :columns="{
                  day: { sortable: true, filterField: true, type: 'uint', elAttr: { width: 80 } },
                  date: { header: 'Date (Warsaw)', sortable: true, filterField: true, type: 'string', elAttr: { width: 160 } },
                  name: {
                    sortable: true,
                    filterField: true,
                    type: 'string',
                    elAttr: { width: 160, formatter: (row) => row?.guild?.name }
                  },
                  is_home: { header: 'Is Home', sortable: true, filterField: true, type: 'bool', elAttr: { width: 100 } },
                  result: { sortable: true, filterField: true, type: 'string', elAttr: { width: 100 } },
                  battle: {
                    sortable: true,
                    filterField: true,
                    type: 'uint',
                    elAttr: { width: 100, formatter: (row) => row?.battle?.id || 0 }
                  }
                }"
                :page-sizes="[50, 100, 200]"
                :row-class-name="
                  ({ row }) => {
                    return row.day > 15 ? 'bg-indigo-50' : undefined;
                  }
                "
                hide-refresh-btn
              >
                <template #cell_name="{ row }">
                  <router-link
                    :to="
                      $utils.bindStaticParams({
                        name: 'gameplay-guild-list-details',
                        params: { id: row.guild.id },
                        query: { env: form.env }
                      })
                    "
                    target="_blank"
                    class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
                  >
                    {{ row.guild.name }}
                  </router-link>
                </template>
                <template #cell_battle="{ row }">
                  <a
                    v-if="row?.battle?.id"
                    class="block cursor-pointer text-blue-500 underline underline-offset-4 hover:text-blue-600"
                    @click="previewActions?.previewBattle?.({ battle_id: row.battle.id })"
                  >{{ row.battle.id }}</a>
                  <span v-else>-</span>
                </template>
              </filtering-table>
              <div class="mt-10 text-lg font-bold">
                Płatności z ostatnich 30 dni
              </div>
              <filtering-table
                :rows="form.payments"
                :max-height="`${Math.max(300, $windowHeight * ($windowWidth > 640 ? 0.72 : 0.6))}px`"
                header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                cell-class-name="gs-col-padding-mini"
                fit
                :columns="{
                  date: { sortable: true, filterField: true, type: 'string', elAttr: { width: 200 } },
                  player: { sortable: true, filterField: true, type: 'string', elAttr: { width: 160 } },
                  offer_id: { header: 'OfferId', sortable: true, filterField: true, type: 'uint', elAttr: { width: 100 } },
                  offer_name: { sortable: true, filterField: true, type: 'string', elAttr: { width: 200 } },
                  price: { sortable: true, filterField: true, type: 'float', elAttr: { width: 100 } },
                  is_voided: { header: 'Is Voided', sortable: true, filterField: true, type: 'int', elAttr: { width: 100 } },
                  voided_time: {
                    header: 'Voided Time',
                    sortable: true,
                    filterField: true,
                    type: 'string',
                    elAttr: { width: 160 }
                  },
                  voided_reason: {
                    header: 'Voided Reason',
                    sortable: true,
                    filterField: true,
                    type: 'string',
                    elAttr: { width: 200 }
                  }
                }"
                :page-sizes="[50, 100, 200]"
                hide-refresh-btn
              />
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Stats"
            name="stats"
            lazy
          >
            <el-scrollbar class="sm:px-10">
              <table class="gs-font-scaled gs-basic-table sm:min-w-[400px] sm:w-full">
                <tr>
                  <td>Created</td>
                  <td>{{ $utils.formatUTCDateTime(form.guild.Created) }}</td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>{{ form.guild.Country }}</td>
                </tr>
                <tr>
                  <td>Zone</td>
                  <td>{{ form.guild.Zone }}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{{ form.guild.Description }}</td>
                </tr>
                <tr>
                  <td>Power</td>
                  <td>{{ form.guild.Power }}</td>
                </tr>
                <tr>
                  <td>Score</td>
                  <td>{{ form.guild.Score }}</td>
                </tr>
                <tr>
                  <td>Division</td>
                  <td>{{ form.guild.DivisionNo }}</td>
                </tr>
                <tr>
                  <td>Group</td>
                  <td>{{ form.guild.GroupNo }}</td>
                </tr>
                <tr>
                  <td>Players</td>
                  <td>{{ form.guild.CountPlayers }}</td>
                </tr>
                <tr>
                  <td>Bots</td>
                  <td>{{ form.guild.CountBots }}</td>
                </tr>
                <tr>
                  <td>Required Level</td>
                  <td>{{ form.guild.RequiredLevel }}</td>
                </tr>
                <tr>
                  <td>Is Open</td>
                  <td>{{ form.guild.IsOpen }}</td>
                </tr>
                <tr>
                  <td>Is Searchable</td>
                  <td>{{ form.guild.IsSearchable }}</td>
                </tr>
                <tr>
                  <td>Season Won Battles</td>
                  <td>{{ form.guild.SeasonWonBattles }}</td>
                </tr>
                <tr>
                  <td>Season Won Lines</td>
                  <td>{{ form.guild.SeasonWonLines }}</td>
                </tr>
                <tr>
                  <td>Previous Season Division</td>
                  <td>{{ form.guild.PrevSeasonDivisionNo }}</td>
                </tr>
                <tr>
                  <td>Previous Season Rank</td>
                  <td>{{ form.guild.PrevSeasonRank }}</td>
                </tr>
                <tr>
                  <td>Pinned message</td>
                  <td>{{ form.guild.PinnedMessage }}</td>
                </tr>
              </table>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane
            label="Season Ranking"
            name="season_ranking"
            lazy
          >
            <div class="max-w-xl sm:w-full sm:max-w-full sm:px-10">
              <div v-if="!form.guild_season_ranking.length">
                Guild is not assigned to season ranking
              </div>
              <filtering-table
                v-else
                :rows="form.guild_season_ranking"
                :max-height="`${Math.max(300, $windowHeight * ($windowWidth > 640 ? 0.72 : 0.6))}px`"
                header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                cell-class-name="gs-col-padding-mini"
                fit
                :columns="{
                  no: { header: 'No.', sortable: true, filterField: true, type: 'uint', elAttr: { width: 90 } },
                  name: { sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 200 } },
                  battles: { header: 'Won Battles', sortable: true, filterField: true, type: 'uint', elAttr: { width: 120 } },
                  lines: { header: 'Won Lines', sortable: true, filterField: true, type: 'uint', elAttr: { width: 120 } }
                }"
                :page-sizes="[50, 100, 200]"
                hide-refresh-btn
              >
                <template #cell_no="{ row }">
                  <span :class="{ 'font-black': row.ID === form.guild.ID }">{{ row.no }}</span>
                </template>
                <template #cell_name="{ row }">
                  <router-link
                    :to="
                      $utils.bindStaticParams({
                        name: 'gameplay-guild-list-details',
                        params: { id: row.ID },
                        query: { env: form?.env }
                      })
                    "
                    target="_blank"
                    class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
                  >
                    {{ row.name }}
                  </router-link>
                </template>
              </filtering-table>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Managing"
            name="managing"
            lazy
          >
            <div>
              <el-card
                shadow="never"
                class="mt-10"
              >
                <fields-col>
                  <crud-field-text
                    :sm="18"
                    api-field-name="guild.Name"
                    label="Guild name"
                    required
                  />
                  <crud-field-slot
                    :sm="6"
                    empty-label
                    label="Change guild name"
                    class="text-center"
                  >
                    <el-popconfirm
                      title="Are you sure?"
                      @confirm="setNewGuildName(formRefer)"
                    >
                      <template #reference>
                        <el-button
                          class="gs-height-related-sm gs-font-scaled font-related gs-btn-primary min-w-[230px]"
                          :disabled="!envApi"
                          :loading="loading.setNewGuildName"
                        >
                          Change guild name
                        </el-button>
                      </template>
                    </el-popconfirm>
                  </crud-field-slot>
                </fields-col>
                <fields-col>
                  <fields-col :sm="18">
                    <crud-field-switcher
                      api-field-name="guild.IsOpen"
                      label="Is open"
                      class="flex-none"
                      doc-icon-class="-right-3 top-0"
                    />
                    <crud-field-text
                      :sm="6"
                      api-field-name="guild.RequiredLevel"
                      label="Required level"
                    />
                  </fields-col>
                  <crud-field-slot
                    :sm="6"
                    empty-label
                    label="Change guild details"
                    class="text-center"
                  >
                    <el-popconfirm
                      title="Are you sure?"
                      @confirm="setNewGuildDetails()"
                    >
                      <template #reference>
                        <el-button
                          class="gs-height-related-sm gs-font-scaled font-related gs-btn-primary min-w-[230px]"
                          :disabled="!envApi"
                          :loading="loading.setNewGuildDetails"
                        >
                          Change guild details
                        </el-button>
                      </template>
                    </el-popconfirm>
                  </crud-field-slot>
                </fields-col>
              </el-card>
              <el-card
                shadow="never"
                class="relative mt-7"
              >
                <fields-col>
                  <crud-field-text
                    :sm="18"
                    api-field-name="guild.Description"
                    label="Guild description"
                  />
                  <crud-field-slot
                    :sm="6"
                    empty-label
                    label="Change guild description"
                    class="text-center"
                  >
                    <el-popconfirm
                      title="Are you sure?"
                      @confirm="setNewGuildDescription()"
                    >
                      <template #reference>
                        <el-button
                          class="gs-height-related-sm gs-font-scaled font-related gs-btn-primary min-w-[230px]"
                          :disabled="!envApi"
                          :loading="loading.setNewGuildDescription"
                        >
                          Change guild description
                        </el-button>
                      </template>
                    </el-popconfirm>
                  </crud-field-slot>
                </fields-col>
                <div class="font-related-xss absolute bottom-3 left-5 text-neutral-500">
                  * to remove <i>"Description"</i>
                  send space: " "
                </div>
                <fields-col>
                  <crud-field-text
                    v-model="guildTranslatedDescription"
                    api-field-name="guildTranslatedDescription"
                    :sm="18"
                    disabled
                    label="Guild translated description"
                  />
                  <crud-field-slot
                    :sm="6"
                    empty-label
                    label="Translate guild description"
                    class="text-center"
                  >
                    <el-popconfirm
                      title="Are you sure?"
                      @confirm="translateGuildDescription()"
                    >
                      <template #reference>
                        <el-button
                          class="gs-height-related-sm gs-font-scaled font-related gs-btn-primary min-w-[230px]"
                          :disabled="!envApi"
                          :loading="loading.translateGuildDescription"
                        >
                          Translate guild description
                        </el-button>
                      </template>
                    </el-popconfirm>
                  </crud-field-slot>
                </fields-col>
              </el-card>
              <el-card
                shadow="never"
                class="relative mt-7"
              >
                <fields-col>
                  <crud-field-text
                    :sm="5"
                    api-field-name="guild.BotFillCountry"
                    label="Bot fill country"
                  />
                  <fields-col :sm="13">
                    <crud-field-switcher
                      api-field-name="guild.FillWithBots"
                      label="Fill with bots"
                      class="flex-none"
                      doc-icon-class="-right-3 top-0"
                    />
                    <crud-field-switcher
                      api-field-name="guild.FillByZone"
                      label="Fill by zone"
                      class="flex-none"
                      doc-icon-class="-right-3 top-0"
                    />
                    <crud-field-switcher
                      api-field-name="guild.DeleteFilledBots"
                      label="Delete with bots"
                      class="flex-none"
                      doc-icon-class="-right-3 top-0"
                    />
                  </fields-col>
                  <crud-field-slot
                    :sm="6"
                    empty-label
                    label="Set fill with bots"
                    class="text-center"
                  >
                    <el-popconfirm
                      title="Are you sure?"
                      @confirm="setGuildFillWithBots()"
                    >
                      <template #reference>
                        <el-button
                          class="gs-height-related-sm gs-font-scaled font-related gs-btn-primary min-w-[230px]"
                          :disabled="!envApi"
                          :loading="loading.setGuildFillWithBots"
                        >
                          Set fill with bots
                        </el-button>
                      </template>
                    </el-popconfirm>
                  </crud-field-slot>
                </fields-col>
                <div class="font-related-xss absolute bottom-3 left-5 text-neutral-500">
                  * to remove <i>"Fill with bots"</i> send space: " "
                </div>
              </el-card>
              <el-card
                shadow="never"
                class="mt-10"
              >
                <fields-col>
                  <fields-col :sm="18">
                    <crud-field-switcher
                      api-field-name="guild.IsFeatured"
                      label="Is Featured in recommended search"
                      class="flex-none"
                    />
                  </fields-col>
                  <crud-field-slot
                    :sm="6"
                    empty-label
                    label="Change guild featured flag"
                    class="text-center"
                  >
                    <el-popconfirm
                      title="Are you sure?"
                      @confirm="setIsFeatured"
                    >
                      <template #reference>
                        <el-button
                          class="gs-height-related-sm gs-font-scaled font-related gs-btn-primary min-w-[230px]"
                          :disabled="!envApi"
                          :loading="loading.setIsFeatured"
                        >
                          Change featured flag
                        </el-button>
                      </template>
                    </el-popconfirm>
                  </crud-field-slot>
                </fields-col>
              </el-card>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Chat"
            name="chat"
          >
            <div class="gs-font-scaled mx-auto max-w-5xl">
              <template v-if="moderators.length">
                <fields-col class="-ml-5 sm:ml-auto">
                  <crud-field-select
                    :sm="6"
                    :options="moderators"
                    api-field-name="token"
                    :label="ws?.isOpen ? 'You joined the chat as:' : 'Join the chat as:'"
                    :form="chat"
                  >
                    <template #fieldAppend>
                      <el-icon
                        v-if="ws?.isOpen"
                        class="gs-scaled-icon-lg ml-2.5 text-cyan-500"
                      >
                        <el-tooltip
                          effect="light"
                          content="You are connected"
                          placement="top"
                          :show-after="400"
                        >
                          <icon-ify
                            icon="fluent:chat-24-regular"
                            class="h-full w-full"
                          />
                        </el-tooltip>
                      </el-icon>
                      <el-icon
                        v-else
                        class="gs-scaled-icon-lg ml-2.5 text-neutral-400"
                      >
                        <el-tooltip
                          effect="light"
                          content="You are disconnected"
                          placement="top"
                          :show-after="400"
                        >
                          <icon-ify
                            icon="fluent:chat-off-24-regular"
                            class="h-full w-full"
                          />
                        </el-tooltip>
                      </el-icon>
                    </template>
                  </crud-field-select>
                  <crud-field-slot
                    :sm="8"
                    label="action btns"
                    empty-label
                  >
                    <template #default>
                      <el-button
                        v-if="!ws?.isOpen"
                        class="gs-btn-outlined-primary"
                        :class="{ 'gs-btn-outlined-primary': !!chat?.token, 'gs-btn-outlined-primary-neutral': !chat?.token }"
                        :disabled="ws?.isOpen || !chat?.token"
                        @click="connect"
                      >
                        connect
                      </el-button>
                      <el-button
                        v-if="!!ws?.isOpen"
                        class="gs-btn-outlined-danger-neutral"
                        @click="disconnect"
                      >
                        disconnect
                      </el-button>
                    </template>
                  </crud-field-slot>
                </fields-col>
              </template>
              <div
                v-else
                class="mt-6 max-w-xs sm:max-w-none"
              >
                To activate the chat, one of
                <a
                  class="cursor-pointer font-semibold text-sky-500 hover:text-sky-600"
                  @click="guildTabs = 'general'"
                >the guild members</a>
                must have the "Is moderator" option set.
              </div>

              <div ref="chatWrapper">
                <el-tabs
                  v-model="chatTabs"
                  class="max-w-xs sm:w-full sm:max-w-none"
                >
                  <el-tab-pane
                    v-for="(tabData, tabIndex) in chat.tabs"
                    :key="tabIndex"
                    v-loading="chat.loading[`chat_${tabIndex}_loading`]"
                    :label="tabData.name"
                    :name="`chat_${tabIndex}`"
                  >
                    <div v-if="chat.pinnedMessage && tabIndex === 0 && Object.keys(chat.pinnedMessage).length">
                      <div class="flex flex-col border-b-4 border-slate-50 pb-2">
                        <div class="mb-1">
                          Pinned message:
                        </div>
                        <div
                          class="gs-chat-box relative inline-block pb-2 pl-4 pr-6 pt-3"
                          :class="{
                            'ml-auto mr-8 rounded-lg rounded-br-none bg-yellow-500 text-white ':
                              chat.pinnedMessage.p !== player.id,
                            'own ml-8 mr-auto rounded-lg rounded-bl-none bg-yellow-500 text-white':
                              chat.pinnedMessage.p === player.id
                          }"
                        >
                          <sup class="absolute right-2 top-2">{{ chat.pinnedMessage.date }}</sup>
                          <div class="absolute -right-8 top-1 flex w-6 flex-col items-start">
                            <icon-ify
                              v-if="playerIsGuildMaster"
                              icon="mdi:remove-circle"
                              class="gs-scaled-icon-xs mb-0.5 cursor-pointer text-neutral-200 hover:text-red-500 active:opacity-80"
                              @click="pinMessage(chat.pinnedMessage.msg_id, false)"
                            />
                          </div>
                          <div class="flex flex-row flex-nowrap">
                            <p>
                              <strong class="pr-4">{{
                                pinfo[chat.pinnedMessage.p] ? pinfo[chat.pinnedMessage.p].name : 'undefined'
                              }}</strong>
                            </p>
                          </div>
                          <p
                            class="pt-2"
                            style="min-width: 180px"
                          >
                            {{ chat.pinnedMessage.msg }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <el-scrollbar
                      :ref="`chat_${tabIndex}_scroll`"
                      :height="chat.height"
                      @scroll="lazyLoad($event, tabIndex, tabData)"
                    >
                      <div class="flex flex-col pb-4 pr-4">
                        <template
                          v-for="(msgId, idx) in chat.messagesOrder"
                          :key="idx"
                        >
                          <div
                            v-if="isMessageInTab(chat.messages[msgId], tabData)"
                            class="mt-3 flex"
                          >
                            <div
                              class="gs-chat-box relative inline-block pb-2 pl-4 pr-6 pt-3"
                              :class="{
                                'ml-auto mr-8 rounded-lg rounded-br-none bg-blue-600 text-white ':
                                  chat.messages[msgId].p !== player.id,
                                'own ml-8 mr-auto rounded-lg rounded-bl-none bg-slate-300 text-gray-600':
                                  chat.messages[msgId].p === player.id,
                                'border-4 border-yellow-400': chat.messages[msgId].t === 2 || chat.messages[msgId].t === 5
                              }"
                            >
                              <sup class="absolute right-2 top-2">{{ chat.messages[msgId].date }}</sup>
                              <div class="absolute -right-8 top-1 flex w-6 flex-col items-start">
                                <icon-ify
                                  v-if="playerIsGuildMaster && canBePinned(msgId)"
                                  icon="fe:check-circle"
                                  class="gs-scaled-icon-xs mb-0.5 cursor-pointer text-neutral-200 hover:text-green-500 active:opacity-80"
                                  @click="pinMessage(msgId, true)"
                                />
                                <icon-ify
                                  v-if="!chat.translatedMessages[msgId]"
                                  icon="cil:language"
                                  class="gs-scaled-icon-md mb-0.5 cursor-pointer text-neutral-200 hover:text-cyan-600 active:opacity-80"
                                  @click="translateMessage(msgId, tabData)"
                                />
                              </div>
                              <div class="flex flex-row flex-nowrap">
                                <p>
                                  <strong class="pr-4">{{
                                    pinfo[chat.messages[msgId].p] ? pinfo[chat.messages[msgId].p].name : 'undefined'
                                  }}</strong>
                                </p>
                              </div>
                              <p
                                class="pt-2"
                                style="min-width: 180px"
                              >
                                <template v-if="chat.messages[msgId]?.msgRendered === null">
                                  {{ chat.messages[msgId].msg }}
                                  <i v-if="chat.translatedMessages[msgId]"><br>{{ chat.translatedMessages[msgId].msg }}</i>
                                </template>
                                <template
                                  v-for="(msg, index) in chat.messages[msgId].msgRendered"
                                  :key="index"
                                >
                                  <span
                                    v-if="msg.html"
                                    class="gs-chat-emoji"
                                    v-html="msg.val"
                                  />
                                  <template v-else>
                                    {{ msg.val }}
                                  </template>
                                </template>
                              </p>
                            </div>
                            <div class="clear-both" />
                          </div>
                        </template>
                      </div>
                    </el-scrollbar>
                    <el-auto-resizer>
                      <template #default="{ width }">
                        <div class="mb-2 border-t-2 border-gray-200 pt-4 sm:mb-0">
                          <div
                            v-if="!envApi"
                            class="font-black uppercase text-red-500"
                          >
                            Note: you are on localhost (or dev mode) but connected to a different environment !!! For developer
                            tests choose local environment.
                          </div>
                          <el-popover
                            v-if="visibleMembersAutocomplete[tabIndex] !== undefined"
                            v-model:visible="visibleMembersAutocomplete[tabIndex]"
                            placement="top"
                            trigger="click"
                            :width="300"
                            :show-arrow="false"
                          >
                            <template #reference>
                              <div
                                class="invisible relative inline-block h-0"
                                :style="{ left: `min(${visibleMembersAutocompleteOffset}ch, ${width - 288}px)` }"
                              >
                                _
                              </div>
                            </template>
                            <template #default>
                              <el-scrollbar :height="200">
                                <div
                                  v-for="(data, idx) in autocompleteGuildMembers"
                                  :key="idx"
                                >
                                  <strong>{{ data.letter }}</strong>
                                  <ul>
                                    <li
                                      v-for="(nameData, nameIdx) in data.names"
                                      :key="nameIdx"
                                      class="cursor-pointer hover:bg-neutral-100"
                                      @click="fillNicknameInMessage(nameData.name, nameData.tag, tabIndex)"
                                    >
                                      {{ nameData.name }}<span v-if="nameData.tag > 0">#{{ nameData.tag }}</span>
                                    </li>
                                  </ul>
                                </div>
                              </el-scrollbar>
                            </template>
                          </el-popover>
                          <div
                            v-if="ws?.isOpen"
                            class="relative flex"
                          >
                            <input
                              :ref="`chat_${tabIndex}_playerMessage`"
                              v-model="playerMessage[chatTabs]"
                              type="text"
                              :placeholder="`Send message on ${env}...`"
                              class="font-related-xl w-full rounded-md bg-gray-100 py-3 pl-12 pr-72 text-gray-600 placeholder-gray-600 focus:placeholder-gray-400 focus:outline-none"
                              @keypress="canShowMembersAutocomplete($event, tabIndex)"
                              @keypress.enter="
                                sendMessage(tabData.guild2_id, tabData.is_tournament, tabData.boss_event_id, tabData.is_cards)
                              "
                            >
                            <div class="absolute inset-y-0 flex w-12 items-center justify-center">
                              <el-popover
                                v-if="showEmojiPicker[tabIndex] !== undefined"
                                v-model:visible="showEmojiPicker[tabIndex]"
                                placement="top"
                                :width="280"
                                trigger="click"
                                popper-class="p-0"
                              >
                                <template #reference>
                                  <icon-ify
                                    icon="ic:outline-emoji-emotions"
                                    class="h-6 w-6 cursor-pointer text-neutral-500 hover:text-cyan-600 focus:outline-0 active:text-cyan-500"
                                  />
                                </template>
                                <EmojiPicker
                                  :native="true"
                                  class="m-0 rounded-none border-0"
                                  display-recent
                                  disable-skin-tones
                                  @select="showEmoji($event, tabIndex)"
                                />
                              </el-popover>
                            </div>
                            <div class="absolute inset-y-0 right-0 flex items-center justify-end">
                              <el-button
                                v-if="(playerIsGuildMaster || playerIsDeputy) && tabIndex === 0"
                                class="gs-btn-warning mr-1 px-4"
                                :disabled="!playerMessage[chatTabs]?.length"
                                @click="sendMessageToAll()"
                              >
                                <icon-ify
                                  icon="emojione-monotone:loudspeaker"
                                  class="h-6 w-6 text-white"
                                />
                              </el-button>
                              <el-button
                                class="gs-btn-primary ml-0 mr-2 px-2"
                                :disabled="!playerMessage[chatTabs]?.length"
                                @click="
                                  sendMessage(tabData.guild2_id, tabData.is_tournament, tabData.boss_event_id, tabData.is_cards)
                                "
                              >
                                Send
                                <icon-ify
                                  icon="carbon:send"
                                  class="ml-1.5 h-6 w-6 text-white"
                                />
                              </el-button>
                            </div>
                          </div>
                          <div
                            v-else
                            class="font-related-sm flex flex-col justify-between text-neutral-400 sm:flex-row"
                          >
                            <el-button
                              v-if="!ws?.isOpen"
                              class="gs-btn-outlined-primary"
                              @click="getChatPreview"
                            >
                              Refresh chat preview
                            </el-button>
                            <el-button
                              v-if="!ws?.isOpen"
                              class="gs-btn-outlined-primary"
                              :loading="loading.translate"
                              @click="translateAllMessages(tabData)"
                            >
                              Translate all
                            </el-button>
                            <div class="mt-4 sm:mt-0">
                              you can only preview chat (you are not connected)
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-auto-resizer>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Personal message"
            name="PersonalMessage"
          >
            <template #label>
              <router-link
                :to="
                  $utils.bindStaticParams({
                    name: 'personal-messages-details',
                    params: { id: 0 },
                    query: { playerCSV: guildMembersListPersonalMessage(form) }
                  })
                "
                target="_blank"
                class="mb-1 mt-1 text-blue-500 hover:text-blue-600"
              >
                Personal message
              </router-link>
            </template>
          </el-tab-pane>
        </el-tabs>
      </fields-col>
    </template>
    <template #drawers>
      <BattleScorePreview @bind-preview-battle-action="previewActions.previewBattle = $event" />
    </template>
  </crud-details-page>
</template>

<style lang="postcss" scoped>
.gs-chat-box {
  max-width: 80%;
  line-height: 1.6em;
}

.gs-chat-emoji {
  font-size: 1.6em;
  display: inline-block;
  transform: translateY(0.1em);
}
</style>
