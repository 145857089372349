<script setup></script>

<template>
  <crud-details-page
    api="rewards/quest-reward"
    disable-top-margin
  >
    <template #form>
      <!-- Label -->
      <crud-field-text
        :sm="8"
        api-field-name="Label"
        required
      />
      <fields-col
        v-for="i in 6"
        :key="i"
      >
        <section-divider>Milestone {{ i }}</section-divider>
        <crud-field-number
          :sm="5"
          :api-field-name="`Threshold${i}`"
          label="Threshold"
        />
        <fields-col :sm="5">
          <!-- XpPct -->
          <crud-field-number
            :api-field-name="`XpPct${i}`"
            label="XpPct"
          />
          <!-- Energy -->
          <crud-field-number
            :api-field-name="`Energy${i}`"
            label="Energy"
          />
          <!-- Gems -->
          <crud-field-number
            :api-field-name="`Gems${i}`"
            label="Gems"
          />
        </fields-col>
        <fields-col :sm="14">
          <crud-field-selector-item
            :sm="16"
            :api-field-name="`Item${i * 2 - 1}`"
            label="Item1"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Item${i * 2 - 1}Amount`"
            label="Item1 amount"
          />
          <crud-field-selector-item
            :sm="16"
            :api-field-name="`Item${i * 2}`"
            label="Item2"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Item${i * 2}Amount`"
            label="Item2 amount"
          />
        </fields-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
