<script>
import CrudFieldSelectorTrophy from '@/components/forms/crud-fields/CrudFieldSelectorTrophy.vue'

export default {
  components: { CrudFieldSelectorTrophy }
}
</script>

<template>
  <crud-details-page
    api="rewards/tournament-reward"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="5">
        <!-- SeasonNo -->
        <crud-field-number api-field-name="SeasonNo" />
        <!-- No -->
        <crud-field-number api-field-name="No" />
        <!-- TournamentMode -->
        <crud-field-select
          api-field-name="TournamentMode"
          options-enum="enums.TournamentMode"
        />
      </fields-col>
      <fields-col :sm="7">
        <!-- Energy -->
        <crud-field-number api-field-name="Energy" />
        <!-- Gems -->
        <crud-field-number api-field-name="Gems" />
        <!-- Trophy -->
        <CrudFieldSelectorTrophy api-field-name="Trophy" />
      </fields-col>
      <fields-col :sm="12">
        <template
          v-for="i in 4"
          :key="i"
        >
          <crud-field-selector-item
            :sm="16"
            :api-field-name="`Item${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Item${i}Amount`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
