<script>
export default {
  name: 'MenuItem',
  data() {
    return {
      loadingDev: false
    }
  },
  methods: {
    clearDev() {
      const email = this.$store.getters['auth/userData'].Email
      this.loadingDev = true
      this.$axios
        .get(`/proxy/clearcache/`, { params: { u: email, p: '6cq8!@X-bN7U5b+]Lx9OAK?^jLxKSIE!#6Z/>**K' } })
        .then(() => {
          this.loadingDev = false
          this.$message({
            message: 'DEV cache cleared',
            type: 'success',
            offset: 40
          })
        })
        .catch(() => {
          this.loadingDev = false
          this.$message({
            message: 'DEV cache failed',
            type: 'warning',
            offset: 40
          })
        })
    }
  }
}
</script>

<template>
  <li class="cursor-text text-center">
    <el-button
      type="warning"
      :loading="loadingDev"
      class="gs-size-scaled"
      size="small"
      @click="clearDev"
    >
      <span v-if="$store.getters['main/pageMenuCollapse']">CCD</span>
      <span v-else>CC DEV</span>
    </el-button>
  </li>
</template>

<style scoped></style>
