<script>
import EmojiPicker from 'vue3-emoji-picker'
import emojiRegex from 'emoji-regex'
import CrudFieldFile from '!/components/forms/crud-fields/CrudFieldFile.vue'
import { userRightRoles } from '!/composition/utilities'

export default {
  components: { EmojiPicker, CrudFieldFile },
  data() {
    return {
      tabs: 'inventory',
      userRightRoles,
      externalData: {},
      envApi: '',
      endPoint: '',
      loading: {},
      ws: null,
      player: {},
      chat: {
        isDeveloper: false,
        messages: {},
        downloadedMessages: {},
        translatedMessages: {},
        tabs: [],
        initTabs: {},
        height: null,
        lazyTasks: {},
        loading: {}
      },
      chatTabs: 'chat_0',
      pinfo: {},
      visibleMembersAutocomplete: [],
      visibleMembersAutocompleteOffset: [],
      showEmojiPicker: [],
      playerMessage: {}
    }
  },
  computed: {
    playerIsGuildMaster() {
      if (this.player.guild === 0 || this.player.id === 0) {
        return false
      }
      if (!this.guilds[this.player.guild]) {
        return false
      }
      return this.guilds[this.player.guild]?.members[this.player.id].gm === 1
    },
    playerIsDeputy() {
      if (this.player.guild === 0 || this.player.id === 0) {
        return false
      }
      if (!this.guilds[this.player.guild]) {
        return false
      }
      return this.guilds[this.player.guild]?.members[this.player.id].deputy === 1
    },
    playerId() {
      return (this.$route.params?.id ?? '0') * 1
    },
    notEmptyChatsTabs() {
      const emptyChatsTabs = this.chat.tabs.filter((tab) => {
        return !!this.chat.messages?.[`chat_${tab.id}`]?.length
      })
      if (!emptyChatsTabs.length) {
        return [
          {
            name: '- No chats -',
            id: 0
          }
        ]
      }
      return emptyChatsTabs
    }
  },
  created() {
    const unwatchChatTab = this.$watch('tabs', () => {
      if (this.tabs === 'chat') {
        if (this.chat.height === null) {
          const clientRect = this.$refs?.chatWrapper?.getBoundingClientRect?.()
          this.chat.height = clientRect?.top ? Math.max(this.$windowHeight, 1000) - clientRect.top - 200 : 700
        }
        this.getChatPreview()
        this.$watch('chatTabs', () => {
          if (!this.chat.initTabs[this.chatTabs]) {
            this.scrollChat()
            this.chat.initTabs[this.chatTabs] = true
          }
        })
        unwatchChatTab()
      }
    })
  },
  mounted() {},
  unmounted() {
    this.resetChatData()
    this.ws?.destroy?.()
  },
  methods: {
    loadData() {
      const envApi = this.$store.getters['auth/envs']?.[this.$route.query?.env]
      if (!(this.$utils.isDevMode() && this.$route.query?.env !== 'local')) {
        this.envApi = envApi
      }
      this.endPoint = `/proxy/gameplay/player/${this.playerId}/`
      this.$axios
        .get(envApi + this.endPoint)
        .then(({ data }) => {
          data.setChatShadowBanDays = 30
          data.setBanDays = 7
          data.setNameBanDays = 7
          data.setDescriptionBanDays = 7
          data.setAvatarBanDays = 7
          data.shadowban_message_send = false
          data.shadowban_message_tag = ''
          data.swapTokenPlayerId = ''
          data.swapFacebookPlayerId = ''
          data.swapGooglePlayerId = ''
          data.swapApplePlayerId = ''
          data.setSeasonPass = false
          data.seasonPassExpire =
            data.item.SeasonPassExpire === '1000-01-01T00:00:00Z'
              ? null
              : this.$utils.formatUTCDateTime(data.item.SeasonPassExpire)
          data.resetSeasonPass = false
          this.externalData = data
        })
        .catch(this.$utils.catchError)
    },
    setTitle(apiLabel, data) {
      return `${data?.item?.Name} (ID:${data?.item?.ID}) on ${data?.env}`
    },
    async savePlayer(formRefer) {
      if (!this.envApi)
        return false
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to save player ?', undefined, {
          confirmButtonText: 'Yes, save player',
          type: 'warning'
        })
        .then(async () => {
          this.loading.savePlayer = true
          try {
            await formRefer.validateField('item.Name')
            await formRefer.validateField('item.ForcedCountryCode')
          } catch (_e) {
            this.$message({
              message: 'Not all fields are valid.',
              type: 'warning',
              offset: 40
            })
            this.loading.savePlayer = false
            return false
          }
          this.loading.savePlayer = true
          const params = {
            newPlayerName: this.externalData.item.Name,
            newPlayerDescription: this.externalData.item.Info,
            newForcedCountryCode: this.externalData.item.ForcedCountryCode,
            newIsBot: this.externalData.item.IsBot,
            newIsSuspicious: this.externalData.item.IsSuspicious,
            newIsModerator: this.externalData.item.IsModerator,
            newIsToDel: this.externalData.item.IsToDel,
            newLang: this.externalData.item.Lang,
            newBlockLang: this.externalData.item.BlockLang,
            newSetSeasonPass: this.externalData.setSeasonPass,
            newResetSeasonPass: this.externalData.resetSeasonPass,
            newEnergy: this.externalData.item.Energy,
            newGems: this.externalData.item.Gems,
            newGcoins: this.externalData.item.StoreGCoins,
            newNotes: this.externalData.item.Notes
          }
          this.$axios
            .get(`${this.envApi}/proxy/gameplay/saveplayer/${this.externalData.item?.ID}/`, { params })
            .then(({ data }) => {
              this.externalData.player_shadowban_expired = data.player_shadowban_expired
              this.externalData.player_shadowban_expire = data.player_shadowban_expire
              this.externalData.seasonPassExpire = data.season_pass_expire
              this.notify()
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.savePlayer = false
            })
        })
    },
    removeAvatar() {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to remove Avatar ?', undefined, {
          confirmButtonText: 'Yes, remove',
          type: 'warning'
        })
        .then(() => {
          this.loading.removeAvatar = true
          const formData = new FormData()
          formData.append('player_id', this.externalData.item?.ID)
          this.$axios
            .post(`${this.envApi}/api/deleteavatar/`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(() => {
              this.notify('Removed')
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.removeAvatar = false
            })
        })
    },
    async resetGuildJoinBan() {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to reset guild join ban?', undefined, {
          confirmButtonText: 'Yes, reset guild join ban',
          type: 'warning'
        })
        .then(async () => {
          this.loading.resetGuildJoinBan = true

          const params = {
            resetGuildJoinBan: true
          }
          this.$axios
            .get(`${this.envApi}/proxy/gameplay/saveplayer/${this.externalData.item.ID}/`, { params })
            .then(({ data }) => {
              this.externalData.player_guild_join_ban = data.player_guild_join_ban
              this.externalData.player_guild_join_ban_till = data.player_guild_join_ban_till
              this.notify()
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.resetGuildJoinBan = false
            })
        })
    },
    async sendWelcomeMessage() {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to send welcome message?', undefined, {
          confirmButtonText: 'Yes, send welcome message',
          type: 'warning'
        })
        .then(async () => {
          this.loading.sendWelcomeMessage = true

          const params = {
            sendWelcomeMessage: this.externalData.item.WelcomeMessageSent
          }
          this.$axios
            .get(`${this.envApi}/proxy/gameplay/saveplayer/${this.externalData.item.ID}/`, { params })
            .then(({ data }) => {
              this.externalData.player_welcome_message_sent = data.player_welcome_message_sent
              this.externalData.player_welcome_message_sent_date = data.player_welcome_message_sent_date
              this.notify()
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.sendWelcomeMessage = false
            })
        })
    },
    async setShadowBan(formRefer, banAllDevices) {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to set shadow ban ?', undefined, {
          confirmButtonText: 'Yes, set shadow ban',
          type: 'warning'
        })
        .then(async () => {
          this.loading.setShadowBan = true
          try {
            if (this.externalData.shadowban_message_send) {
              await formRefer.validateField('shadowban_message_content')
            }
          } catch (_e) {
            this.$message({
              message: 'Not all fields are valid.',
              type: 'warning',
              offset: 40
            })
            this.loading.setShadowBan = false
            return false
          }

          const params = {
            setChatShadowBan: this.externalData.setChatShadowBanDays,
            setShadowbanOnGuildChat: this.externalData.item.IsGuildChatShadowBanned,
            sendShadowbanMessage: this.externalData.shadowban_message_send,
            sendShadowbanMessageContent: this.externalData.shadowban_message_content,
            banAllDeviceIds: banAllDevices
          }
          this.$axios
            .get(`${this.envApi}/proxy/gameplay/saveplayer/${this.externalData.item?.ID}/`, { params })
            .then(({ data }) => {
              this.externalData.player_shadowban_expired = data.player_shadowban_expired
              this.externalData.player_shadowban_expire = data.player_shadowban_expire
              this.notify()
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.setShadowBan = false
            })
        })
    },
    setBan(banAllDevices) {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to set ban ?', undefined, {
          confirmButtonText: 'Yes, set ban',
          type: 'warning'
        })
        .then(() => {
          this.loading.setBan = true
          const params = {
            setBan: this.externalData.setBanDays,
            setBanMessage: this.externalData.item.BanMessage,
            banAllDeviceIds: banAllDevices
          }

          this.$axios
            .get(`${this.envApi}/proxy/gameplay/saveplayer/${this.externalData.item?.ID}/`, { params })
            .then(({ data }) => {
              this.externalData.player_shadowban_expired = data.player_shadowban_expired
              this.externalData.player_shadowban_expire = data.player_shadowban_expire
              this.externalData.player_ban_expired = data.player_ban_expired
              this.externalData.player_ban_expire = data.player_ban_expire
              this.notify()
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.setBan = false
            })
        })
    },
    setNameBan(banAllDevices) {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to set name ban ?', undefined, {
          confirmButtonText: 'Yes, set ban',
          type: 'warning'
        })
        .then(() => {
          this.loading.setNameBan = true
          const params = {
            setNameBan: this.externalData.setNameBanDays,
            banAllDeviceIds: banAllDevices
          }

          this.$axios
            .get(`${this.envApi}/proxy/gameplay/saveplayer/${this.externalData.item.ID}/`, { params })
            .then(({ data }) => {
              this.externalData.player_name_ban_expired = data.player_name_ban_expired
              this.externalData.player_name_ban_expire = data.player_name_ban_expire
              this.notify()
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.setNameBan = false
            })
        })
    },
    setDescriptionBan(banAllDevices) {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to set description ban ?', undefined, {
          confirmButtonText: 'Yes, set ban',
          type: 'warning'
        })
        .then(() => {
          this.loading.setDescriptionBan = true
          const params = {
            setDescriptionBan: this.externalData.setDescriptionBanDays,
            setDescriptionBanPlayer: this.externalData.item.DescriptionBanPlayer,
            setDescriptionBanGuild: this.externalData.item.DescriptionBanGuild,
            setDescriptionBanInvitationMessage: this.externalData.item.DescriptionBanInvitationMessage,
            banAllDeviceIds: banAllDevices
          }

          this.$axios
            .get(`${this.envApi}/proxy/gameplay/saveplayer/${this.externalData.item.ID}/`, { params })
            .then(({ data }) => {
              this.externalData.player_description_ban_expired = data.player_description_ban_expired
              this.externalData.player_description_ban_expire = data.player_description_ban_expire
              this.notify()
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.setDescriptionBan = false
            })
        })
    },
    setAvatarBan(banAllDevices) {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to set avatar ban ?', undefined, {
          confirmButtonText: 'Yes, set ban',
          type: 'warning'
        })
        .then(() => {
          this.loading.setAvatarBan = true
          const params = {
            setAvatarBan: this.externalData.setAvatarBanDays,
            banAllDeviceIds: banAllDevices
          }

          this.$axios
            .get(`${this.envApi}/proxy/gameplay/saveplayer/${this.externalData.item.ID}/`, { params })
            .then(({ data }) => {
              this.externalData.player_avatar_ban_expired = data.player_avatar_ban_expired
              this.externalData.player_avatar_ban_expire = data.player_avatar_ban_expire
              this.notify()
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.setAvatarBan = false
            })
        })
    },
    async swapTokens() {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to swap tokens ?', undefined, {
          confirmButtonText: 'Yes, swap tokens',
          type: 'warning'
        })
        .then(async () => {
          this.loading.swapTokens = true
          let swapPlayerData = await this.$axios.get(
            `${this.envApi}/proxy/gameplay/player/${this.externalData.swapTokenPlayerId}/`
          )
          swapPlayerData = swapPlayerData?.data || {}
          const isPlayer = swapPlayerData?.item?.ID > 0
          let msg = 'No player with such ID!'
          if (isPlayer && swapPlayerData.item?.ID !== this.externalData.item?.ID) {
            msg =
              `Are you sure you want to swap ${
                this.externalData.item.Name
              } with this player?` +
              `\n\nPlayer ID: ${
                swapPlayerData.item?.ID
              }\nPlayer Name: ${
                swapPlayerData.item.Name
              }\nPlayer Creation Date: ${
                swapPlayerData.item.Created
              }\nPlayer Last Active Date: ${
                swapPlayerData.item.LastActive
              }\nPlayer Level: ${
                swapPlayerData.item.Level
              }\nPlayer Zone: ${
                swapPlayerData.item.Zone}`
          } else if (isPlayer && swapPlayerData.item?.ID === this.externalData.item?.ID) {
            msg = 'You cannot swap the player\'s token with itself!'
          }

          const confirm = await this.$messageBox
            .confirm(msg.split('\n').join('<br>'), 'Swapping tokens', {
              confirmButtonText: 'OK',
              dangerouslyUseHTMLString: true,
              type: 'warning'
            })
            .catch(() => {
              this.loading.swapTokens = false
            })

          if (!confirm || !isPlayer || swapPlayerData?.item?.ID === this.externalData.item?.ID) {
            this.loading.swapTokens = false
            return
          }
          const params = {
            tokenSwapPlayerID: this.externalData.swapTokenPlayerId
          }
          this.$axios
            .get(`${this.envApi}/proxy/gameplay/swaptokens/${this.externalData.item?.ID}/`, { params })
            .then(() => {
              this.notify('Tokens swapped')
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.swapTokens = false
            })
        })
    },
    async swapFacebook() {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to swap facebook ids ?', undefined, {
          confirmButtonText: 'Yes, swap facebook ids',
          type: 'warning'
        })
        .then(async () => {
          this.loading.swapFacebook = true
          let swapPlayerData = await this.$axios.get(
            `${this.envApi}/proxy/gameplay/player/${this.externalData.swapFacebookPlayerId}/`
          )
          swapPlayerData = swapPlayerData?.data || {}
          const isPlayer = swapPlayerData?.item?.ID > 0
          let msg = 'No player with such ID!'
          if (isPlayer && swapPlayerData.item.ID !== this.externalData.item.ID) {
            msg =
              `Are you sure you want to swap ${
                this.externalData.item.Name
              } with this player?` +
              `\n\nPlayer ID: ${
                swapPlayerData.item.ID
              }\nPlayer Name: ${
                swapPlayerData.item.Name
              }\nPlayer Creation Date: ${
                swapPlayerData.item.Created
              }\nPlayer Last Active Date: ${
                swapPlayerData.item.LastActive
              }\nPlayer Level: ${
                swapPlayerData.item.Level
              }\nPlayer Zone: ${
                swapPlayerData.item.Zone
              }\nPlayer Facebook Id: ${
                swapPlayerData.item.FacebookId}`
          } else if (isPlayer && swapPlayerData.item.ID === this.externalData.item.ID) {
            msg = 'You cannot swap the player\'s facebook id with itself!'
          }

          const confirm = await this.$messageBox
            .confirm(msg.split('\n').join('<br>'), 'Swapping facebook ids', {
              confirmButtonText: 'OK',
              dangerouslyUseHTMLString: true,
              type: 'warning'
            })
            .catch(() => {
              this.loading.swapFacebook = false
            })

          if (!confirm || !isPlayer || swapPlayerData?.item?.ID === this.externalData.item.ID) {
            this.loading.swapFacebook = false
            return
          }
          const params = {
            swapFacebookPlayerID: this.externalData.swapFacebookPlayerId
          }
          this.$axios
            .get(`${this.envApi}/proxy/gameplay/swapsocial/${this.externalData.item.ID}/`, { params })
            .then(() => {
              this.notify('Facebook Ids swapped')
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.swapFacebook = false
            })
        })
    },
    async swapGoogle() {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to swap google ids ?', undefined, {
          confirmButtonText: 'Yes, swap google ids',
          type: 'warning'
        })
        .then(async () => {
          this.loading.swapGoogle = true
          let swapPlayerData = await this.$axios.get(
            `${this.envApi}/proxy/gameplay/player/${this.externalData.swapGooglePlayerId}/`
          )
          swapPlayerData = swapPlayerData?.data || {}
          const isPlayer = swapPlayerData?.item?.ID > 0
          let msg = 'No player with such ID!'
          if (isPlayer && swapPlayerData.item.ID !== this.externalData.item.ID) {
            msg =
              `Are you sure you want to swap ${
                this.externalData.item.Name
              } with this player?` +
              `\n\nPlayer ID: ${
                swapPlayerData.item.ID
              }\nPlayer Name: ${
                swapPlayerData.item.Name
              }\nPlayer Creation Date: ${
                swapPlayerData.item.Created
              }\nPlayer Last Active Date: ${
                swapPlayerData.item.LastActive
              }\nPlayer Level: ${
                swapPlayerData.item.Level
              }\nPlayer Zone: ${
                swapPlayerData.item.Zone
              }\nPlayer Google Id: ${
                swapPlayerData.item.GoogleId}`
          } else if (isPlayer && swapPlayerData.item.ID === this.externalData.item.ID) {
            msg = 'You cannot swap the player\'s google id with itself!'
          }

          const confirm = await this.$messageBox
            .confirm(msg.split('\n').join('<br>'), 'Swapping google ids', {
              confirmButtonText: 'OK',
              dangerouslyUseHTMLString: true,
              type: 'warning'
            })
            .catch(() => {
              this.loading.swapGoogle = false
            })

          if (!confirm || !isPlayer || swapPlayerData?.item?.ID === this.externalData.item.ID) {
            this.loading.swapGoogle = false
            return
          }
          const params = {
            swapGooglePlayerID: this.externalData.swapGooglePlayerId
          }
          this.$axios
            .get(`${this.envApi}/proxy/gameplay/swapsocial/${this.externalData.item.ID}/`, { params })
            .then(() => {
              this.notify('Google Ids swapped')
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.swapGoogle = false
            })
        })
    },
    async swapApple() {
      if (!this.envApi)
        return false
      this.$messageBox
        .confirm('Are you sure to swap apple ids ?', undefined, {
          confirmButtonText: 'Yes, swap apple ids',
          type: 'warning'
        })
        .then(async () => {
          this.loading.swapApple = true
          let swapPlayerData = await this.$axios.get(
            `${this.envApi}/proxy/gameplay/player/${this.externalData.swapApplePlayerId}/`
          )
          swapPlayerData = swapPlayerData?.data || {}
          const isPlayer = swapPlayerData?.item?.ID > 0
          let msg = 'No player with such ID!'
          if (isPlayer && swapPlayerData.item.ID !== this.externalData.item.ID) {
            msg =
              `Are you sure you want to swap ${
                this.externalData.item.Name
              } with this player?` +
              `\n\nPlayer ID: ${
                swapPlayerData.item.ID
              }\nPlayer Name: ${
                swapPlayerData.item.Name
              }\nPlayer Creation Date: ${
                swapPlayerData.item.Created
              }\nPlayer Last Active Date: ${
                swapPlayerData.item.LastActive
              }\nPlayer Level: ${
                swapPlayerData.item.Level
              }\nPlayer Zone: ${
                swapPlayerData.item.Zone
              }\nPlayer Apple Id: ${
                swapPlayerData.item.AppleId}`
          } else if (isPlayer && swapPlayerData.item.ID === this.externalData.item.ID) {
            msg = 'You cannot swap the player\'s apple id with itself!'
          }

          const confirm = await this.$messageBox
            .confirm(msg.split('\n').join('<br>'), 'Swapping apple ids', {
              confirmButtonText: 'OK',
              dangerouslyUseHTMLString: true,
              type: 'warning'
            })
            .catch(() => {
              this.loading.swapApple = false
            })

          if (!confirm || !isPlayer || swapPlayerData?.item?.ID === this.externalData.item.ID) {
            this.loading.swapApple = false
            return
          }
          const params = {
            swapApplePlayerID: this.externalData.swapApplePlayerId
          }
          this.$axios
            .get(`${this.envApi}/proxy/gameplay/swapsocial/${this.externalData.item.ID}/`, { params })
            .then(() => {
              this.notify('Apple Ids swapped')
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.swapApple = false
            })
        })
    },
    async deleteSocial(delFacebook, delGoogle, delApple) {
      if (!this.envApi)
        return false
      let typ = ''
      if (delFacebook) {
        typ = 'Facebook'
      } else if (delGoogle) {
        typ = 'Google'
      } else if (delApple) {
        typ = 'Apple'
      }

      this.$messageBox
        .confirm(`Are you sure to delete ${typ} ID ?`, undefined, {
          confirmButtonText: `Yes, delete ${typ} ID`,
          type: 'warning'
        })
        .then(async () => {
          this.loading.delete = true
          const params = {
            facebook_id: delFacebook ? 'NULL' : '',
            google_id: delGoogle ? 'NULL' : '',
            apple_id: delApple ? 'NULL' : ''
          }
          this.$axios
            .get(`${this.envApi}/proxy/gameplay/deletesocial/${this.externalData.item.ID}/`, { params })
            .then(() => {
              this.notify(`${typ} Id deleted`)
            })
            .catch(this.$utils.catchFormErrors(this.externalErrors))
            .then(() => {
              this.loading.delete = false
            })
        })
    },
    notify(message = 'saved') {
      this.$message({
        message,
        type: 'success',
        offset: 40
      })
    },
    shadowBanMessageTagChanged(tag) {
      if (!tag?.ID) {
        return false
      }
      const lang = this.$utils.capitalizeFirstLetter(this.externalData.item.Lang.toLowerCase())
      this.$axios
        .get(`/admin/api/settings/client-string/${tag?.ID}/`)
        .then(({ data }) => {
          const content = data?.item?.[`Contents${lang}`] || data?.item?.Contents || ''
          if (content.length) {
            this.externalData.shadowban_message_content = content
          }
        })
        .catch(this.$utils.catchError)
    },
    renderEmoji(text) {
      const regex = emojiRegex()
      if (regex.test(text)) {
        const rendered = text
          .replaceAll(regex, (matched) => {
            return `[e-m_o-j_I]${matched}[e-m_o-j_I]`
          })
          .split('[e-m_o-j_I]')
          .map((el) => {
            return { val: el, html: regex.test(el) }
          })

        return rendered
      }
      return null
    },
    sendTask(task = 'logged/player/void', additionalData = {}) {
      const data = { ...additionalData }
      data.version = ''
      data.platform = 'Ios'
      data.country_code = ''
      data.access_token = 'b581165696952d71b47d205883de25db'
      data.token = this.chat.token
      data.task = task
      return this.ws.send(data)
    },
    connect() {
      this.ws.openSocket()
    },
    disconect() {
      this.$message({
        message: 'You have been disconnected.',
        type: 'warning',
        offset: 40
      })
      this.ws?.destroy?.()
    },
    lazyLoad(ev, tabIndex, tabData) {
      if (ev.scrollTop === 0) {
        const scrollerRef = this.$refs?.[`chat_${tabData.id}_scroll`]?.[0]
        const scrollHeight = scrollerRef?.wrapRef?.scrollHeight
        this.chat.loading[`chat_${tabData.id}_loading`] = true
        // let idTask = 0;
        if (!this.ws?.isOpen) {
          // idTask = ++this.idTaskRestApi;
          const envApi = this.$store.getters['auth/envs']?.[this.$route.query?.env]
          const endpoint = `${envApi}/proxy/chatpreview/player/lazyload/`
          const email = this.$store.getters['auth/userData'].Email

          this.$axios
            .get(endpoint, {
              params: {
                u: email,
                p: '6cq8!@X-bN7U5b+]Lx9OAK?^jLxKSIE!#6Z/>**K',
                player_id: this.playerId,
                player2_id: tabData.id,
                from_line_id: this.chat.messages?.[`chat_${tabData.id}`]?.[0]?.key
                  ? this.chat.messages[`chat_${tabData.id}`][0].key.slice(1)
                  : undefined
              }
            })
            .then((response) => {
              this.tryUpdateChatMessages(response.data, scrollHeight)
            })
            .catch(this.$utils.catchError)
            .then(() => {
              this.chat.loading[`chat_${tabData.id}_loading`] = false
            })
        }
        // else {
        //   idTask = this.sendTask('logged/guild/getchat', {
        //     guild2Id: tabData.guild2_id,
        //     boss_event_id: tabData.boss_event_id,
        //     is_tournament: tabData.is_tournament,
        //     is_cards: tabData.is_cards,
        //     offset: offset,
        //   });
        //   this.idTaskRestApi++;
        // }

        // this.chat.lazyTasks[`id_${idTask}`] = { scrollHeight };
      }
    },
    canShowMembersAutocomplete(event, tab) {
      this.visibleMembersAutocompleteOffset[tab] = (event.target.selectionStart || 0) + 8
      if (event.key !== '@')
        return
      this.$nextTick(() => {
        this.visibleMembersAutocomplete[tab] = true
      })
    },
    sendMessage(guild2Id, isTournament, bossEventId, isCards) {
      if (!this.playerMessage[this.chatTabs]?.length) {
        return
      }
      let playerMessage = this.playerMessage[this.chatTabs]
      const scrollerRef = this.$refs[`${this.chatTabs}_scroll`][0]
      scrollerRef.setScrollTop(scrollerRef?.wrapRef?.scrollHeight)

      const mentionTags = []
      const mentionsRegex = /@(\w+)#(\d+)/g
      const mentionMatches = [...playerMessage.matchAll(mentionsRegex)]
      for (const i in mentionMatches) {
        const d = mentionMatches[i]
        mentionTags.push(d[2])
        playerMessage = playerMessage.replace(d[0], `@${d[1]}`)
      }

      this.sendTask('logged/guild/addchat', {
        msg: playerMessage || '',
        guild2_id: guild2Id,
        boss_event_id: bossEventId,
        is_tournament: isTournament,
        is_cards: isCards,
        mention_tags: mentionTags.join(',')
      })
      this.playerMessage[this.chatTabs] = ''
    },
    showEmoji(emoji, tabIndex) {
      const input = this.$refs[`${this.chatTabs}_playerMessage`][0]
      if (input) {
        input.setRangeText(emoji.i, input.selectionStart, input.selectionEnd)
        this.playerMessage[this.chatTabs] = input.value
      } else {
        this.playerMessage[this.chatTabs] =
          this.playerMessage[this.chatTabs] === undefined ? emoji.i : this.playerMessage[this.chatTabs] + emoji.i
      }
      this.showEmojiPicker[tabIndex] = false
    },
    sendMessageToAll() {
      const msg = this.playerMessage.chat_0
      if (msg.includes('@')) {
        this.$message.error('You cannot mention players in broadcast message')
        return
      }

      this.$messageBox
        .confirm('Are you sure?', 'Send message to all')
        .then(() => {
          const allMsg = `/all ${msg}`
          this.sendTask('logged/guild/addchat', {
            msg: allMsg
          })
          this.playerMessage.chat_0 = ''
        })
        .catch(() => {})
    },
    tryUpdateChatMessages(data, prevScrollHeight) {
      if (data.chat) {
        const newMessages = {}
        for (const keyChatMessage in data.chat) {
          const msg = data.chat[keyChatMessage]
          let keyChatTabId = this.pinfo?.[msg.p]?.id
          if (keyChatTabId === this.playerId) {
            keyChatTabId = this.pinfo?.[msg.p2]?.id
          }
          if (keyChatTabId) {
            const keyChat = `chat_${keyChatTabId}`

            if (!this.chat.downloadedMessages[keyChat]) {
              this.chat.downloadedMessages[keyChat] = {}
            }
            if (this.chat.downloadedMessages[keyChat]?.[keyChatMessage]) {
              continue
            }
            this.chat.downloadedMessages[keyChat][keyChatMessage] = true

            const msgUnix = Math.round(keyChatMessage.substring(1, keyChatMessage.length) * 0.000001)
            msg.unix = msgUnix
            msg.date = new Date(msgUnix).toLocaleString()
            msg.msgRendered = this.renderEmoji(msg.msg)
            msg.key = keyChatMessage

            if (!this.chat.messages[keyChat]) {
              this.chat.messages[keyChat] = []
            }
            if (!newMessages[keyChat]) {
              newMessages[keyChat] = []
            }
            newMessages[keyChat].push(msg)
          } else {
            this.$notify({
              title: 'Error',
              type: 'warning',
              customClass: 'child-inherit-colors bg-teal-50 text-red-600 z-[999999]',
              duration: 9000,
              message: `Missing user id: ${this.pinfo?.[msg.p]?.id ? msg.p2 : msg.p} in pinfo`
            })
          }
        }
        for (const keyChat in newMessages) {
          const allMessages = [...this.chat.messages[keyChat], ...newMessages[keyChat]]
          allMessages.sort((a, b) => {
            return a.unix - b.unix
          })
          this.chat.messages[keyChat] = allMessages
        }
        this.scrollChat(prevScrollHeight)
      }
    },
    scrollChat(prevScrollHeight) {
      this.$utils.nextLoopEvent(200).then(() => {
        const scrollerRef = this.$refs?.[`${this.chatTabs}_scroll`]?.[0]
        const scrollHeight = scrollerRef?.wrapRef?.scrollHeight
        if (scrollerRef) {
          scrollerRef.setScrollTop(prevScrollHeight ? scrollHeight - prevScrollHeight : scrollerRef.wrapRef.scrollHeight)
        }
      })
    },
    tryUpdatePinfo(data) {
      this.pinfo = data.pinfo ?? {}
    },
    updateChatTabs() {
      let tabs = [
        {
          name: 'User chat',
          id: 0,
          lastMsgUnix: 0
        }
      ]

      const pinfo = Object.values(this.pinfo)
      if (pinfo.length) {
        tabs = []
      }
      for (const playerInfo of pinfo) {
        const chatPlayer = this.chat?.messages?.[`chat_${playerInfo.id}`]
        tabs.push({
          name: playerInfo.name,
          id: playerInfo.id,
          lastMsgUnix: chatPlayer?.[chatPlayer?.length - 1]?.unix ?? 0
        })
      }
      tabs.sort((a, b) => {
        return b.lastMsgUnix - a.lastMsgUnix
      })
      this.chat.tabs = tabs
    },
    getChatPreview() {
      const envApi = this.$store.getters['auth/envs']?.[this.$route.query?.env]
      const endpoint = `${envApi}/proxy/chatpreview/player/getlines/`
      const email = this.$store.getters['auth/userData'].Email

      this.chat.loading = {}
      this.chat.loading[`${this.chatTabs}_loading`] = true

      this.$axios
        .get(endpoint, {
          params: { u: email, p: '6cq8!@X-bN7U5b+]Lx9OAK?^jLxKSIE!#6Z/>**K', player_id: this.playerId }
        })
        .then((response) => {
          this.tryUpdatePinfo(response.data)
          this.tryUpdateChatMessages(response.data)
          this.updateChatTabs()
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.chat.loading[`${this.chatTabs}_loading`] = false
          if (this.chatTabs === 'chat_0') {
            this.chatTabs = `chat_${this.notEmptyChatsTabs?.[0]?.id ?? 0}`
          }
        })
    },
    tryUpdateTranslatedMessages(resp) {
      if (resp.translated_chat) {
        for (const i in resp.translated_chat) {
          this.chat.translatedMessages[i] = resp.translated_chat[i]
        }
      }
    },
    translateMessage(msgId) {
      if (!this.ws?.isOpen) {
        const envApi = this.$store.getters['auth/envs']?.[this.$route.query?.env]
        const endpoint = `${envApi}/proxy/chatpreview/translatechat/`
        const email = this.$store.getters['auth/userData'].Email

        this.$axios
          .get(endpoint, {
            params: {
              u: email,
              p: '6cq8!@X-bN7U5b+]Lx9OAK?^jLxKSIE!#6Z/>**K',
              msg_id: msgId.slice(1)
            }
          })
          .then((response) => {
            this.tryUpdateTranslatedMessages(response.data)
          })
      }
      //
      // this.sendTask('logged/guild/translatechat', {
      //   msg_id: msgId.substr(1, msgId.length),
      //   guild2_id: tabData.guild2_id,
      //   boss_event_id: tabData.boss_event_id,
      //   is_tournament: tabData.is_tournament,
      //   is_cards: tabData.is_cards,
      // });
    },
    resetChatData() {
      this.player = {}
      this.pinfo = {}
      this.guilds = {}
      this.chat = {
        isDeveloper: false,
        messages: {},
        downloadedMessages: {},
        translatedMessages: {},
        tabs: [],
        initTabs: {},
        height: null,
        lazyTasks: {},
        loading: {}
      }
      this.chat.messages = {}
    }
  }
}
</script>

<template>
  <crud-details-page
    :versioned="false"
    :actions="{
      crudSave: false,
      crudDuplicate: false,
      crudNewVersion: false,
      crudUsage: false,
      crudDelete: false
    }"
    disable-top-margin
    :external-data="externalData"
    proxy
    :render-title="setTitle"
    @is-access="loadData"
  >
    <template #form="{ form, formRefer }">
      <el-row class="gs-font-scaled w-full">
        <el-image
          v-if="form.avatar"
          :src="form.avatar"
          fit="contain"
          class="h-20 w-20 hover:scale-105"
          :preview-src-list="[form.avatar]"
          preview-teleported
          hide-on-click-modal
        />
        <el-tabs
          v-model="tabs"
          class="w-full"
        >
          <el-tab-pane
            label="Inventory"
            name="inventory"
          >
            <el-col>
              <div
                v-if="
                  form?.inventory_in_use?.[1]?.preview_url
                    || form?.inventory_in_use?.[2]?.preview_url
                    || form?.inventory_in_use?.[3]?.preview_url
                    || form?.inventory_in_use?.[4]?.preview_url
                    || form?.inventory_in_use?.[5]?.preview_url
                    || form?.horse_in_use?.ID
                "
                class="font-related-xxl font-bold"
              >
                Inventory in use
              </div>
              <table class="mt-2.5">
                <tbody>
                  <tr>
                    <td>
                      <el-image
                        v-if="form?.inventory_in_use?.[2]?.preview_url"
                        :src="form.inventory_in_use[2].preview_url"
                        fit="contain"
                        class="h-14 w-14 hover:scale-105"
                        :preview-src-list="[form.inventory_in_use[2].preview_url]"
                        preview-teleported
                        hide-on-click-modal
                      />
                    </td>
                    <td>
                      <el-image
                        v-if="form?.inventory_in_use?.[1]?.preview_url"
                        :src="form.inventory_in_use[1].preview_url"
                        fit="contain"
                        class="h-14 w-14 hover:scale-105"
                        :preview-src-list="[form.inventory_in_use[1].preview_url]"
                        preview-teleported
                        hide-on-click-modal
                      />
                    </td>
                    <td>
                      <el-image
                        v-if="form?.inventory_in_use?.[3]?.preview_url"
                        :src="form.inventory_in_use[3].preview_url"
                        fit="contain"
                        class="h-14 w-14 hover:scale-105"
                        :preview-src-list="[form.inventory_in_use[3].preview_url]"
                        preview-teleported
                        hide-on-click-modal
                      />
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td>
                      <el-image
                        v-if="form?.inventory_in_use?.[4]?.preview_url"
                        :src="form.inventory_in_use[4].preview_url"
                        fit="contain"
                        class="h-14 w-14 hover:scale-105"
                        :preview-src-list="[form.inventory_in_use[4].preview_url]"
                        preview-teleported
                        hide-on-click-modal
                      />
                    </td>
                    <td>
                      <el-image
                        v-if="form?.inventory_in_use?.[5]?.preview_url"
                        :src="form.inventory_in_use[5].preview_url"
                        fit="contain"
                        class="h-14 w-14 hover:scale-105"
                        :preview-src-list="[form.inventory_in_use[5].preview_url]"
                        preview-teleported
                        hide-on-click-modal
                      />
                    </td>
                  </tr>
                  <tr v-if="form?.horse_in_use?.ID > 0">
                    <td />
                    <td>
                      <el-image
                        :src="form.horse_in_use.preview_url"
                        fit="contain"
                        class="h-14 w-14 hover:scale-105"
                        :preview-src-list="[form.horse_in_use.preview_url]"
                        preview-teleported
                        hide-on-click-modal
                      />
                    </td>
                    <td>ExpireAt: {{ form?.horse_in_use.expire_at }}</td>
                  </tr>
                </tbody>
              </table>
              <div
                v-if="form.inventory_countable.length === 0 && form.inventory_skins.length === 0"
                class="font-related-xxl my-3.5 font-bold"
              >
                Empty inventory
              </div>
              <div
                v-else
                class="font-related-xxl my-3.5 font-bold"
              >
                Inventory
              </div>
              <template v-if="form.inventory_countable.length">
                <div class="font-related-xl my-2.5 font-medium">
                  Coutnable
                </div>
                <filtering-table
                  :rows="form.inventory_countable"
                  :max-height="`${Math.max(300, $windowHeight * 0.6)}px`"
                  header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                  cell-class-name="gs-col-padding-mini"
                  fit
                  :columns="{
                    ID: { header: 'ID', sortable: true, filterField: true, type: 'uint', elAttr: { width: 80 } },
                    prev: { elAttr: { width: 100 } },
                    count: { sortable: true, filterField: true, type: 'int', elAttr: { width: 80 } },
                    title: { sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 300 } }
                  }"
                  :page-sizes="[50, 100, 200]"
                  hide-refresh-btn
                >
                  <template #cell_prev="{ row }">
                    <el-image
                      v-if="row.preview_url"
                      :src="row.preview_url"
                      fit="contain"
                      class="h-14 w-14 hover:scale-105"
                      :preview-src-list="[row.preview_url]"
                      preview-teleported
                      hide-on-click-modal
                    />
                  </template>
                </filtering-table>
              </template>
              <template v-if="form.inventory_skins.length">
                <div class="font-related-xl mb-2.5 mt-10 font-medium">
                  Skins
                </div>
                <filtering-table
                  :rows="form.inventory_skins"
                  :max-height="`${Math.max(300, $windowHeight * 0.6)}px`"
                  header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                  cell-class-name="gs-col-padding-mini"
                  fit
                  :columns="{
                    ID: { header: 'ID', sortable: true, filterField: true, type: 'uint', elAttr: { width: 80 } },
                    prev: { elAttr: { width: 100 } },
                    count: { sortable: true, filterField: true, type: 'int', elAttr: { width: 80 } },
                    title: { sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 300 } }
                  }"
                  :page-sizes="[50, 100, 200]"
                  hide-refresh-btn
                >
                  <template #cell_prev="{ row }">
                    <el-image
                      v-if="row.preview_url"
                      :src="row.preview_url"
                      fit="contain"
                      class="h-14 w-14 hover:scale-105"
                      :preview-src-list="[row.preview_url]"
                      preview-teleported
                      hide-on-click-modal
                    />
                  </template>
                </filtering-table>
              </template>
              <template v-if="form.player_gifts?.length">
                <div class="font-related-xl mt-10 mb-2.5 font-medium">
                  Gifts
                </div>
                <filtering-table
                  :rows="form.player_gifts"
                  :max-height="`${Math.max(300, $windowHeight * 0.6)}px`"
                  header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                  cell-class-name="gs-col-padding-mini"
                  fit
                  :columns="{
                    id: { header: 'ID', sortable: true, filterField: true, type: 'uint', elAttr: { width: 80 } },
                    layout: { elAttr: { width: 200 } },
                    date: { sortable: true, filterField: true, type: 'string', elAttr: { width: 200 } },
                    sender: { sortable: true, filterField: true, type: 'uint', elAttr: { width: 100 } },
                    info: { sortable: false, filterField: true, type: 'string', elAttr: { minWidth: 300 } },
                    details: { sortable: false, filterField: true, type: 'string', elAttr: { minWidth: 400 } }
                  }"
                  :page-sizes="[50, 100, 200]"
                  hide-refresh-btn
                />
              </template>
            </el-col>
          </el-tab-pane>
          <el-tab-pane
            label="Stats"
            name="stats"
            lazy
          >
            <el-col>
              <table class="gs-basic-table w-full">
                <tr>
                  <td class="min-w-[50vw] sm:min-w-min sm:w-1/5">
                    Level
                  </td>
                  <td class="min-w-[50vw] sm:min-w-min">
                    {{ form.item.Level }}
                  </td>
                </tr>
                <tr>
                  <td>XP</td>
                  <td>{{ Number(form.item.Xp).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>Energy</td>
                  <td>{{ form.item.Energy }}</td>
                </tr>
                <tr>
                  <td>Gems</td>
                  <td>{{ form.item.Gems }}</td>
                </tr>
                <tr>
                  <td>Created</td>
                  <td>{{ $utils.formatUTCDateTime(form.item.Created) }}</td>
                </tr>
                <tr>
                  <td>Last Login</td>
                  <td>{{ $utils.formatUTCDateTime(form.item.SessionStart) }}</td>
                </tr>
                <tr>
                  <td>Country Code</td>
                  <td>{{ form.item.CountryCode }}</td>
                </tr>
                <tr>
                  <td>Forced Country Code</td>
                  <td>{{ form.item.ForcedCountryCode }}</td>
                </tr>
                <tr>
                  <td>Zone</td>
                  <td>{{ form.item.Zone }}</td>
                </tr>
                <tr>
                  <td>Is Guild Master</td>
                  <td>{{ form.item.IsGuildMaster ? 'Yes' : 'No' }}</td>
                </tr>
                <tr>
                  <td>Is Deputy</td>
                  <td>{{ form.item.IsDeputy ? 'Yes' : 'No' }}</td>
                </tr>
                <tr>
                  <td>Is Bot</td>
                  <td>{{ form.item.IsBot ? 'Yes' : 'No' }}</td>
                </tr>
                <tr>
                  <td>Is System Player</td>
                  <td>{{ form.item.IsSystem ? 'Yes' : 'No' }}</td>
                </tr>
                <tr>
                  <td>SeasonPass points</td>
                  <td>{{ form.item.SeasonPassPoints }}</td>
                </tr>
                <tr>
                  <td>Device</td>
                  <td>{{ form.item.DeviceId }}</td>
                </tr>
                <tr>
                  <td>Same Device</td>
                  <td>{{ form.same_device_id }}</td>
                </tr>
                <tr>
                  <td>Apple ID</td>
                  <td>{{ form.item.AppleId }}</td>
                </tr>
                <tr>
                  <td>Google ID</td>
                  <td>{{ form.item.GoogleId }}</td>
                </tr>
                <tr>
                  <td>Facebook ID</td>
                  <td>{{ form.item.FacebookId }}</td>
                </tr>
                <tr>
                  <td>Card Group</td>
                  <td>{{ String.fromCharCode((form.item?.ID % 3) + 65) }}</td>
                </tr>
                <tr>
                  <td>Store Token</td>
                  <td>{{ form.item.StoreToken }}</td>
                </tr>
                <tr>
                  <td>Store GCoins</td>
                  <td>{{ form.item.StoreGCoins }}</td>
                </tr>
                <tr>
                  <td>Store Spent (since last reset)</td>
                  <td>{{ form.item.StoreSpent }}</td>
                </tr>
                <tr>
                  <td>Store Current Status</td>
                  <td>{{ form.store_current_status }}</td>
                </tr>
                <tr>
                  <td>Store Previous Status</td>
                  <td>{{ form.item.StorePrevStatus }}</td>
                </tr>
              </table>
            </el-col>
          </el-tab-pane>
          <el-tab-pane
            label="Guild"
            name="guild"
            lazy
          >
            <el-col>
              <div v-if="form.guild?.ID === 0">
                This player does not belong to any guild
              </div>
              <table
                v-else
                class="gs-basic-table w-full"
              >
                <tr>
                  <td class="min-w-[50vw] sm:min-w-min sm:w-1/5">
                    ID
                  </td>
                  <td class="min-w-[50vw] sm:min-w-min">
                    <router-link
                      :to="
                        $utils.bindStaticParams({
                          name: 'gameplay-guild-list-details',
                          params: { id: form.guild?.ID },
                          query: { env: form?.env }
                        })
                      "
                      target="_blank"
                      class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
                    >
                      {{ form.guild?.ID }}
                    </router-link>
                  </td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>
                    <router-link
                      :to="
                        $utils.bindStaticParams({
                          name: 'gameplay-guild-list-details',
                          params: { id: form.guild?.ID },
                          query: { env: form?.env }
                        })
                      "
                      target="_blank"
                      class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
                    >
                      {{ form.guild.name }}
                    </router-link>
                  </td>
                </tr>
                <tr>
                  <td>Division No</td>
                  <td>
                    <router-link
                      :to="
                        $utils.bindStaticParams({
                          name: 'gameplay-divisions',
                          query: { c_sea: { env: form?.env, zone: form.guild.zone } }
                        })
                      "
                      target="_blank"
                      class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
                    >
                      {{ form.guild.division_no }}
                    </router-link>
                  </td>
                </tr>
                <tr>
                  <td>League No</td>
                  <td>
                    <router-link
                      :to="
                        $utils.bindStaticParams({
                          name: 'gameplay-divisions',
                          query: { c_sea: { env: form?.env, zone: form.guild.zone } }
                        })
                      "
                      target="_blank"
                      class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
                    >
                      {{ form.guild.group_no }}
                    </router-link>
                  </td>
                </tr>

                <tr>
                  <td>Zone</td>
                  <td>
                    <router-link
                      :to="
                        $utils.bindStaticParams({
                          name: 'gameplay-divisions',
                          query: { c_sea: { env: form?.env, zone: form.guild.zone } }
                        })
                      "
                      target="_blank"
                      class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
                    >
                      {{ form.guild.zone }}
                    </router-link>
                  </td>
                </tr>
              </table>
            </el-col>
          </el-tab-pane>
          <el-tab-pane
            label="Payments"
            name="payments"
            lazy
          >
            <el-col>
              <div v-if="!form.payments.length">
                No payment with OK status for this user
              </div>
              <div v-if="form.item.IsSystem">
                This is system player, payments will be not included in stats
              </div>
              <router-link
                :to="
                  $utils.bindStaticParams({
                    name: 'gameplay-payment-logs',
                    query: { c_fil: { PlayerId: form.item?.ID }, c_sea: { env: form?.env } }
                  })
                "
                target="_blank"
                class="mb-8 mt-5 block text-blue-500 underline underline-offset-4 hover:text-blue-600"
              >
                Click here if you want to see all payments
              </router-link>
              Sum: {{ form.payments.reduce((acc, item) => acc + item.price, 0).toFixed(2) }}
              <filtering-table
                v-if="!!form.payments.length"
                :rows="form.payments"
                :max-height="`${Math.max(300, $windowHeight * ($windowWidth > 640 ? 0.72 : 0.6))}px`"
                header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                cell-class-name="gs-col-padding-mini"
                fit
                :columns="{
                  date: { sortable: true, filterField: true, type: 'string', elAttr: { width: 160 } },
                  offer_id: { header: 'Offer ID', sortable: true, filterField: true, type: 'uint', elAttr: { width: 100 } },
                  offer_name: {
                    header: 'Offer Name',
                    sortable: true,
                    filterField: true,
                    type: 'string',
                    elAttr: { minWidth: 200 }
                  },
                  price: { sortable: true, filterField: true, type: 'float', elAttr: { width: 100 } },
                  is_voided: { header: 'Is Voided', sortable: true, filterField: true, type: 'int', elAttr: { width: 100 } },
                  voided_time: {
                    header: 'Voided Time',
                    sortable: true,
                    filterField: true,
                    type: 'string',
                    elAttr: { width: 160 }
                  },
                  voided_reason: {
                    header: 'Voided Reason',
                    sortable: true,
                    filterField: true,
                    type: 'string',
                    elAttr: { width: 160 }
                  }
                }"
                :page-sizes="[50, 100, 200]"
                hide-refresh-btn
              >
                <template #cell_price="{ row }">
                  {{ Number(row.price).toLocaleString() }}
                </template>
              </filtering-table>
            </el-col>
          </el-tab-pane>
          <el-tab-pane
            label="Season Ranking"
            name="season_ranking"
            lazy
          >
            <el-col>
              <div v-if="!form.player_season_ranking.length">
                Player is not assigned to season ranking
              </div>
              <filtering-table
                v-else
                :rows="form.player_season_ranking"
                :max-height="`${Math.max(300, $windowHeight * ($windowWidth > 640 ? 0.72 : 0.6))}px`"
                header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                cell-class-name="gs-col-padding-mini"
                fit
                :columns="{
                  no: { header: 'No.', sortable: true, filterField: true, type: 'uint', elAttr: { width: 70 } },
                  avatar: { elAttr: { width: 80 } },
                  points: { sortable: true, filterField: true, type: 'int', elAttr: { width: 80 } },
                  name: { sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 250 } }
                }"
                :page-sizes="[50, 100, 200]"
                hide-refresh-btn
              >
                <template #cell_no="{ row }">
                  <span :class="{ 'font-black': row.ID === form.item.ID }">{{ row.no }}</span>
                </template>
                <template #cell_avatar="{ row }">
                  <el-image
                    v-if="row.avatar"
                    :src="row.avatar"
                    fit="contain"
                    class="h-10 w-10 hover:scale-105"
                    :preview-src-list="[row.avatar]"
                    preview-teleported
                    hide-on-click-modal
                  />
                </template>
                <template #cell_name="{ row }">
                  <router-link
                    :to="
                      $utils.bindStaticParams({
                        name: 'gameplay-players-details',
                        params: { id: row.ID },
                        query: { env: form?.env }
                      })
                    "
                    target="_blank"
                    class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
                  >
                    {{ row.name }}
                  </router-link>
                </template>
              </filtering-table>
            </el-col>
          </el-tab-pane>
          <el-tab-pane
            label="Trophies"
            name="trophies"
            lazy
          >
            <el-col>
              <div v-if="!form.trophies.length">
                Player has no trophies (never been in a guild)
              </div>
              <filtering-table
                v-else
                :rows="form.trophies"
                :max-height="`${Math.max(300, $windowHeight * ($windowWidth > 640 ? 0.72 : 0.6))}px`"
                header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
                cell-class-name="gs-col-padding-mini"
                fit
                :columns="{
                  s: { header: 'Season', sortable: true, filterField: true, type: 'int', elAttr: { width: 190 } },
                  g: { header: 'Guild', sortable: true, filterField: true, type: 'int', elAttr: { width: 190 } },
                  d: { header: 'Division', sortable: true, filterField: true, type: 'int', elAttr: { width: 190 } },
                  v0: {
                    header: 'Line Defended',
                    sortable: true,
                    filterField: true,
                    type: 'int',
                    elAttr: { width: 190, formatter: (row) => row?.v?.[0] }
                  },
                  v1: {
                    header: 'Champions',
                    sortable: true,
                    filterField: true,
                    type: 'int',
                    elAttr: { width: 190, formatter: (row) => row?.v?.[1] }
                  },
                  v2: {
                    header: 'Guild Trophy',
                    sortable: true,
                    filterField: true,
                    type: 'int',
                    elAttr: { width: 190, formatter: (row) => row?.v?.[2] }
                  },
                  v4: {
                    header: 'Chargers',
                    sortable: true,
                    filterField: true,
                    type: 'int',
                    elAttr: { width: 190, formatter: (row) => row?.v?.[4] }
                  },
                  v5: {
                    header: 'Player Trophy',
                    sortable: true,
                    filterField: true,
                    type: 'int',
                    elAttr: { width: 190, formatter: (row) => row?.v?.[5] }
                  }
                }"
                :page-sizes="[50, 100, 200]"
                hide-refresh-btn
              />
            </el-col>
          </el-tab-pane>
          <el-tab-pane
            label="Managing"
            name="managing"
            lazy
          >
            <el-col>
              <el-card
                shadow="never"
                class="mt-10"
              >
                <fields-col>
                  <crud-field-text
                    :sm="6"
                    api-field-name="item.Name"
                    label="Player name"
                    required
                    :rules="[$v.maxLength(32)]"
                  />
                  <crud-field-text
                    :sm="12"
                    api-field-name="item.Info"
                    label="Player description"
                  />
                  <crud-field-text
                    :sm="6"
                    api-field-name="item.ForcedCountryCode"
                    label="Forced country code"
                    :rules="[$v.maxLength(6)]"
                  />
                  <crud-field-text
                    :sm="3"
                    api-field-name="item.Lang"
                    label="Lang"
                    :rules="[$v.maxLength(6)]"
                  />
                  <crud-field-switcher
                    api-field-name="item.BlockLang"
                    label="Block Lang"
                    class="flex-none"
                  >
                    <template #fieldAppend>
                      <div class="pl-1 text-red-600">
                        Block the possibility of language change through client
                      </div>
                    </template>
                  </crud-field-switcher>
                  <crud-field-number
                    :sm="4"
                    api-field-name="item.Energy"
                    :min="-1000000"
                    :max="1000000"
                  />
                  <crud-field-number
                    :sm="4"
                    api-field-name="item.Gems"
                    :min="-1000000"
                    :max="1000000"
                  />
                  <crud-field-number
                    :sm="3"
                    label="GCoins"
                    api-field-name="item.StoreGCoins"
                  />
                  <crud-field-text
                    :sm="6"
                    api-field-name="seasonPassExpire"
                    label="Season Pass Expire At"
                    disabled
                  />
                  <el-col />
                  <crud-field-switcher
                    api-field-name="item.IsBot"
                    label="Is bot"
                    class="flex-none"
                  >
                    <template #fieldAppend>
                      <div class="pl-1 text-red-600">
                        Do not change it without a specific reason!
                      </div>
                    </template>
                  </crud-field-switcher>
                  <crud-field-switcher
                    api-field-name="item.IsSuspicious"
                    label="Is warned"
                    class="flex-none"
                  >
                    <template #fieldAppend>
                      <div class="pl-1 text-red-600">
                        This player has been warned for doing something suspicious!
                      </div>
                    </template>
                  </crud-field-switcher>
                  <crud-field-switcher
                    api-field-name="item.IsModerator"
                    label="Is Moderator"
                    class="flex-none"
                  />
                  <crud-field-switcher
                    api-field-name="item.IsToDel"
                    label="IsToDel"
                    doc-icon-class="-top-1 -right-16"
                    class="flex-none"
                  />
                  <crud-field-switcher
                    api-field-name="setSeasonPass"
                    label="Set"
                    class="flex-none"
                    doc-icon-class="-top-1 -right-16"
                  />
                  <crud-field-switcher
                    api-field-name="resetSeasonPass"
                    label="Reset"
                    class="flex-none"
                    doc-icon-class="-top-1 -right-16"
                  />
                  <el-col />
                  <crud-field-textarea
                    api-field-name="item.Notes"
                    class="flex-none"
                  />
                  <el-col />
                  <fields-col justify="end">
                    <crud-field-slot
                      :sm="4"
                      empty-label
                      label="Remove avatar"
                    >
                      <el-button
                        class="gs-height-related-sm gs-font-scaled font-related gs-btn-danger w-full"
                        :disabled="!envApi"
                        :loading="loading.removeAvatar"
                        @click="removeAvatar"
                      >
                        Remove avatar
                      </el-button>
                    </crud-field-slot>
                    <crud-field-slot
                      :sm="4"
                      empty-label
                      label="Save player"
                    >
                      <el-button
                        class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                        :disabled="!envApi"
                        :loading="loading.savePlayer"
                        @click="savePlayer(formRefer)"
                      >
                        Save player
                      </el-button>
                    </crud-field-slot>
                  </fields-col>
                </fields-col>
              </el-card>
              <el-card
                shadow="never"
                class="relative mt-10"
              >
                <fields-col>
                  <crud-field-slot
                    label="info"
                    empty-label
                    col-class="flex-none"
                  >
                    <strong
                      v-if="form.player_guild_join_ban"
                      class="text-red-600"
                    >Guild join ban expires at: {{ form.player_guild_join_ban_till }}</strong>
                    <strong
                      v-if="!form.player_guild_join_ban"
                      class="text-green-800"
                    >Guild join ban not set</strong>
                  </crud-field-slot>
                  <fields-col justify="end">
                    <crud-field-slot
                      :sm="4"
                      empty-label
                      label="Reset Guild Join Ban"
                    >
                      <el-button
                        :disabled="!envApi"
                        class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                        :loading="loading.resetGuildJoinBan"
                        @click="resetGuildJoinBan()"
                      >
                        Reset Guild Join Ban
                      </el-button>
                    </crud-field-slot>
                  </fields-col>
                </fields-col>
                <fields-col>
                  <crud-field-switcher
                    api-field-name="item.WelcomeMessageSent"
                    label="Send message"
                    class="flex-none"
                  />
                  <crud-field-slot
                    label="info"
                    empty-label
                    col-class="flex-none"
                  >
                    <strong
                      v-if="form.player_welcome_message_sent"
                      class="text-red-600"
                    >Message sent on: {{ form.player_welcome_message_sent_date }}</strong>
                    <strong
                      v-if="!form.player_welcome_message_sent"
                      class="text-green-800"
                    >Message not sent</strong>
                  </crud-field-slot>
                  <fields-col justify="end">
                    <crud-field-slot
                      :sm="4"
                      empty-label
                      label="Send Welcome Message"
                    >
                      <el-button
                        :disabled="!envApi"
                        class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                        :loading="loading.sendWelcomeMessage"
                        @click="sendWelcomeMessage()"
                      >
                        Send Welcome Message
                      </el-button>
                    </crud-field-slot>
                  </fields-col>
                </fields-col>
              </el-card>
              <el-card
                v-if="
                  $utils.checkRights(userRightRoles.admin) || $store.getters['auth/userData']?.Email === 'dbuczek@gamesture.com'
                "
                shadow="never"
                class="relative mt-10"
              >
                <fields-col>
                  <CrudFieldFile
                    api-field-name="Set Avatar"
                    upload-title="Upload avatar"
                    :download-file-name="`${form.item.ID}`"
                    :api="`${envApi}/proxy/uploadavatar/`"
                    :api-data="{ id: form.item.ID }"
                    doc-icon-class="left-20 -top-2"
                  />
                </fields-col>
              </el-card>
            </el-col>
          </el-tab-pane>
          <el-tab-pane
            label="Bans"
            name="bans"
            lazy
          >
            <el-col>
              <el-card
                shadow="never"
                class="relative mt-10"
              >
                <fields-col>
                  <crud-field-number
                    :sm="4"
                    api-field-name="setDescriptionBanDays"
                  />
                  <crud-field-slot
                    label="info"
                    empty-label
                    col-class="flex-none"
                  >
                    <strong
                      v-if="!form.player_description_ban_expired"
                      class="text-red-600"
                    >Description ban until: {{ form.player_description_ban_expire }}</strong>
                    <strong
                      v-if="form.player_description_ban_expired"
                      class="text-green-800"
                    >Description ban not set</strong>
                  </crud-field-slot>
                  <crud-field-switcher
                    api-field-name="item.DescriptionBanPlayer"
                    label="Ban changing player description"
                    class="flex-none"
                  />
                  <crud-field-switcher
                    api-field-name="item.DescriptionBanGuild"
                    label="Ban changing guild name & description"
                    class="flex-none"
                  />
                  <crud-field-switcher
                    api-field-name="item.DescriptionBanInvitationMessage"
                    label="Ban invitation with message"
                    class="flex-none"
                  />
                </fields-col>
                <fields-col justify="end">
                  <crud-field-slot
                    :sm="4"
                    empty-label
                    label="Set Description Ban"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                      :disabled="!envApi"
                      :loading="loading.setDescriptionBan"
                      @click="setDescriptionBan(formRefer)"
                    >
                      Set Description ban
                    </el-button>
                  </crud-field-slot>
                  <crud-field-slot
                    :sm="5"
                    empty-label
                    label="Set Description Ban Device"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full bg-red-600"
                      :disabled="!envApi"
                      :loading="loading.setDescriptionBan"
                      @click="setDescriptionBan(true)"
                    >
                      Set Description ban (all devices)
                    </el-button>
                  </crud-field-slot>
                </fields-col>
                <div class="font-related-xss absolute bottom-3 left-5 text-neutral-500">
                  * to unban set <i>"Set description ban days"</i> on 0
                </div>
              </el-card>
              <el-card
                shadow="never"
                class="relative mt-10"
              >
                <fields-col>
                  <crud-field-number
                    :sm="4"
                    api-field-name="setNameBanDays"
                  />
                  <crud-field-slot
                    label="info"
                    empty-label
                    col-class="flex-none"
                  >
                    <strong
                      v-if="!form.player_name_ban_expired"
                      class="text-red-600"
                    >Name ban until: {{ form.player_name_ban_expire }}</strong>
                    <strong
                      v-if="form.player_name_ban_expired"
                      class="text-green-800"
                    >Name ban not set</strong>
                  </crud-field-slot>
                </fields-col>
                <fields-col justify="end">
                  <crud-field-slot
                    :sm="4"
                    empty-label
                    label="Set Name Ban"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                      :disabled="!envApi"
                      :loading="loading.setNameBan"
                      @click="setNameBan(formRefer)"
                    >
                      Set Name ban
                    </el-button>
                  </crud-field-slot>
                  <crud-field-slot
                    :sm="5"
                    empty-label
                    label="Set Name Ban Device"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full bg-red-600"
                      :disabled="!envApi"
                      :loading="loading.setNameBan"
                      @click="setNameBan(true)"
                    >
                      Set Name ban (all devices)
                    </el-button>
                  </crud-field-slot>
                </fields-col>
                <div class="font-related-xss absolute bottom-3 left-5 text-neutral-500">
                  * to unban set <i>"Set name ban days"</i> on 0
                </div>
              </el-card>
              <el-card
                shadow="never"
                class="relative mt-10"
              >
                <fields-col>
                  <crud-field-number
                    :sm="4"
                    api-field-name="setChatShadowBanDays"
                  />
                  <crud-field-slot
                    label="info"
                    empty-label
                    col-class="flex-none"
                  >
                    <strong
                      v-if="!form.player_shadowban_expired"
                      class="text-red-600"
                    >ShadowBan until: {{ form.player_shadowban_expire }}</strong>
                    <strong
                      v-if="form.player_shadowban_expired"
                      class="text-green-800"
                    >ShadowBan not set</strong>
                  </crud-field-slot>
                  <crud-field-switcher
                    api-field-name="shadowban_message_send"
                    label="Send message"
                    col-class="flex-none"
                  />
                  <crud-field-switcher
                    api-field-name="item.IsGuildChatShadowBanned"
                    label="Shadowban on guild chat"
                    col-class="flex-none"
                  />
                </fields-col>
                <fields-col v-if="form.shadowban_message_send">
                  <crud-field-selector-client-strings
                    :sm="8"
                    api-field-name="shadowban_message_tag"
                    label="Shadow ban meessage tag"
                    @change="shadowBanMessageTagChanged"
                  />
                  <crud-field-textarea
                    api-field-name="shadowban_message_content"
                    label="Shadow ban message content"
                    required
                  />
                </fields-col>
                <fields-col justify="end">
                  <crud-field-slot
                    :sm="4"
                    empty-label
                    label="Set Shadow Ban"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                      :disabled="!envApi"
                      :loading="loading.setShadowBan"
                      @click="setShadowBan(formRefer)"
                    >
                      Set shadow ban
                    </el-button>
                  </crud-field-slot>
                  <crud-field-slot
                    :sm="5"
                    empty-label
                    label="Set Shadow Ban Device"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full bg-red-600"
                      :disabled="!envApi"
                      :loading="loading.setShadowBan"
                      @click="setShadowBan(formRefer, true)"
                    >
                      Set shadow ban (all devices)
                    </el-button>
                  </crud-field-slot>
                </fields-col>
                <div class="font-related-xss absolute bottom-3 left-5 text-neutral-500">
                  * to unban set <i>"Set chat shadow ban days"</i> on 0
                </div>
              </el-card>
              <el-card
                shadow="never"
                class="relative mt-10"
              >
                <fields-col>
                  <crud-field-number
                    :sm="4"
                    api-field-name="setBanDays"
                  />
                  <crud-field-text
                    :sm="10"
                    api-field-name="item.BanMessage"
                  />
                  <crud-field-slot
                    label="ban_expired"
                    empty-label
                    col-class="flex-none"
                  >
                    <strong
                      v-if="!form.player_ban_expired"
                      class="text-red-600"
                    >Ban until: {{ form.player_ban_expire }}</strong>
                    <strong
                      v-if="form.player_ban_expired"
                      class="text-green-800"
                    >Ban not set</strong>
                  </crud-field-slot>
                  <fields-col justify="end">
                    <crud-field-slot
                      :sm="4"
                      empty-label
                      label="Set Ban"
                    >
                      <el-button
                        class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                        :disabled="!envApi"
                        :loading="loading.setBan"
                        @click="setBan()"
                      >
                        Set ban
                      </el-button>
                    </crud-field-slot>
                    <crud-field-slot
                      :sm="5"
                      empty-label
                      label="Set Ban Device"
                    >
                      <el-button
                        class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full bg-red-600"
                        :disabled="!envApi"
                        :loading="loading.setBan"
                        @click="setBan(true)"
                      >
                        Set ban (all devices)
                      </el-button>
                    </crud-field-slot>
                  </fields-col>
                </fields-col>
                <div class="font-related-xss absolute bottom-3 left-5 text-neutral-500">
                  * to unban set <i>"Set ban days"</i> on 0
                </div>
              </el-card>
              <el-card
                shadow="never"
                class="relative mt-10"
              >
                <fields-col>
                  <crud-field-number
                    :sm="4"
                    api-field-name="setAvatarBanDays"
                  />
                  <crud-field-slot
                    label="avatar_ban_expired"
                    empty-label
                    col-class="flex-none"
                  >
                    <strong
                      v-if="!form.player_avatar_ban_expired"
                      class="text-red-600"
                    >Ban until: {{ form.player_avatar_ban_expire }}</strong>
                    <strong
                      v-if="form.player_avatar_ban_expired"
                      class="text-green-800"
                    >Avatar ban not set</strong>
                  </crud-field-slot>
                  <fields-col justify="end">
                    <crud-field-slot
                      :sm="4"
                      empty-label
                      label="Set Avatar ban"
                    >
                      <el-button
                        class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                        :disabled="!envApi"
                        :loading="loading.setBan"
                        @click="setAvatarBan(false)"
                      >
                        Set Avatar ban
                      </el-button>
                    </crud-field-slot>
                    <crud-field-slot
                      :sm="5"
                      empty-label
                      label="Set Ban Device"
                    >
                      <el-button
                        class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full bg-red-600"
                        :disabled="!envApi"
                        :loading="loading.setBan"
                        @click="setAvatarBan(true)"
                      >
                        Set ban (all devices)
                      </el-button>
                    </crud-field-slot>
                  </fields-col>
                </fields-col>
                <div class="font-related-xss absolute bottom-3 left-5 text-neutral-500">
                  * to unban set <i>"Set ban days"</i> on 0
                </div>
              </el-card>
            </el-col>
          </el-tab-pane>
          <el-tab-pane
            label="Token Swap"
            name="token_swap"
            lazy
          >
            <el-col>
              <el-card
                shadow="never"
                class="mt-10"
              >
                <fields-col>
                  <crud-field-text
                    :sm="6"
                    api-field-name="swapTokenPlayerId"
                  />
                  <crud-field-slot :sm="18">
                    <div class="leading-tight text-red-600">
                      This option swaps the tokens of this player and the selected one.<br>
                      You should be careful with this option. It should be used only when necessary.<br>
                      It should be used only when the player asks for the change. <br>
                      The swap should be justified (e.g. by matching payment history).
                    </div>
                  </crud-field-slot>
                  <crud-field-slot
                    :sm="4"
                    :offset="$windowWidth > 640 ? 1 : undefined"
                    empty-label
                    label="Swap Tokens"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                      :disabled="!envApi"
                      :loading="loading.swapTokens"
                      @click="swapTokens()"
                    >
                      Swap tokens
                    </el-button>
                  </crud-field-slot>
                </fields-col>
              </el-card>
            </el-col>
          </el-tab-pane>
          <el-tab-pane
            label="Social Media"
            name="social_media"
            lazy
          >
            <el-col>
              <el-card
                shadow="never"
                class="mt-10"
              >
                <fields-col>
                  <crud-field-text
                    :sm="6"
                    api-field-name="swapFacebookPlayerId"
                  />
                  <crud-field-slot :sm="13">
                    <div class="leading-tight text-red-600">
                      This option swaps facebook ids of this player and the selected one.<br>
                      You should be careful with this option. It should be used only when necessary.<br>
                      It should be used only when the player asks for the change. <br>
                    </div>
                  </crud-field-slot>
                  <crud-field-slot
                    :sm="4"
                    :offset="$windowWidth > 640 ? 1 : undefined"
                    empty-label
                    label="Swap Facebook"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                      :disabled="!envApi"
                      :loading="loading.swapFacebook"
                      @click="swapFacebook()"
                    >
                      Swap Facebook
                    </el-button>
                  </crud-field-slot>
                </fields-col>
                <fields-col>
                  <crud-field-text
                    :sm="6"
                    api-field-name="swapGooglePlayerId"
                  />
                  <crud-field-slot :sm="13">
                    <div class="leading-tight text-red-600">
                      This option swaps google ids of this player and the selected one.<br>
                      You should be careful with this option. It should be used only when necessary.<br>
                      It should be used only when the player asks for the change. <br>
                    </div>
                  </crud-field-slot>
                  <crud-field-slot
                    :sm="4"
                    :offset="$windowWidth > 640 ? 1 : undefined"
                    empty-label
                    label="Swap Google"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                      :disabled="!envApi"
                      :loading="loading.swapGoogle"
                      @click="swapGoogle()"
                    >
                      Swap Google
                    </el-button>
                  </crud-field-slot>
                </fields-col>
                <fields-col>
                  <crud-field-text
                    :sm="6"
                    api-field-name="swapApplePlayerId"
                  />
                  <crud-field-slot :sm="13">
                    <div class="leading-tight text-red-600">
                      This option swaps apple ids of this player and the selected one.<br>
                      You should be careful with this option. It should be used only when necessary.<br>
                      It should be used only when the player asks for the change. <br>
                    </div>
                  </crud-field-slot>
                  <crud-field-slot
                    :sm="4"
                    :offset="$windowWidth > 640 ? 1 : undefined"
                    empty-label
                    label="Swap Apple"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                      :disabled="!envApi"
                      :loading="loading.swapApple"
                      @click="swapApple()"
                    >
                      Swap Apple
                    </el-button>
                  </crud-field-slot>
                </fields-col>
                <fields-col justify="end">
                  <crud-field-slot
                    :sm="4"
                    empty-label
                    label="Swap Apple"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                      :disabled="!envApi"
                      :loading="loading.delete"
                      @click="deleteSocial(true, false, false)"
                    >
                      Delete Facebook
                    </el-button>
                  </crud-field-slot>
                </fields-col>
                <fields-col justify="end">
                  <crud-field-slot
                    :sm="4"
                    empty-label
                    label="Swap Apple"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                      :disabled="!envApi"
                      :loading="loading.delete"
                      @click="deleteSocial(false, true, false)"
                    >
                      Delete Google
                    </el-button>
                  </crud-field-slot>
                </fields-col>
                <fields-col justify="end">
                  <crud-field-slot
                    :sm="4"
                    empty-label
                    label="Swap Apple"
                  >
                    <el-button
                      class="gs-height-related-sm gs-font-scaled font-related gs-btn-info w-full"
                      :disabled="!envApi"
                      :loading="loading.delete"
                      @click="deleteSocial(false, false, true)"
                    >
                      Delete Apple
                    </el-button>
                  </crud-field-slot>
                </fields-col>
              </el-card>
            </el-col>
          </el-tab-pane>
          <el-tab-pane
            label="Chat"
            name="chat"
            lazy
          >
            <div class="gs-font-scaled mx-auto max-w-5xl">
              <!--              <fields-col class="sm:ml-auto"> -->
              <!--                <crud-field-slot -->
              <!--                  :sm="8" -->
              <!--                  label="action btns" -->
              <!--                  empty-label -->
              <!--                > -->
              <!--                  <template #default> -->
              <!--                    <el-button -->
              <!--                      v-if="!ws?.isOpen" -->
              <!--                      class="gs-btn-outlined-primary" -->
              <!--                      @click="connect" -->
              <!--                      >connect to chat -->
              <!--                    </el-button> -->
              <!--                    <el-button -->
              <!--                      v-if="!!ws?.isOpen" -->
              <!--                      class="gs-btn-outlined-danger-neutral" -->
              <!--                      @click="disconect" -->
              <!--                      >disconnect chat -->
              <!--                    </el-button> -->
              <!--                  </template> -->
              <!--                </crud-field-slot> -->
              <!--              </fields-col> -->

              <div ref="chatWrapper">
                <el-tabs
                  v-model="chatTabs"
                  class="max-w-xs sm:w-full sm:max-w-none"
                >
                  <el-tab-pane
                    v-for="(tabData, tabIndex) in notEmptyChatsTabs"
                    :key="tabData.id"
                    v-loading="chat.loading[`chat_${tabData.id}_loading`]"
                    :label="tabData.name"
                    :name="`chat_${tabData.id}`"
                  >
                    <el-scrollbar
                      :ref="`chat_${tabData.id}_scroll`"
                      :height="chat.height"
                      @scroll="lazyLoad($event, tabIndex, tabData)"
                    >
                      <div class="flex flex-col pb-4 pr-4">
                        <template
                          v-for="(msg, msgInd) in chat.messages[`chat_${tabData.id}`] ?? []"
                          :key="`${tabData.id}_${msg.unix}`"
                        >
                          <div class="mt-3 flex">
                            <div
                              class="gs-chat-box relative inline-block pb-2 pl-4 pr-6 pt-3"
                              :class="{
                                'ml-auto mr-8 rounded-lg rounded-br-none bg-blue-600 text-white ':
                                  (msg.p !== msg.p2 && msg.p2 === playerId)
                                  || (msg.p === playerId && msg.p2 === playerId && msgInd % 2),
                                'own ml-8 mr-auto rounded-lg rounded-bl-none bg-slate-300 text-gray-600':
                                  (msg.p !== msg.p2 && msg.p2 !== playerId)
                                  || (msg.p === playerId && msg.p2 === playerId && !(msgInd % 2)),
                                'border-4 border-yellow-400': msg.t === 2 || msg.t === 5
                              }"
                            >
                              <sup class="absolute right-2 top-2">{{ msg.date }}</sup>
                              <div class="absolute -right-8 top-1 flex w-6 flex-col items-start">
                                <icon-ify
                                  v-if="!chat.translatedMessages[msg.key]"
                                  icon="cil:language"
                                  class="gs-scaled-icon-md mb-0.5 cursor-pointer text-neutral-200 hover:text-cyan-600 active:opacity-80"
                                  @click="translateMessage(msg.key, tabData)"
                                />
                              </div>
                              <div class="flex flex-row flex-nowrap">
                                <p>
                                  <strong class="pr-4">{{ pinfo[msg.p] ? pinfo[msg.p].name : 'undefined' }}</strong>
                                </p>
                              </div>
                              <p
                                class="pt-2"
                                style="min-width: 180px"
                              >
                                <template v-if="msg?.msgRendered === null">
                                  {{ msg.msg }}
                                  <i v-if="chat.translatedMessages[msg.key]"><br>{{ chat.translatedMessages[msg.key].msg }}</i>
                                </template>
                                <template
                                  v-for="(msgRendered, index) in msg.msgRendered"
                                  :key="index"
                                >
                                  <span
                                    v-if="msgRendered.html"
                                    class="gs-chat-emoji"
                                    v-html="msgRendered.val"
                                  />
                                  <template v-else>
                                    {{ msgRendered.val }}
                                  </template>
                                </template>
                              </p>
                            </div>
                            <div class="clear-both" />
                          </div>
                        </template>
                      </div>
                    </el-scrollbar>
                    <div class="mb-2 border-t-2 border-gray-200 pt-4 sm:mb-0">
                      <div
                        v-if="!envApi"
                        class="font-black uppercase text-red-500"
                      >
                        Note: you are on localhost (or dev mode) but connected to a different environment !!! For developer tests
                        choose local environment.
                      </div>
                      <div
                        v-if="ws?.isOpen"
                        class="relative flex"
                      >
                        <input
                          :ref="`chat_${tabIndex}_playerMessage`"
                          v-model="playerMessage[chatTabs]"
                          type="text"
                          :placeholder="`Send message on ${env}...`"
                          class="font-related-xl w-full rounded-md bg-gray-100 py-3 pl-12 pr-72 text-gray-600 placeholder-gray-600 focus:placeholder-gray-400 focus:outline-none"
                          @keypress="canShowMembersAutocomplete($event, tabIndex)"
                          @keypress.enter="
                            sendMessage(tabData.guild2_id, tabData.is_tournament, tabData.boss_event_id, tabData.is_cards)
                          "
                        >
                        <div class="absolute inset-y-0 flex w-12 items-center justify-center">
                          <el-popover
                            v-if="showEmojiPicker[tabIndex] !== undefined"
                            v-model:visible="showEmojiPicker[tabIndex]"
                            placement="top"
                            :width="280"
                            trigger="click"
                            popper-class="p-0"
                          >
                            <template #reference>
                              <icon-ify
                                icon="ic:outline-emoji-emotions"
                                class="h-6 w-6 cursor-pointer text-neutral-500 hover:text-cyan-600 focus:outline-0 active:text-cyan-500"
                              />
                            </template>
                            <EmojiPicker
                              :native="true"
                              class="m-0 rounded-none border-0"
                              display-recent
                              disable-skin-tones
                              @select="showEmoji($event, tabIndex)"
                            />
                          </el-popover>
                        </div>
                        <div class="absolute inset-y-0 right-0 flex items-center justify-end">
                          <el-button
                            v-if="(playerIsGuildMaster || playerIsDeputy) && tabIndex === 0"
                            class="gs-btn-warning mr-1 px-4"
                            :disabled="!playerMessage[chatTabs]?.length"
                            @click="sendMessageToAll()"
                          >
                            <icon-ify
                              icon="emojione-monotone:loudspeaker"
                              class="h-6 w-6 text-white"
                            />
                          </el-button>
                          <el-button
                            class="gs-btn-primary ml-0 mr-2 px-2"
                            :disabled="!playerMessage[chatTabs]?.length"
                            @click="
                              sendMessage(tabData.guild2_id, tabData.is_tournament, tabData.boss_event_id, tabData.is_cards)
                            "
                          >
                            Send
                            <icon-ify
                              icon="carbon:send"
                              class="ml-1.5 h-6 w-6 text-white"
                            />
                          </el-button>
                        </div>
                      </div>
                      <div
                        v-else
                        class="mt-4 font-related-sm flex flex-col justify-between text-neutral-400 sm:flex-row items-center"
                      >
                        <el-button
                          v-if="!ws?.isOpen"
                          class="gs-btn-outlined-primary"
                          @click="getChatPreview"
                        >
                          Refresh chat preview
                        </el-button>
                        <div class="mt-4 sm:mt-0 leading-none">
                          you can only preview chat (you are not connected)
                        </div>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="Personal message"
            name="PersonalMessage"
          >
            <template #label>
              <router-link
                :to="
                  $utils.bindStaticParams({
                    name: 'personal-messages-details',
                    params: { id: 0 },
                    query: { playerCSV: form.item.ID }
                  })
                "
                target="_blank"
                class="mt-1 mb-1 text-blue-500 hover:text-blue-600"
              >
                Personal message
              </router-link>
            </template>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </template>
  </crud-details-page>
</template>

<style lang="postcss" scoped>
:deep(.el-tabs) {
  .el-tabs__nav-prev,
  .el-tabs__nav-next {
    background-color: rgba(192, 192, 192, 0.1);
  }
}
.gs-chat-box {
  max-width: 80%;
  line-height: 1.6em;
}

.gs-chat-emoji {
  font-size: 1.6em;
  display: inline-block;
  transform: translateY(0.1em);
}
</style>
