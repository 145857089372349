<script>
export default {
  data() {
    return {
      externalData: {},
      envApi: '',
      endPoint: '',
      loading: {}
    }
  },
  methods: {
    loadData() {
      const envApi = this.$store.getters['auth/envs']?.[this.$route.query?.env]
      if (!(this.$utils.isDevMode() && this.$route.query?.env !== 'local')) {
        this.envApi = envApi
      }
      this.endPoint = `/proxy/gameplay/rushevent/${this.$route.params?.id}/`
      this.$axios
        .get(envApi + this.endPoint, { params: { json: 1 } })
        .then(({ data }) => {
          this.externalData = data
        })
        .catch(this.$utils.catchError)
    },
    setTitle(apiLabel, data) {
      return `${(this.$route.query?.env || '').toUpperCase()} - ${data?.zone || ''}  : Rush event :  ${data?.rush_event_name || ''}`
    }
  }
}
</script>

<template>
  <crud-details-page
    :versioned="false"
    :actions="{
      crudSave: false,
      crudDuplicate: false,
      crudNewVersion: false,
      crudUsage: false,
      crudDelete: false
    }"
    disable-top-margin
    :external-data="externalData"
    :render-title="setTitle"
    :mobile-single-column="false"
    @is-access="loadData"
  >
    <template #form>
      <h1 v-if="externalData?.s?.length">
        Date {{ externalData.s }} - {{ externalData.e }}
      </h1>
      <el-scrollbar class="w-full">
        <table class="gs-font-scaled mt-4 whitespace-nowrap align-top">
          <template
            v-for="row in Math.ceil(99 / 7)"
            :key="row"
          >
            <tr>
              <template
                v-for="td in 7"
                :key="td"
              >
                <td
                  class="gs-related-px-sm pb-4 align-top"
                  :set="(groupNo = (row - 1) * 7 + td - 1)"
                >
                  <div v-if="externalData.groups?.[groupNo]">
                    <div class="gs-related-py-xs font-semibold">
                      Group #{{ groupNo + 1 }} (BattleId: {{ externalData.groups[groupNo].battle_id }})
                      <span
                        v-if="externalData.groups?.[groupNo]?.fighters?.[0]?.started !== false"
                        class="pl-2 text-green-700"
                      >started</span>
                      <span
                        v-else
                        class="pl-2 text-red-500"
                      >
                        not started
                      </span>
                    </div>
                    <div
                      v-for="(player, indexPlayer) in externalData.groups[groupNo].fighters"
                      :key="indexPlayer"
                    >
                      #{{ player.position }}
                      {{ player.name }}
                      {{ player.id }}
                      <span
                        v-if="player.score"
                        class="font-bold"
                      >
                        Score {{ player.score }}
                      </span>
                      <span
                        v-if="player.revenue"
                        class="font-bold"
                      >
                        Revenue ${{ player.revenue }}
                      </span>
                      {{ player.guild_name }}
                    </div>
                  </div>
                </td>
              </template>
            </tr>
          </template>
          <tr>
            <th class="text-xl text-left">
              Local ranking
            </th>
          </tr>
          <tr>
            <div
              v-for="(player, indexPlayer) in externalData.ranking"
              :key="indexPlayer"
            >
              #{{ indexPlayer + 1 }}
              {{ player.name }}
              {{ player.id }}
              <span
                v-if="player.score"
                class="pl-2 font-bold text-red-500"
              >
                Score {{ player.score }}
              </span>
              <span
                v-if="player.revenue"
                class="font-bold"
              >
                Revenue ${{ player.revenue }}
              </span>
              {{ player.guild_name }}
              <span
                v-if="player.is_bot"
                class="font-bold"
                style="color: orange"
              >
                BOT
              </span>
            </div>
          </tr>
        </table>
      </el-scrollbar>
    </template>
  </crud-details-page>
</template>
