<script setup>
</script>

<template>
  <crud-details-page
    api="nettoSettings/game-setting"
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Key -->
        <crud-field-text
          api-field-name="Key"
          required
        />
        <!-- Value -->
        <crud-field-text
          api-field-name="Value"
          required
        />
      </fields-col>
      <!-- Description -->
      <crud-field-textarea
        :sm="16"
        api-field-name="Description"
        :textarea-rows="5"
      />
    </template>
  </crud-details-page>
</template>
