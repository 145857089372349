<script setup>
import { computed, ref } from 'vue'
import draggable from 'vuedraggable'
import { globalProperties as app } from '!/plugins/utilities'

const lootsOrder = ref([])
function initItem(form) {
  form.stageQuantitiesArr = []
  form.lootCount = 20
  const stageQuantitiesArray = [form.StageQuantities1, form.StageQuantities2, form.StageQuantities3, form.StageQuantities4, form.StageQuantities5, form.StageQuantities6, form.StageQuantities7, form.StageQuantities8, form.StageQuantities9, form.StageQuantities10, form.StageQuantities11, form.StageQuantities12, form.StageQuantities13, form.StageQuantities14, form.StageQuantities15, form.StageQuantities16, form.StageQuantities17, form.StageQuantities18, form.StageQuantities19, form.StageQuantities20]
  stageQuantitiesArray.forEach((sq) => {
    if (sq === undefined) {
      form.stageQuantitiesArr.push(Array.from({ length: form.lootCount }, () => 0))
    } else {
      form.stageQuantitiesArr.push(sq.split(',').map(Number))
    }
  })

  form.simEventId = 1
  form.simChestIncomeSimulations = 25
  lootsOrder.value = Array.from({ length: form.lootCount || 0 }).fill(null).map((_, row) => {
    return { row: row + 1 }
  })
  return form
}
const loading = ref({})
function simChest(form, store) {
  loading.value.simChest = true
  const envApi = store?.getters['auth/envs']?.dev
  app.$axios
    .get(`${envApi}/proxy/simchest/`, {
      params: {
        chest_id: form.Versioned.Root,
        event_id: form.simEventId,
        simulations: form.simChestIncomeSimulations
      }
    })
    .then(({ data }) => {
      app.$messageBox
        .confirm(data.logs, undefined, {
          confirmButtonText: 'OK',
          type: 'info',
          dangerouslyUseHTMLString: true,
          showCancelButton: false,
          icon: 'none'
        })
        .catch(() => {})
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loading.value.simChest = false
    })
}
function saveItem(form) {
  const orderedData = {}
  lootsOrder.value.forEach((orderData, order) => {
    orderedData[`Item${order + 1}`] = form[`Item${orderData.row}`]
    orderedData[`Weight${order + 1}`] = form[`Weight${orderData.row}`]
    orderedData[`IsPreview${order + 1}`] = form[`IsPreview${orderData.row}`]

    let stageQuantitiesSlice = ''
    form.stageQuantitiesArr[order].forEach((q, idx) => {
      if (idx > 0) {
        stageQuantitiesSlice += ','
      }
      stageQuantitiesSlice += q
    })

    orderedData[`StageQuantities${order + 1}`] = stageQuantitiesSlice
  })
  return { ...form, ...orderedData }
}

function onChangeOrder(markAsChanged) {
  markAsChanged(
    'lootsOrder',
    lootsOrder.value.some((element, index) => {
      return element.row !== index + 1
    })
  )
}

const itemChance = computed({
  get() {
    return (form, weight) => {
      const w = form[`Weight${weight}`]
      let wSum = 0

      lootsOrder.value.forEach((_, idx) => {
        wSum += form[`Weight${idx + 1}`]
      })

      const chance =
        100 * (w / wSum)
      return chance.toFixed(2)
    }
  }
})
</script>

<template>
  <crud-details-page
    api="chests/chest"
    disable-top-margin
    :render-init-item="initItem"
    :render-saved-item="saveItem"
  >
    <template #form="{ form, markAsChanged }">
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.ChestType"
          :visible-options="['Lottery']"
          required
        />
        <!-- IsEventChest -->
        <crud-field-switcher api-field-name="IsEventChest" />
        <!-- RequiredLevel -->
        <crud-field-number api-field-name="RequiredLevel" />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetShopPreview -->
        <crud-field-selector-files api-field-name="AssetShopPreview" />
        <!-- AssetChestAnimation -->
        <crud-field-selector-files api-field-name="AssetChestAnimation" />
        <!-- AssetSeasonPreview -->
        <crud-field-selector-files api-field-name="AssetSeasonPreview" />
        <!-- AssetChestPreview -->
        <crud-field-selector-files api-field-name="AssetChestPreview" />
        <!-- AssetSeasonBanner -->
        <crud-field-selector-files api-field-name="AssetSeasonBanner" />
      </fields-col>
      <section-divider>Loots</section-divider>
      <el-col>
        <el-card
          shadow="never"
          class="gs-font-scaled relative mt-8 mb-10"
        >
          <fields-col>
            <crud-field-slot
              :sm="4"
              label="Sim Chest"
              empty-label
            >
              <el-button
                class="gs-height-related-xl gs-btn-outlined-primary-neutral gs-loading gs-no-icon"
                :loading="loading.simChest"
                @click="simChest(form, $store)"
              >
                Sim chest
              </el-button>
            </crud-field-slot>
            <crud-field-number
              :sm="4"
              api-field-name="simEventId"
              :min="1"
              label="Sim EventId"
              vertical
            >
              <template #fieldAppend>
                <div class="font-related-xs text-neutral-500">
                  (correct event id)
                </div>
              </template>
            </crud-field-number>
            <crud-field-number
              :sm="4"
              api-field-name="simChestIncomeSimulations"
              label="Number of simulations"
            />
          </fields-col>
          <span class="font-related-xs absolute bottom-2 left-4 text-neutral-500">before sim: save & do CC DEV</span>
        </el-card>
      </el-col>
      <fields-col>
        <fields-table>
          <template #tHeader>
            <tr>
              <th>Nr</th>
              <th>Item</th>
              <th>Weight</th>
              <th>Chance in %</th>
              <th>Quantity 1</th>
              <th>Quantity 2</th>
              <th>Quantity 3</th>
              <th>Quantity 4</th>
              <th>Quantity 5</th>
              <th>Quantity 6</th>
              <th>Quantity 7</th>
              <th>Quantity 8</th>
              <th>Quantity 9</th>
              <th>Quantity 10</th>
            </tr>
          </template>
          <template #tBody>
            <draggable
              v-model="lootsOrder"
              item-key="row"
              ghost-class="gs-ghost-tr"
              tag="tbody"
              handle=".handle"
              @change="onChangeOrder(markAsChanged)"
            >
              <template #item="props">
                <tr class="bg-neutral-50">
                  <fields-table-td
                    :width="55"
                    class="relative text-center align-middle"
                    cell-class=""
                  >
                    <div class="absolute -left-1 top-0 bottom-0 flex items-center">
                      <icon-ify
                        icon="clarity:drag-handle-line"
                        class="gs-scaled-icon-xl handle cursor-move"
                      />
                    </div>
                    <div>{{ props.index + 1 }}</div>
                  </fields-table-td>
                  <fields-table-td :width="200">
                    <crud-field-selector-item
                      :api-field-name="`Item${props.element.row}`"
                      slim
                      :label="false"
                      :min-height="false"
                      disable-doc
                    />
                  </fields-table-td>
                  <fields-table-td :width="100">
                    <crud-field-number
                      :api-field-name="`Weight${props.element.row}`"
                      slim
                      :label="false"
                      :min-height="false"
                      disable-doc
                    />
                  </fields-table-td>
                  <fields-table-td :width="120">
                    <crud-field-slot
                      slim
                      :label="false"
                      :min-height="false"
                      disable-doc
                    >
                      {{ itemChance(form, props.element.row) }}
                    </crud-field-slot>
                  </fields-table-td>
                  <template
                    v-for="i in 10"
                    :key="i"
                  >
                    <fields-table-td :width="100">
                      <crud-field-number
                        :api-field-name="`stageQuantitiesArr.${props.element.row - 1}.${i - 1}`"
                        slim
                        :label="false"
                        :min-height="false"
                        disable-doc
                      />
                    </fields-table-td>
                  </template>
                </tr>
              </template>
            </draggable>
          </template>
        </fields-table>
      </fields-col>
    </template>
  </crud-details-page>
</template>
