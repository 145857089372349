<script>
export default {
  name: 'BattleScorePreview',
  emits: ['bind-preview-battle-action'],
  data() {
    return {
      loading: {},
      dialogBattleVisible: false,
      previewBattleData: {}
    }
  },
  created() {
    this.$emit('bind-preview-battle-action', this.previewBattle)
  },
  methods: {
    previewBattle(battle) {
      this.loading.previewBattle = true
      this.previewBattleData.id = battle.battle_id
      this.previewBattleData.data = {}
      this.dialogBattleVisible = true

      const envApi = this.$store.getters['auth/envs']?.[this.$route.query?.env]
      this.$axios
        .get(`${envApi}/proxy/gameplay/battlescorepreview/`, { params: { json: 1, battle_id: battle.battle_id } })
        .then(({ data }) => {
          data.rows = data?.total ? [data.total, ...data.rows] : data.rows
          this.previewBattleData.data = data;

          [1, 2].forEach((number) => {
            this.previewBattleData.data[`guildPlayers${number}`] = []
            Object.keys(this.previewBattleData.data[`player_dmg${number}`]).forEach((player) => {
              if (this.previewBattleData.data[`player_dmg${number}`][player]) {
                this.previewBattleData.data[`guildPlayers${number}`].push({
                  player,
                  damage: (this.previewBattleData.data[`player_dmg${number}`][player] || 0).toLocaleString(),
                  shields: (this.previewBattleData.data?.[`player_sh${number}`]?.[player] || 0).toLocaleString(),
                  champion: this.previewBattleData.data?.champion?.name === player ? 'X' : '',
                  charger: this.previewBattleData.data?.charger?.name === player ? 'X' : ''
                })
              }
            })
            this.previewBattleData.data[`guildPlayers${number}`].sort((a, b) => {
              return b.damage - a.damage
            })
          })
        })
        .catch(this.$utils.catchError)
        .then(() => {
          this.loading.previewBattle = false
        })
    },
    addHelperClass({ row }) {
      return row?.fighers ? undefined : 'gs-hide-expand-row '
    }
  }
}
</script>

<template>
  <el-dialog
    v-model="dialogBattleVisible"
    :title="`Battle #${previewBattleData.id} score preview:`"
    :width="$windowWidth < 640 ? '95%' : '60%'"
    align-center
  >
    <el-scrollbar
      class="w-full"
      max-height="65vh"
    >
      <el-table
        v-loading="loading.previewBattle"
        :data="previewBattleData.data.rows"
        class="gs-scaled expand-row-p0 w-full"
        header-cell-class-name="bg-sky-50 text-cyan-600 font-normal"
        :row-class-name="addHelperClass"
      >
        <el-table-column
          type="expand"
          :width="25"
        >
          <template #default="{ row }">
            <div
              v-if="row?.fighers?.length"
              class="bg-neutral-50 py-5 px-10"
            >
              <table class="gs-basic-table w-full table-fixed">
                <tr>
                  <th colspan="4">
                    Fighers
                  </th>
                </tr>
                <tr
                  v-for="(figher, figherIndex) in row.fighers"
                  :key="figherIndex"
                >
                  <td>{{ figher.guild_name }}</td>
                  <td>{{ figher.player_name }}</td>
                  <td>Is owner: {{ figher.is_owner ? true : false }}</td>
                  <td>Score : {{ figher.score.toLocaleString() }}</td>
                </tr>
              </table>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="line"
          label="&nbsp;"
          :min-width="100"
        >
          <template #default="{ row }">
            {{ row.line === undefined ? 'Total:' : `Line ${row.line}:` }}
          </template>
        </el-table-column>
        <el-table-column
          prop="guild1_score"
          :label="previewBattleData.data.guild1_name"
          :formatter="(row, column, cellValue) => cellValue.toLocaleString()"
          :min-width="100"
        />
        <el-table-column
          prop="guild2_score"
          :label="previewBattleData.data.guild2_name"
          :formatter="(row, column, cellValue) => cellValue.toLocaleString()"
          :min-width="100"
        />
        <el-table-column
          prop="dice_points"
          label="Dice points"
          :formatter="(row, column, cellValue) => cellValue.toLocaleString()"
          :min-width="100"
        />
        <el-table-column
          prop="result"
          label="Result"
          :min-width="100"
        />
      </el-table>
      <template v-if="!loading.previewBattle">
        <div
          v-for="n in 2"
          :key="n"
        >
          <h2
            v-if="previewBattleData.data[`guild${n}_name`]"
            class="font-related-xxl mt-5 font-bold"
          >
            {{ previewBattleData.data[`guild${n}_name`] }}
          </h2>
          <el-table
            v-if="previewBattleData.data[`guildPlayers${n}`]?.length"
            :data="previewBattleData.data[`guildPlayers${n}`]"
            class="gs-scaled mt-5"
            header-cell-class-name="bg-sky-50 text-cyan-600 font-normal"
          >
            <el-table-column
              prop="player"
              label="Player"
            />
            <el-table-column
              prop="damage"
              label="Damage"
            />
            <el-table-column
              prop="shields"
              label="Shields"
            />
            <el-table-column
              prop="champion"
              label="Champion"
              align="center"
            />
            <el-table-column
              prop="charger"
              label="Charger"
              align="center"
            />
          </el-table>
        </div>
      </template>
    </el-scrollbar>
  </el-dialog>
</template>

<style scoped></style>
