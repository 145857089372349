<script setup></script>

<template>
  <crud-details-page
    api="season-pass"
    disable-top-margin
  >
    <template #form>
      <!-- SeasonNo -->
      <crud-field-number
        :sm="5"
        api-field-name="SeasonNo"
        required
      />
      <!-- No -->
      <crud-field-number
        :sm="5"
        api-field-name="No"
        required
      />
      <!-- RequiredPoints -->
      <crud-field-number
        :sm="5"
        api-field-name="RequiredPoints"
        required
      />
      <section-divider>Normal rewards</section-divider>
      <fields-col :sm="5">
        <!-- NormalGems -->
        <crud-field-number api-field-name="NormalGems" />
        <!-- NormalEnergy -->
        <crud-field-number api-field-name="NormalEnergy" />
      </fields-col>
      <fields-col :sm="5">
        <!-- NormalXpPct -->
        <crud-field-number api-field-name="NormalXpPct" />
        <!-- NormalScore -->
        <crud-field-number
          api-field-name="NormalScore"
          label="Normal score (attack points)"
        />
      </fields-col>
      <crud-field-selector-item
        :sm="8"
        api-field-name="NormalItem"
      />
      <section-divider>Premium rewards</section-divider>
      <fields-col :sm="5">
        <!-- PremiumGems -->
        <crud-field-number api-field-name="PremiumGems" />
        <!-- PremiumEnergy -->
        <crud-field-number api-field-name="PremiumEnergy" />
      </fields-col>
      <fields-col :sm="5">
        <!-- PremiumXpPct -->
        <crud-field-number api-field-name="PremiumXpPct" />
        <!-- PremiumScore -->
        <crud-field-number
          api-field-name="PremiumScore"
          label="Premium score (attack points)"
        />
      </fields-col>
      <fields-col :sm="8">
        <crud-field-selector-item api-field-name="PremiumItem" />
        <!-- PremiumIsSpecial -->
        <crud-field-switcher
          label="Highlight premium reward on the banner"
          api-field-name="PremiumIsSpecial"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
