<script setup>
import EnvSelector from '@/components/proxy/EnvSelector.vue'

function renderLoadedData(data) {
  const renderedRows = []
  data.rows.forEach((row, rowIndex) => {
    if (row?.new_guild?.length) {
      row.new_guild.forEach((guild, index) => {
        const newRow = { ...row }
        newRow.new_guild = guild
        newRow.division = row?.division?.[index] || ''
        newRow.players = row?.players?.[index] || ''
        newRow.power = row?.power?.[index] || ''
        newRow.skirmish = row?.skirmish?.[index] || ''
        newRow.last24H = row?.last24H?.[index] || ''
        newRow.weight = row?.weight?.[index] || ''
        newRow.modulo = rowIndex % 2
        if (index) {
          newRow.isChild = true
        }
        renderedRows.push(newRow)
      })
    }
    data.rows = renderedRows
  })
  return { items: data.rows, total: data.rows.length }
}

function rowClassName({ row }) {
  return row.modulo ? 'bg-zinc-50' : 'bg-teal-50'
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/autoinvitepreview/'
    }"
    :action-col-settings="false"
    :top-actions="false"
    :api-filtering="false"
    :api-pagination="false"
    :api-sorting="false"
    hide-columns-selector
    :render-loaded-data="renderLoadedData"
    :table-props="{
      'header-cell-class-name': 'font-medium bg-sky-50 gs-col-padding-mini text-sky-800',
      'row-class-name': rowClassName
    }"
    :columns="{
      player: 'string:sort',
      level: 'uint:sort',
      zone: 'string:sort',
      country: 'string:sort',
      old: 'string:sort',
      empty: 'any',
      new_guild: 'string:sort',
      division: 'uint:sort',
      players: 'uint:sort',
      power: 'string:sort',
      skirmish: 'string:sort',
      last24H: 'string:sort',
      weight: 'float:sort'
    }"
    :columns-settings="{
      ID: false,
      player: { width: 140, elAttr: { formatter: (row) => row?.player?.name } },
      level: { width: 60 },
      zone: { width: 80 },
      country: { width: 75 },
      old: { header: 'Old guild/last24h%/players', elAttr: { minWidth: 250, formatter: (row) => row?.old_guild?.name } },
      empty: { width: 80, header: ' ', filterField: false },
      new_guild: { header: 'New guild', elAttr: { formatter: (row) => row?.new_guild?.name, minWidth: 280 } },
      division: { width: 75 },
      players: { width: 75 },
      power: { width: 95, header: 'Power+/20' },
      skirmish: { width: 80 },
      last24H: { width: 80, header: 'last24H' },
      weight: { width: 70 }
    }"
    :enable-cell-edit-dialogs="false"
    :rows-on-page-list="[51, 102, 201, 501, 1002]"
    :rows-on-page="51"
    disable-multi-select-rows
  >
    <template #topLeft="{ searchFields }">
      <div class="w-20">
        <EnvSelector v-model:value-field="searchFields.env" />
      </div>
    </template>
    <template #cell_player="{ row, searchFields }">
      <router-link
        v-if="row.player.id"
        :to="
          $utils.bindStaticParams({
            name: 'gameplay-players-details',
            params: { id: row.player.id },
            query: { env: searchFields?.env }
          })
        "
        target="_blank"
        class="underline underline-offset-4 hover:text-blue-600"
        :class="{ 'text-blue-500': !row.isChild, 'font-related-xs italic text-neutral-400': row.isChild }"
      >
        {{ row.player.name }}
      </router-link>
      <span v-else />
    </template>
    <template #cell_level="{ row }">
      <span :class="{ 'font-related-xss italic text-neutral-400': row.isChild }">{{ row.level }}</span>
    </template>
    <template #cell_zone="{ row }">
      <span :class="{ 'font-related-xss italic text-neutral-400': row.isChild }">{{ row.zone }}</span>
    </template>
    <template #cell_country="{ row }">
      <span :class="{ 'font-related-xss italic text-neutral-400': row.isChild }">{{ row.country }}</span>
    </template>
    <template #cell_old="{ row, searchFields }">
      <router-link
        v-if="row?.old_guild?.id"
        :to="
          $utils.bindStaticParams({
            name: 'gameplay-guild-list-details',
            params: { id: row.old_guild.id },
            query: { env: searchFields.env }
          })
        "
        class="underline underline-offset-4 hover:text-blue-600"
        :class="{ 'text-blue-500': !row.isChild, 'font-related-xs italic text-neutral-400': row.isChild }"
      >
        {{ row.old_guild.name }}
      </router-link>
    </template>
    <template #cell_empty="{ row }">
      <span v-if="!row.isChild">--------&gt;</span>
    </template>
    <template #cell_new_guild="{ row, searchFields }">
      <router-link
        v-if="row?.new_guild?.id"
        :to="
          $utils.bindStaticParams({
            name: 'gameplay-guild-list-details',
            params: { id: row.new_guild.id },
            query: { env: searchFields.env }
          })
        "
        class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
      >
        {{ row.new_guild.name }}
      </router-link>
    </template>
  </crud-table>
</template>
