<script>
export default {
  data: () => ({
    messageQueue: {
      // dev: [], // not important?
      prod: []
    }
  }),
  mounted() {
    this.loadMessageQueue()
  },
  methods: {
    loadMessageQueue() {
      Object.keys(this.messageQueue).forEach((env) => {
        const envApi = this.$store.getters['auth/envs']?.[env]
        if (envApi === undefined)
          return

        this.$axios
          .get(`${envApi}/proxy/tools/globalmessagesqueue/`)
          .then((results) => {
            this.messageQueue[env] = results.data
          })
          .catch(() => {
            this.$message.error(`Error while getting message queue for ${env}!`)
          })
      })
    }
  }
}
</script>

<template>
  <crud-table
    :default-visible-columns="['CreatedAt', 'Title', 'Sender']"
    api="tools/template-global-messages"
    api-item="tools/template-global-message"
    :versioned="false"
  >
    <template #topTable>
      <div
        v-for="(messages, env) in messageQueue"
        :key="env"
        class="flex flex-row flex-wrap items-center pt-2"
      >
        <div
          v-for="(msg, i) in messages"
          :key="i"
          class="inline-block rounded-lg bg-slate-300 p-2 mx-2 mb-2 whitespace-nowrap"
        >
          <p>
            <strong>{{ msg.env }}</strong>
          </p>
          <p>
            {{ msg.title }} (#{{ msg.template_id }})
            <strong v-if="msg.sent === 1">Sent!</strong>
          </p>
          <p
            v-for="(sdate, j) in msg.send_dates"
            :key="j"
          >
            {{ sdate.zone }} - {{ sdate.time }}
          </p>
        </div>
      </div>
    </template>
  </crud-table>
</template>
