<script setup>
</script>

<template>
  <crud-details-page
    api="items/external-skin"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <crud-field-text
          api-field-name="Name"
          required
        />
        <crud-field-selector-files
          api-field-name="Image"
          label="Settings"
        />
        <crud-field-selector-files api-field-name="Preview" />
        <crud-field-selector-files
          api-field-name="Model"
          label="Model (Mafia only)"
        />
        <crud-field-selector-files
          api-field-name="Material"
          label="Material (Mafia only)"
        />
        <crud-field-selector-files
          api-field-name="Weapon"
          label="Weapon (Mafia only)"
        />
        <crud-field-checkbox
          api-field-name="IsStarting"
          slim
          class="ml-3"
        />
        <crud-field-checkbox
          api-field-name="IsFemale"
          slim
          class="ml-3"
        />
        <crud-field-checkbox
          api-field-name="IsDummy"
          slim
          class="ml-3"
        />
        <crud-field-checkbox
          api-field-name="IsFrame"
          slim
          class="ml-3"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
