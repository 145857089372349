<script>
import { BasicProps } from '!/components/forms/crud-fields/mixins/BasicProps'
import ItemSelector from '@/components/selectors/ItemSelector.vue'

export default {
  name: 'CrudFieldSelectorItem',
  components: { ItemSelector },
  mixins: [BasicProps],
  props: {
    initFilters: {
      type: Object,
      default: () => ({
        // 'colNameApi': initValue
      })
    }
  }
}
</script>

<template>
  <crud-details-field v-bind="$props">
    <template #default>
      <slot>
        <ItemSelector
          v-model="fieldModel"
          v-bind="$props"
          model-is-entity
          :label="false"
          full-width
        />
      </slot>
    </template>
    <template #fieldPrepend>
      <slot name="fieldPrepend" />
    </template>
    <template #fieldAppend>
      <slot name="fieldAppend" />
    </template>
    <template #label>
      <slot name="label" />
    </template>
  </crud-details-field>
</template>

<style scoped></style>
