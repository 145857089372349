<template>
  <crud-details-page
    api="events/rush-event"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- NameTag -->
        <crud-field-selector-client-strings
          api-field-name="NameTag"
          required
        />
        <!-- GoldMultiplier -->
        <crud-field-number
          api-field-name="GoldMultiplier"
          :max="255"
        />
        <!-- GoldCritMultiplier -->
        <crud-field-number
          api-field-name="GoldCritMultiplier"
          :max="255"
        />
        <!-- DurationHours -->
        <crud-field-number
          api-field-name="DurationHours"
          :max="255"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- SkirmishReminderAssetPopup -->
        <crud-field-selector-files api-field-name="SkirmishReminderAssetPopup" />
        <!-- AssetPopup -->
        <crud-field-selector-files api-field-name="AssetPopup" />
        <!-- AssetRewardPopup -->
        <crud-field-selector-files api-field-name="AssetRewardPopup" />
        <!-- AssetLocation -->
        <crud-field-selector-files api-field-name="AssetLocation" />
        <!-- AssetHomeIcon -->
        <crud-field-selector-files api-field-name="AssetHomeIcon" />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetHomeBanner -->
        <crud-field-selector-files api-field-name="AssetHomeBanner" />
        <!-- AssetBoss -->
        <crud-field-selector-files api-field-name="AssetBoss" />
        <!-- AssetCritPanelBackground -->
        <crud-field-selector-files api-field-name="AssetCritPanelBackground" />
        <!-- AssetRankingBanner -->
        <crud-field-selector-files api-field-name="AssetRankingBanner" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
