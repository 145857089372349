<script>
export default {
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '',
      dialogPlayers: [],
      response: {}
    }
  },
  methods: {
    openDialog(players, title) {
      this.dialogVisible = true
      this.dialogPlayers = players
      this.dialogTitle = title
    },
    copyDeviceId(deviceId) {
      navigator.clipboard.writeText(deviceId)
    },
    renderLoadedData(response) {
      this.response = response || {}
      return { items: response.rows, total: response.rows.length }
    }
  }
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/playerdeviceids/'
    }"
    :action-col-settings="false"
    :top-actions="false"
    :static-api-params="{
      env: 'prod'
    }"
    :columns="{
      device_id: 'string:sort',
      count: 'uint:sort',
      actions: 'any'
    }"
    :columns-settings="{
      ID: false,
      device_id: { width: 400, header: 'Device Id', filterField: true },
      count: { width: 70, filterField: true },
      actions: { width: 120, header: 'Show' }
    }"
    :default-columns-settings="{
      filterField: false
    }"
    :table-props="{
      'header-cell-class-name': 'font-medium bg-sky-50 gs-col-padding-mini text-sky-800'
    }"
    enable-number-rows
    hide-columns-selector
    :api-pagination="false"
    :api-filtering="false"
    :api-sorting="false"
    :enable-cell-edit-dialogs="false"
    :render-loaded-data="renderLoadedData"
    disable-multi-select-rows
  >
    <template #topTable>
      <h1 class="text-xl">
        Last 7 Days
      </h1>
      <h1 class="text-l mb-2">
        Total Count: {{ response?.count }}
        <el-button
          class="gs-btn-outlined-primary gs-size-scaled ml-3"
          size="small"
          @click="openDialog(response?.players, 'All players')"
        >
          Show All Players
        </el-button>
      </h1>
    </template>
    <template #cell_actions="{ row }">
      <el-button
        class="gs-btn-outlined-primary gs-size-scaled"
        size="small"
        @click="openDialog(row.players, 'Device players')"
      >
        Show Players
      </el-button>
    </template>
    <template #drawers>
      <el-dialog
        v-model="dialogVisible"
        width="90%"
        align-center
        :title="dialogTitle"
      >
        <filtering-table
          v-if="dialogVisible"
          :rows="dialogPlayers"
          index-rows-column
          height="75vh"
          max-height="75vh"
          header-cell-class-name="font-medium bg-sky-50 gs-col-padding-mini text-sky-800"
          cell-class-name="gs-col-padding-mini"
          fit
          :columns="{
            id: { label: 'Id', width: 70, sortable: true, filterField: true, type: 'uint' },
            name: { label: 'Name', width: 170, sortable: true, filterField: true, type: 'string' },
            guild: {
              'min-width': 170,
              'sortable': true,
              'filterField': true,
              'type': 'string',
              'elAttr': { formatter: (row) => row.guild.name }
            },
            country: { width: 90, sortable: true, filterField: true, type: 'string' },
            created: { width: 170, sortable: true, filterField: true, type: 'string' },
            last_active: { label: 'Last active', width: 170, sortable: true, filterField: true, type: 'string' },
            level: { width: 70, sortable: true, filterField: true, type: 'uint' },
            energy: { width: 70, sortable: true, filterField: true, type: 'uint' },
            energy_gifts: { label: 'Energy gifts', width: 100, sortable: true, filterField: true, type: 'uint' },
            gems: { width: 70, sortable: true, filterField: true, type: 'uint' },
            revenue: { width: 80, sortable: true, filterField: true, type: 'uint' },
            copy: { label: ' ', width: 150 }
          }"
          :page-sizes="[50, 100, 200]"
          hide-refresh-btn
        >
          <template #cell_id="{ row }">
            <router-link
              :to="$utils.bindStaticParams({ name: 'gameplay-players-details', params: { id: row.id }, query: { env: 'prod' } })"
              target="_blank"
              class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
            >
              {{ row.id }}
            </router-link>
          </template>
          <template #cell_guild="{ row }">
            <router-link
              :to="
                $utils.bindStaticParams({
                  name: 'gameplay-guild-list-details',
                  params: { id: row.guild.id },
                  query: { env: 'prod' }
                })
              "
              class="text-blue-500 underline underline-offset-4 hover:text-blue-600"
            >
              {{ row.guild.name }}
            </router-link>
          </template>
          <template #cell_copy="{ row }">
            <el-button
              class="gs-btn-outlined-primary gs-size-scaled ml-3"
              size="small"
              @click="copyDeviceId(row.device_id)"
            >
              Copy DeviceId
            </el-button>
          </template>
        </filtering-table>
      </el-dialog>
    </template>
  </crud-table>
</template>
