<template>
  <crud-details-page
    api="rewards/daily-login-reward"
    disable-top-margin
  >
    <template #form>
      <!-- Day -->
      <crud-field-number
        :sm="5"
        api-field-name="Day"
        required
      />
      <fields-col :sm="5">
        <!-- XpPct -->
        <crud-field-number api-field-name="XpPct" />
        <!-- Energy -->
        <crud-field-number api-field-name="Energy" />
        <!-- Gems -->
        <crud-field-number api-field-name="Gems" />
      </fields-col>
      <fields-col :sm="14">
        <template
          v-for="i in 2"
          :key="i"
        >
          <crud-field-selector-item
            :sm="16"
            :api-field-name="`Item${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Item${i}Amount`"
          />
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
