<script setup></script>

<template>
  <crud-details-page
    api="division-nettoSettings/battle-reward"
  >
    <template #form>
      <!-- DivisionNo -->
      <crud-field-number
        :sm="4"
        api-field-name="DivisionNo"
        required
      />
      <!-- XpPct -->
      <crud-field-number
        :sm="4"
        api-field-name="XpPct"
      />
      <!-- Energy -->
      <crud-field-number
        :sm="4"
        api-field-name="Energy"
      />
      <!-- SeasonPassPoints -->
      <crud-field-number
        :sm="4"
        api-field-name="SeasonPassPoints"
      />
    </template>
  </crud-details-page>
</template>
