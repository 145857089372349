<template>
  <crud-details-page
    api="season-calendar"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- SeasonCalendar -->
        <crud-field-select
          api-field-name="SeasonCalendar"
          options-enum="enums.SeasonCalendar"
          required
        />
        <!-- NameTag -->
        <crud-field-selector-client-strings
          api-field-name="NameTag"
          required
        />
        <!-- DescriptionTag -->
        <crud-field-selector-client-strings
          api-field-name="DescriptionTag"
          required
        />
      </fields-col>
      <fields-col :sm="4">
        <!-- SeasonDayStart -->
        <crud-field-number
          api-field-name="SeasonDayStart"
          required
        />
        <!-- SeasonDayEnd -->
        <crud-field-number
          api-field-name="SeasonDayEnd"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetIcon -->
        <crud-field-selector-files
          api-field-name="AssetIcon"
          required
        />
        <!-- Color -->
        <crud-field-text
          api-field-name="Color"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
