<script>
import CrudFieldSelectorChest from '@/components/forms/crud-fields/CrudFieldSelectorChest.vue'
import CrudFieldSelectorCardDeck from '@/components/forms/crud-fields/CrudFieldSelectorCardDeck.vue'

export default {
  components: {
    CrudFieldSelectorCardDeck,
    CrudFieldSelectorChest
  }
}
</script>

<template>
  <crud-details-page
    api="events/card-event"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- NameTag -->
        <crud-field-selector-client-strings
          api-field-name="NameTag"
          required
        />
        <!-- DurationHours -->
        <crud-field-number api-field-name="DurationHours" />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetDeckIcon -->
        <crud-field-selector-files api-field-name="AssetDeckIcon" />
        <!-- AssetPopup -->
        <crud-field-selector-files api-field-name="AssetPopup" />
      </fields-col>
      <section-divider>Packs</section-divider>
      <fields-col :sm="16">
        <template
          v-for="i in 3"
          :key="i"
        >
          <CrudFieldSelectorChest
            :sm="12"
            :init-filters="{ Type: 'EventCardPack' }"
            :api-field-name="`Pack${i}`"
          />
          <crud-field-selector-item
            :sm="12"
            :init-filters="{ Type: 'ChestKey' }"
            :api-field-name="`Pack${i}Key`"
          />
        </template>
      </fields-col>
      <section-divider>Special Packs</section-divider>
      <fields-col :sm="16">
        <template
          v-for="i in [4, 5, 6, 7, 8]"
          :key="i"
        >
          <CrudFieldSelectorChest
            :sm="12"
            :init-filters="{ Type: 'EventCardPack' }"
            :api-field-name="`Pack${i}`"
          />
          <crud-field-selector-item
            :sm="12"
            :init-filters="{ Type: 'ChestKey' }"
            :api-field-name="`Pack${i}Key`"
          />
        </template>
      </fields-col>
      <section-divider>Decks</section-divider>
      <fields-col :sm="8">
        <CrudFieldSelectorCardDeck
          v-for="i in 15"
          :key="i"
          :init-filters="{ Type: 'Event' }"
          :api-field-name="`Deck${i}`"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
